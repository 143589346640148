import IconLink from 'components/IconLink';
import ImportData from 'components/ImportData';
import React from 'react';
import { HIDDEN_SCROLLBARS } from 'resources/constants/styles';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  padding-bottom: 0;
  height: 100%;
  overflow: scroll;
  ${HIDDEN_SCROLLBARS}
`;

const Layout = styled.div`
  max-height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-bottom: 15px;
`;

const DEFAULT_IMPORT_OPTIONS = [];

const CreateContainer = ({ route, text, children, importOptions = DEFAULT_IMPORT_OPTIONS, importData }) => {
  return (
    <Container>
      <Layout>
        <HeaderContainer>
          <IconLink to={route}>{text}</IconLink>
          {!!importData  && <ImportData options={importOptions} onChange={importData} />}
        </HeaderContainer>
        {children}
        <div style={{ minHeight: '32px' }}></div>
      </Layout>
    </Container>
  );
};

export default CreateContainer;
