import React, {useEffect, useState, useRef, useMemo} from 'react';
import { Input as InputTextbox } from '@etiquette-ui/inputs-dropdowns';
import { getFullName } from 'utils/text';
import styled from 'styled-components';
import UserItem from 'components/UserItem';
import { ChevronDownIcon } from '@etiquette-ui/icons';
import useI18n from 'i18n/useI18n';
import { compareStrings } from 'utils/index.js';
import { SearchContainer, Input } from 'components/styles.js';


const DropdownContainer = styled.div`
  position: relative;
  margin-bottom: 15px;    
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;
  width: 25px;
  height: 100%;

  display: flex;
  align-items: center;

  transform: ${ ({isOpen}) => isOpen ? 'rotate(180deg)' : 'none'};
`;

const Dropdown = styled.div`
  max-height: 200px;
  min-height: 150px;
  overflow-y: scroll;

  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0px;
  right: 0px;

  background-color: white;
  box-shadow: 0px 4px 12px #03326914;

  padding-top: 15px;
  padding-bottom: 20px;
`;

const DropdownItem = styled.div`
  margin-top: 5px;
`;

const EmployeeSelect = ({employee, setEmployee, employees}) => {

  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  const { translate } = useI18n();
  const [search, setSearch] = useState('');

  const handleSetEmployee = (employee, event) => {
    if(event && event.stopPropagation) event.stopPropagation(); 
    setEmployee(employee);
    setOpen(false)
  }

  const onSearch = (e) => {
    setSearch(e.target.value);
  }

  useEffect(() => {
    if (open && inputRef.current) inputRef.current.focus();
    if(!open) setSearch('');
  }, [open]);

  const list = useMemo(()=>{
    if(!!search) {
      return employees.filter(({name})=>compareStrings(name, search));
    }
    return !!employees ? employees : [];
  },[search, employees]);

  return (
      <DropdownContainer onClick={()=>setOpen(!open)} onMouseLeave={()=>setOpen(false)}>
        <InputTextbox
          iconProps={{
            icon: 'user'
          }}
          inputProps={{
            readOnly: true,
            onChange: function noRefCheck(){},
            required: true,
            value: !!employee ? getFullName(employee) : '',
          }}
          labelProps={{
            label: translate('Choose an employee')
          }}
        />
        <IconContainer isOpen={open}>
          <ChevronDownIcon
            styles={{
              svg: {
                width: '20px',
                height: '20px',
              },
              line:{
                fill: 'none',
                strokeLinecap:'round',
                strokeLinejoin:'round',
                strokeWidth:'2px',
                stroke: 'black'
                }
            }}
          />
        </IconContainer>
        {open && (          
          <Dropdown>            
            <SearchContainer>
              <Input ref={inputRef} value={search} onChange={onSearch} placeholder={translate('Search')} />
            </SearchContainer>
            {list.map( user => (
              <DropdownItem key={user.id} onClick={(event) => handleSetEmployee(user, event)}>
                <UserItem name={`${getFullName(user)}`} image={user.image} />
              </DropdownItem>            
            ))}
          </Dropdown>
      )}
      </DropdownContainer>
    
  )
}
export default EmployeeSelect;
