import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer'
import { history } from "../resources/history";
import { routerMiddleware } from 'connected-react-router'

const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];

const store = createStore(
    rootReducer(history),
    composeWithDevTools(applyMiddleware(...middlewares))
)

sagaMiddleware.run(rootSaga);

export default store;
