import React from 'react';
import Card from 'components/Card';
import { GridContainer } from 'containers/FormLayout';
import useI18n from 'i18n/useI18n';
import { CardActionButton } from 'components/Buttons';
import TrashSVG from 'resources/img/trash-icon.svg';
import { Input, PhoneInput } from '@etiquette-ui/inputs-dropdowns';

const ContactCard = ({title, formData, onChangeForm, errors, errorMailKey = 'contactEmail', errorPhoneKey= 'contactPhone', onRemove}) => {
  const { translate } = useI18n();

  return (
    <Card>
      <h3>{title}</h3>
      <GridContainer>
        <Input
          labelProps={{label: translate('First Name')}}
          iconProps={{
            icon: 'user'
          }}
          inputProps={{
            value: formData.contactFirstName, 
            onChange: (e) => onChangeForm('contactFirstName', e.target.value), 
            required: true 
          }}
        />
        <Input
          labelProps={{label: translate('Last Name')}}
          iconProps={{
            icon: 'user'
          }}
          inputProps={{
            value: formData.contactLastName, 
            onChange: (e) => onChangeForm('contactLastName', e.target.value), 
            required: true 
          }}
        />
        <Input
          labelProps={{label: translate('Email Address')}}
          iconProps={{
            icon: 'mail'
          }}
          inputProps={{
            value: formData.contactEmail, 
            onChange: (e) => onChangeForm('contactEmail', e.target.value), 
            required: true,
            type: 'email'
          }}
          error={errors[errorMailKey] ? translate('Invalid Email') : undefined}
        />
        <PhoneInput
          countrySelectProps={{
            countriesLabel: translate('All Countries'),
            country: formData?.contactCountryCode,
            onChange: (value) => onChangeForm('contactCountryCode', value),
            preferredCountriesCodes: [
              'MX',
              'US',
              'CA'
            ]
          }}
          errorProps={{
            error: errors[errorPhoneKey] ? translate('Invalid Phone Number') : ''
          }}
          inputProps={{
            onChange: (e) => onChangeForm('contactPhone', e.target.value),
            value: formData?.contactPhone,
            required: true
          }}
          labelProps={{
            label: 'Mobile Phone'
          }}
        />
        <Input
          labelProps={{label: translate('Job Position')}}
          iconProps={{
            icon: 'position'
          }}
          inputProps={{
            value: formData.contactPosition, 
            onChange: (e) => onChangeForm('contactPosition', e.target.value), 
            required: true 
          }}
        />
      </GridContainer>
      {!!onRemove && <CardActionButton onClick={onRemove} icon={TrashSVG} >{translate('Remove Contact')}</CardActionButton>}
    </Card>
  );
};
export default ContactCard;