  import React,{useMemo} from 'react';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import { TEXT_PLACEHOLDER } from '@etiquette-ui/colors';
import useRoles from 'state/roles';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';

const SelectContainer = styled.div`
  pointer-events: ${ ({isActive}) => isActive ? 'all' : 'none'};
  opacity: ${ ({isActive}) => isActive ? '1' : '.7'};
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
const Placeholder = styled.div`  
  padding: 2px 5px;
  font-size: 12px;
  color: ${TEXT_PLACEHOLDER};
  font-weight: 500;
`;

const RoleSelect = ({employee, groupId, divisionId, onChangeRole}) => {
  const { translate } = useI18n();
  const [roles] = useRoles();

  const getFreeOptions = () => {
    if(employee === undefined) return [];

    const groupRolesList = roles.list.filter((p) => +p.groupId === +groupId).map(({ id, name }) => ({ value: id, label: name }));
    
    if(employee.assignments === null || employee.assignments[groupId] === undefined){
      return groupRolesList;
    }

    let rolesIds = [];
    for(const assignment of employee.assignments[groupId]){
      if(!!assignment.division && Number(assignment.division) === Number(divisionId) && !!assignment.roles){
        rolesIds =  assignment.roles;
      }
    }
    const rolesList = groupRolesList.filter((role) => {
      if(!rolesIds.includes(role.value)) return role;
    });

    return rolesList;
  }

  const freeRoles = useMemo(getFreeOptions, [employee]);

  return (
    <SelectContainer isActive={freeRoles.length > 0 ? true : false}>
      <Dropdown
        labelProps={{
          label: translate('Assign Role')
        }} 
        leadingIconProps={{
          icon: 'role'
        }} 
        selectProps={{
          options: freeRoles,
          onChange: value => onChangeRole(value),
          placeholder: translate('Choose one')
        }}
      />
      {!employee && freeRoles.length === 0 && (
        <Placeholder>{translate('Choose an employee first.')}</Placeholder>
      )}
      {!!employee && freeRoles.length === 0 && (
        <Placeholder>{translate('No Role options for the chosen Employee')}</Placeholder>
      )}           
    </SelectContainer>
  )
}
export default RoleSelect;
