import React, { Fragment } from 'react';
import Assignments from 'components/EmployeeForm/sections/Assignments';
import Permission from 'components/EmployeeForm/sections/Permission';
import { TitleH2 } from 'components/Texts';
import { FormSpacer } from 'containers/FormLayout';
import { Divider } from 'containers/FormLayout';
import { ACTIONS } from '../../Reducer';

const GroupsDetails = ({ formData, formDispatcher }) => {
  return (
    <>
      {formData.groups.map((group, i) => (
        <Fragment key={i}>
          <Divider />
          <FormSpacer />
          <TitleH2 style={{ marginBottom: '20px', textAlign: 'center' }}>{group.element.label}</TitleH2>
          <FormSpacer />
          <Permission formData={group} handleChange={(value)=>formDispatcher(ACTIONS.PERMISSION, {index: i, permission: value})} groupId={group.element.value} />
          <FormSpacer />
          <Assignments
            formData={group}
            handleDivisionChange={(index, value) => formDispatcher(ACTIONS.ADD_DIVISION, {index: i, element: { index, value } })}
            handleRoleChange={(index, value) => formDispatcher(ACTIONS.ADD_ROLE, { index: i, element: { index, value } })}
            addAssignment={() => formDispatcher(ACTIONS.ADD_ASSIGNMENT, {index: i, groupId: group.element.value } )}
            removeAssignment={(id) => formDispatcher(ACTIONS.REMOVE_ASSIGNMENT, {index: i, id} )}
            groupId={group.element.value}
          />
          <FormSpacer />
        </Fragment>
      ))}
    </>
  );
};

export default GroupsDetails;
