import React from "react";
import styled from "styled-components";
import Search from "./components/Search";
import User from "./components/User";
import Lang from "./components/Lang";
import Logout from "./components/Logout";
import { useHistory } from "react-router";
import { TitleH2 } from "components/Texts";

const Header = styled.header`
  display: grid;
  grid-template-columns: 16rem auto 25rem;
  grid-gap: 10px;
  background-color: #012739;
  color: white;
  border-radius: 0px 0px 25px 25px;
  align-items: center;
  height: 100%;
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 1rem;
  align-items: center;
`;

const Middle = styled.div`
  display: flex;
  justify-content: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
`;

const Logocontainer = styled.div`
  height: 64px;
  background-color: #FFF;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 8px 18px;
  box-sizing: border-box;
  h2 {
    color: black;
    cursor: pointer;
    padding-left: 12px;
    box-sizing: border-box;
  }
  img {
    width: 48px;
    height: 48px;
  }
`;

const MainHeader = () => {

  const history = useHistory();

  return (
    <Header>
      <Left>
        <Logocontainer>
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt={"adminsite-logo"} />
          <TitleH2 onClick={() => history.push('/')}>AdminSite</TitleH2>
        </Logocontainer>
      </Left>
      <Middle>
        <Search />
      </Middle>
      <Right>
        <Lang />
        <User />
        <Logout />
      </Right>
    </Header>
  );
};

export default MainHeader;
