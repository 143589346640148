import { TitleH3, TitleH2 } from 'components/Texts';
import React from 'react';
import styled from 'styled-components';
import { IMAGES_URL } from 'resources/constants/urls';
import useI18n from 'i18n/useI18n';
import { formatAddress } from 'utils/text';
import { Body } from 'components/Texts';

const Container = styled.div`
  display: grid;
  grid-template-rows: 2rem minmax(0, 1fr);
  height: 100%;
`;

const Grid = styled.div`
  display: flex;
  flex: 1;
`;

const Info = styled.div`
  flex: 4;
`;

const ImageContainer = styled.div`
  flex: 3;
  max-height: 150px;
  overflow: hidden;
  margin-bottom: 1rem;

  img {
    max-height: 150px;
    overflow: hidden;
    object-fit: cover;
  }
`;

const GroupProfile = ({ group }) => {
  const { translate } = useI18n();

  return (
    <Container>
      <TitleH3>{translate('Group Information')}</TitleH3>
      <Grid>
        <ImageContainer>
          <img src={`${IMAGES_URL}${group?.imageLight}`} alt={'group-profile'} style={{ width: '100%' }} />
        </ImageContainer>
        <div style={{width: '20px'}}></div>
        <Info>
          <TitleH2 style={{ fontWeight: '400', marginBottom: '1rem' }}>{group.name}</TitleH2>
          <TitleH3 style={{ marginBottom: '0.5rem' }}>{translate('Address')}</TitleH3>
          <Body>{formatAddress(group.address)}</Body>
        </Info>
      </Grid>
    </Container>
  );
};

export default GroupProfile;
