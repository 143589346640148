import Types from "./types";

export default class RolesDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchStart = (payload) => this.dispatch({ type: Types.FETCH_START, payload });

    updateStart = (item, goBack=true) => this.dispatch({ type: Types.UPDATE_START, payload: { item, goBack } });

    createStart = (payload) => this.dispatch({ type: Types.CREATE_START, payload });

    deleteStart = (payload) => this.dispatch({ type: Types.DELETE_START, payload });
    
    fetchOneStart = (payload) => this.dispatch({ type: Types.FETCH_ONE_START, payload });

    resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}