import { DEFAULT_COUNTRY_CODE, DEFAULT_INIT_TIME, DEFAULT_SCHEDULE, DEFAULT_SPECIAL_DATE } from 'components/GroupForm/initState';
import { getPhoneParts } from 'utils/phone';

const parsePhone = (phone) => {
  const obj = getPhoneParts(phone);
  return !!obj
    ? { contactCountryCode: obj.countryCode, contactPhone: obj.phone }
    : { contactCountryCode: DEFAULT_COUNTRY_CODE, contactPhone: '' };
};

const formatHour = (timeString) => {
  if (!timeString) return { ...DEFAULT_INIT_TIME };
  const [hour, minute] = timeString.split(':');

  if (hour >= 12) {
    return {
      hour: +hour === 12 ? '12' : +hour - 12,
      minute: minute,
      time: 'pm',
    };
  }
  return {
    hour: +hour === 0 ? '12' : hour,
    minute: minute,
    time: 'am',
  };
};

export const getSchedule = (config) => {
  if (!!config.openingHours?.schedule) {
    const schedule = {};

    for (const key of Object.keys(config.openingHours.schedule)) {
      schedule[key] = {
        ...config.openingHours.schedule[key],
        hours: [...config.openingHours.schedule[key].hours].map((hour) => formatHour(hour)),
      };
    }

    return schedule;
  }
  return null;
};

export const getSpecialDates = (config) => {
  if (!!config.openingHours?.specialDates) {
    const specialDates = config.openingHours.specialDates.map(({ date, isOpen, hours }) => {
      return { date: new Date(date + 'T00:00:00'), isOpen, hours: hours.map((hour) => formatHour(hour)) };
    });

    return specialDates;
  }
  return null;
};

const parseFormData = (group) => {
  const newGroup = {};
  newGroup.id = group.id;
  newGroup.name = group?.name;
  newGroup.contacts = [
    ...group.contacts.map((contact) => ({
      contactFirstName: contact?.name,
      contactLastName: contact?.lastName,
      contactEmail: contact?.email,
      contactPosition: contact?.position,
      ...parsePhone(contact.phone),
    })),
  ];
  newGroup.streetAddress = group?.address?.street;
  newGroup.streetNumber = group?.address?.number;
  newGroup.zipCode = group?.address?.zipCode;
  newGroup.stateAddress =
    !!group?.address?.state && !!group?.address?.stateId
      ? { label: group?.address?.state, value: group?.address?.stateId }
      : null;
  newGroup.cityAddress =
    !!group?.address?.city && !!group?.address?.cityId ? { label: group?.address?.city, value: group?.address?.cityId } : null;
  newGroup.img = group?.imageLight;

  newGroup.config = {
    openingHours: {
      schedule: {
        monday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
        tuesday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
        wednesday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
        thursday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
        friday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
        saturday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
        sunday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
      },
      specialDates: [{ ...DEFAULT_SPECIAL_DATE, hours: [...DEFAULT_SPECIAL_DATE.hours] }],
    },
  };

  if(!!group.config) {
    const config = group.config;

    const schedule = getSchedule(config);
    if (!!schedule) newGroup.config.openingHours.schedule = schedule;

    const specialDates = getSpecialDates(config);
    if (!!schedule) newGroup.config.openingHours.specialDates = specialDates;
  }
  

  return newGroup;
};

export default parseFormData;
