import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router';
import backArrow from 'resources/img/back-Icon.svg';
import { TitleH4 } from 'components/Texts';
import { Main, Space } from './styles';
import AppContainer from './components/AppContainer';
import AppCard from './components/AppCard';
import useApps from 'state/apps';
import useI18n from 'i18n/useI18n';
import { useParams } from 'react-router-dom';

const LearnApps = () => {
  const { translate } = useI18n();
  const history = useHistory();
  const [apps] = useApps();
  const [appsSelected, setAppsSelected] = useState([])

  const { groupId } = useParams();

  const handleBackProfile = () => {
    history.push(`/${groupId}/management/profile`);
  }

  const stackApps = (value) => {
    const newAppsSelected = [...appsSelected];

    const index = newAppsSelected.findIndex((item) => item.identifier === value.identifier);
    if (index !== -1) {
      newAppsSelected.splice(index, 1);
    } else {
      newAppsSelected.push(value);
    }

    setAppsSelected(newAppsSelected);
  }

  return (
    <Main>
      <div className='main__container'>
        <section className='main__container--arrowSection'>
          <img src={backArrow} alt="backArrow" onClick={handleBackProfile} />
          <TitleH4 onClick={handleBackProfile}>{translate("Back Profile")}</TitleH4>
        </section>
        <section className='main__container--appSection '>
          <AppContainer onClick={handleBackProfile} appListSelected={appsSelected}>
            {!!apps.available && apps.available.map((item, idx) => (
              <Fragment key={idx}>
                <AppCard data={item} onClick={stackApps} />
                <Space />
              </Fragment>
            ))}
          </AppContainer>
        </section>
      </div>
    </Main>
  );
}

export default LearnApps;
