import { takeLatest, put, all, call } from 'redux-saga/effects';
import { groupsAPI } from 'resources/api';
import { toFormData } from 'utils';
import Types from './types';
import { goBack } from 'connected-react-router';
import { processError } from 'state/utils';

function* fetchStartAsync() {
  try {
    const res = yield groupsAPI.getAll();
    yield put({ type: Types.FETCH_SUCCESS, payload: res });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

function* updateStartAsync({ payload }) {
  try {
    const form = toFormData(payload);
    yield groupsAPI.update(form);
    yield put({ type: Types.UPDATE_SUCCESS, payload });
    yield put({ type: Types.FETCH_START });
    yield put(goBack());
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.UPDATE_ERROR, payload: message });
  }
}

function* updateStart() {
  yield takeLatest(Types.UPDATE_START, updateStartAsync);
}

function* toggleActiveAsync({ payload }) {
  try {
    const form = toFormData(payload);
    yield groupsAPI.update(form);
    yield put({ type: Types.UPDATE_SUCCESS, payload });
    yield put({ type: Types.FETCH_START });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.UPDATE_ERROR, payload: message });
  }
}

function* toggleActive() {
  yield takeLatest(Types.TOGGLE_ACTIVE, toggleActiveAsync);
}

function* createStartAsync({ payload }) {
  try {
    const form = toFormData(payload);
    yield groupsAPI.create(form);
    yield put({ type: Types.CREATE_SUCCESS, payload });
    yield put({ type: Types.FETCH_START });
    yield put(goBack());
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.CREATE_ERROR, payload: message });
  }
}

function* createStart() {
  yield takeLatest(Types.CREATE_START, createStartAsync);
}
function* deleteStartAsync({ payload }) {
  try {
    yield groupsAPI.delete(payload);
    yield put({ type: Types.DELETE_SUCCESS, payload });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.DELETE_ERROR, payload: message });
  }
}

function* deleteStart() {
  yield takeLatest(Types.DELETE_START, deleteStartAsync);
}
export default function* sagas() {
  yield all([call(fetchStart), call(updateStart), call(toggleActive), call(createStart), call(deleteStart)]);
}
