import React, { useReducer } from 'react';

import { useRouter } from 'providers/router';
import Form from '../components/Form';
import Reducer, { initialState } from '../Reducer';
import useI18n from 'i18n/useI18n';
import useUsers from 'state/users';
import CreateContainer from 'pages/Consolidate/containers/CreateContainer';
import submitProcessor from '../components/Form/submitProcessor';

const Create = () => {
  const { translate } = useI18n();
  const [formData, formDispatcher] = useReducer(Reducer, initialState);
  const { route, canGoBack } = useRouter();
  const [, usersDispatcher] = useUsers();

  const dispatch = (type, payload) => formDispatcher({ type, payload });

  const onSubmit = () => {
    const newData = submitProcessor(formData);

    usersDispatcher.createStart(newData);
  };

  return (
    <CreateContainer route={canGoBack() ? route.from : `/consolidate/employees`} text={translate('Back to Employees')}>
      <Form
        onSubmit={onSubmit}
        dispatch={dispatch}
        state={formData}
        title={translate('New Employee')}
        buttonText={translate('Create Employee')}
      />
    </CreateContainer>
  );
};

export default Create;
