import { takeLatest, put, all, call } from 'redux-saga/effects';
import { nomenclatorAPI } from 'resources/api';
import { processError } from 'state/utils';
import Types from './types';

function* fetchStartPaymentMethodsAsync() {
  try {
    const list = yield nomenclatorAPI('payment_method');
    yield put({ type: Types.FETCH_SUCCESS_PAYMENT_METHOD, payload: { list } });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR_PAYMENT_METHOD, payload: message });
  }
}

function* fetchStartPaymentMethods() {
  yield takeLatest(Types.FETCH_START_PAYMENT_METHOD, fetchStartPaymentMethodsAsync);
}

function* fetchStartSaleTypesAsync() {
  try {
    const list = yield nomenclatorAPI('sale_type');
    yield put({ type: Types.FETCH_SUCCESS_SALE_TYPE, payload: { list } });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR_SALE_TYPE, payload: message });
  }
}

function* fetchStartSaleTypes() {
  yield takeLatest(Types.FETCH_START_SALE_TYPE, fetchStartSaleTypesAsync);
}

function* fetchStartAppsAsync() {
  try {
    const list = yield nomenclatorAPI('app');
    yield put({ type: Types.FETCH_SUCCESS_APP, payload: { list } });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR_APP, payload: message });
  }
}

function* fetchStartApps() {
  yield takeLatest(Types.FETCH_START_APP, fetchStartAppsAsync);
}

function* fetchStartLangsAsync() {
  try {
    const list = yield nomenclatorAPI('lang');
    yield put({ type: Types.FETCH_SUCCESS_LANG, payload: { list } });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR_LANG, payload: message });
  }
}

function* fetchStartLangs() {
  yield takeLatest(Types.FETCH_START_LANG, fetchStartLangsAsync);
}

export default function* sagas() {
  yield all([call(fetchStartPaymentMethods), call(fetchStartSaleTypes), call(fetchStartApps), call(fetchStartLangs)]);
}
