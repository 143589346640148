import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import List from './pages/List';

const SurveyBuilder = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
          <Route path={path} component={List} exact />
          {/* <Route path={`${path}/create`} component={Create} /> */}
          {/* <Route path={`${path}/edit/:id([0-9]+)`} component={Edit} /> */}
        </Switch>
    );
}

export default SurveyBuilder;
