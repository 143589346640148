import { combineReducers } from 'redux';
import groups from './groups/reducer';
import places from './places/reducer';
import apps from './apps/reducer';
import permissions from './permissions/reducer';
import divisions from './divisions/reducer';
import users from './users/reducer';
import emailTemplates from './emailTemplates/reducer';
import salesAdjustment from './salesAdjustment/reducer';
import roles from './roles/reducer';
import nomenclators from './nomenclators/reducer';

import { connectRouter } from 'connected-react-router';

const rootReducer = (history) => combineReducers({
  groups,
  places,
  apps,
  permissions,
  divisions,
  users,
  emailTemplates,
  salesAdjustment,
  roles,
  nomenclators,
  router: connectRouter(history),
});

export default rootReducer;
