import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch, useParams } from 'react-router';
import EmailTemplates from './pages/EmailTemplates';
import Management from './pages/Management';
import SalesAdjustments from './pages/SalesAdjustments';
import styled from 'styled-components';
import SideNav from './components/SideNav';
import routes from './routes';
import useGroups from 'state/groups';
import useApps from 'state/apps';
import MainLayout from 'containers/MainLayout';
import PrivateRoute from 'containers/PrivateRoute';
import { ACCESS_LIST } from 'resources/constants/config';
import SurveyBuilder from './pages/SurveyBuilder';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 20px;
  grid-template-rows: minmax(0, 100%);
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  padding: 16px;
  padding-bottom: 0;
  width: 100%;
`;

const GroupHome = ({ location }) => {
  const { path, url } = useRouteMatch();
  const [, dispatcher] = useGroups();
  const [, appDispatcher] = useApps();
  const { groupId } = useParams();

  useEffect(() => {
    dispatcher.fetchStart();
    appDispatcher.fetchStart();
    appDispatcher.fetchAvailable();
  }, []);

  return (
    <MainLayout>
      <Container>
          <SideNav routes={routes(url)} current={location.pathname} />
        <Content>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${url}/management`} />
            </Route>
            <Route path={`${path}/management`} component={Management} />
            <PrivateRoute path={`${path}/templates`} component={EmailTemplates} access={ACCESS_LIST.EMAIL_TEMPLATE} groupId={groupId}/>
            <PrivateRoute path={`${path}/adjustments`} component={SalesAdjustments} access={ACCESS_LIST.SALE} groupId={groupId}/>
            <Route path={`${path}/survey-builder`} component={SurveyBuilder} />
          </Switch>
        </Content>
      </Container>
    </MainLayout>
  );
};

export default GroupHome;
