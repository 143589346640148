import React from 'react';
import Space, { Hr } from 'components/Space';
import { TitleH1 } from 'components/Texts';


const ByGroupLayout = ({title='', children}) => (
    <>
      <Space height={'8px'}/>
      <Hr />
      <Space />
      <TitleH1>{title}</TitleH1>
      <Space />
      {children}
      <Space height={'56px'}/>
    </>
)


export default ByGroupLayout;