import React, { useEffect, useMemo } from 'react';
import Table from 'components/Table';
import usePermissions from 'state/permissions';
import structure from './structure';
import { useHistory } from 'react-router';
import useI18n from 'i18n/useI18n';
import { useParams } from 'react-router-dom';
import ManagementTabsContainer from 'pages/Group/pages/Management/containers/ManagementTabsContainer';
import { useAlert } from '@etiquette-ui/alert-dialog';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';
import { useAccess } from 'providers/access';
import { ACCESS_LIST } from 'resources/constants/config';

const List = ({ location }) => {
  const [data, dispatcher] = usePermissions();
  const history = useHistory();
  const { translate } = useI18n();
  const { groupId } = useParams();
  const alert = useAlert();
  const { search, setVisible } = useSearch();
  const { hasAccessInGroup } = useAccess();

  useEffect(() => {
    dispatcher.fetchStart(groupId);
  }, [groupId]);

  useEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const items = useMemo(() => {
    let list = [...data.list];
    if (search.length !== 0) list = list.filter(({ name, description }) => compareStrings(name, search) || compareStrings(description, search));
    return list;
  }, [data.list, search]);

  return (
    <ManagementTabsContainer location={location} fabLink={`${location.pathname}/create`} fabText={translate('Add Permission')} fabDisabled={!hasAccessInGroup(groupId, ACCESS_LIST.PERMISSION)}>
      <Table
        structure={structure}
        config={{ history, dispatcher, data, translate, alert, groupId }}
        items={items.filter((app) => +groupId === app.groupId)}
      />
    </ManagementTabsContainer>
  );
};

export default List;
