  import React,{useMemo} from 'react';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import { TEXT_PLACEHOLDER } from '@etiquette-ui/colors';
import useDivisions from 'state/divisions';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';

const SelectContainer = styled.div`
  pointer-events: ${ ({isActive}) => isActive ? 'all' : 'none'};
  opacity: ${ ({isActive}) => isActive ? '1' : '.7'};
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
const Placeholder = styled.div`  
  padding: 2px 5px;
  font-size: 12px;
  color: ${TEXT_PLACEHOLDER};
  font-weight: 500;
`;

const DivisionSelect = ({employee, groupId, roleId, onChangeDivision}) => {
  const { translate } = useI18n();
  const [divisions] = useDivisions();

  const getFreeOptions = () => {
    if(employee === undefined) return [];

    const groupDivisionsList = divisions.list.filter((p) => +p.groupId === +groupId).map(({ id, name }) => ({ value: id, label: name }));
   
    if(employee.assignments === null || employee.assignments[groupId] === undefined){
      return groupDivisionsList;
    }

    let divisionsIds = [];
    for(const assignment of employee.assignments[groupId]){
      if(!!assignment.roles && !!assignment.division && assignment.roles.includes(roleId)){
        divisionsIds.push(assignment.division);
      }
    }
    
    const divisionsList = groupDivisionsList.filter((division) => {
      if(!divisionsIds.includes(division.value)) return division;
    });
    
    return divisionsList;
  }

  const freeDivisions = useMemo(getFreeOptions, [employee]);

  return (
    <SelectContainer isActive={freeDivisions.length > 0 ? true : false}>
      <Dropdown
        labelProps={{
          label: translate('Assign Division')
        }} 
        leadingIconProps={{
          icon: 'role'
        }} 
        selectProps={{
          options: freeDivisions,
          onChange: value => onChangeDivision(value),
          placeholder: translate('Choose one')
        }}
      />
      {!employee && freeDivisions.length === 0 && (
        <Placeholder>{translate('Choose an employee first.')}</Placeholder>
      )}
      {!!employee && freeDivisions.length === 0 && (
        <Placeholder>{translate('No Division options for the chosen Employee')}</Placeholder>
      )}           
    </SelectContainer>
  )
}
export default DivisionSelect;
