import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import BasicText from 'components/DynamicTable/DynamicTableComponents/cells/BasicText';
import AppList from 'components/Table/AppList';
import RowContainer from 'components/Table/RowContainer';
import RowActions from './cells/Actions';
import SortText from 'components/Table/headers/SortText';
import SortNumber from 'components/Table/headers/SortNumber';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 3,
            header: ({globalState, ...rest}) => <SortText property={'name'} text={globalState.config.translate('Restaurant')} {...rest}/>,
            body: ({ item }) => <BasicText text={item.name} style={{ marginLeft: '16px' }} />,
        },
        {
            flex: 2,
            header: ({globalState, ...rest}) => <SortNumber property={'employees'} text={globalState.config.translate('# Employees')} {...rest}/>,
            body: ({ item }) => <BasicText text={item.employees} style={{ marginLeft: '16px' }} />,
        },
        {
            flex: 5,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Apps')} />,
            body: ({ item }) => <AppList idList={item.apps ? item.apps.map(({id})=>id) : []} />,
        },
        {
            flex: 2,
            header: () => <HeaderText text={''} />,
            body: RowActions,
        },
    ],
};

export default structure;
