import React, { useState } from 'react';
import Card from 'components/Card';
import { FormContainer } from 'containers/FormLayout';
import { TitleH2 } from 'components/Texts';
import Details from './sections/Details';
import Languages from './sections/Languages';
import Subject from './sections/Subject';
import Message from './sections/Message';
import { FormSpacer } from 'containers/FormLayout';
import validate from './validate';
import { InvalidText } from 'components/Texts';
import useI18n from 'i18n/useI18n';

import { PrimaryButton } from '@etiquette-ui/buttons';
import { Spinner } from '@etiquette-ui/misc';

const AdjustmentForm = ({ formData, setFormData, onSubmit, title, buttonText, loading = false }) => {
  const [errors, setErrors] = useState({});
  const { translate } = useI18n();

  const handleFormChange = (key, value) => {
    const tmp = { ...errors };
    delete tmp[key];
    setErrors(tmp);

    const data = { ...formData };
    data[key] = value;
    setFormData(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formData);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    onSubmit();
  };

  return (
    <Card>
      <FormContainer>
        <TitleH2 style={{ marginBottom: '20px' }}>{title}</TitleH2>
        <form onSubmit={handleSubmit} style={{ width: '90%' }}>
          <Details formData={formData} handleFormChange={handleFormChange} />
          <FormSpacer />
          <Languages formData={formData} handleFormChange={handleFormChange} errors={errors} />
          <Subject formData={formData} handleFormChange={handleFormChange} />
          <FormSpacer />
          <Message formData={formData} handleFormChange={handleFormChange} />
          <FormSpacer />
          {Object.keys(errors).length > 0 && (
            <>
              <InvalidText style={{ textAlign: 'center' }}>{translate('Errors found on Form')}</InvalidText>
              <FormSpacer />
            </>
          )}
          <PrimaryButton type={'submit'} disabled={loading} >
            {loading 
              ? <Spinner 
                  style={{
                    '--width': '15px',
                    '--margin': '0 5px',
                    '--bg': 'white',
                    '--color': 'white'
                  }}
                />
              : buttonText
            }
          </PrimaryButton>
        </form>
      </FormContainer>
    </Card>
  );
};

export default AdjustmentForm;
