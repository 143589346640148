import styled from 'styled-components';
import { Card as BaseCard } from '@etiquette-ui/misc';

const Card = styled(BaseCard)`
  margin: 0;
  border-radius: 24px;
  padding: 24px;
  position: relative;
`;

export default Card;