import colors from 'resources/constants/colors';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TextSpan = styled.span`
    display: flex;
    align-items: center;
    padding: 0 5px;
    ${({ color }) => (color ? `color: ${color};` : '')}
`;

export const TextBodyCell = styled.span`
  font-size: 0.875rem;
`;

export const TextInputStyled = styled.input`
    height: 36px;
    max-width: 100%;
    border-radius: 7px;
    border: solid 1px ${colors.border_grey_light};
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Avenir-book';
    line-height: 1.43;
    letter-spacing: 0.06px;
    color: black;
`;

export const Select = styled.select`
    padding : 4px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Avenir-book';
    border-radius: 3px;
`;

export const LoadedImage = styled.img`
  width: 100%; 
  height: 100%;
  object-fit: cover;
`;

export const ImageWrapper = styled.div`
  margin: auto 0;
  width: 45px;
  position: relative;
`;

export const ImageContainer = styled.div` 
  display: flex;
  background-color: #EBEBEB;
  width: 45px; 
  height: 45px; 
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
