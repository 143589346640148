import React, { useState } from 'react';
import Card from 'components/Card';
import { FormContainer, FormSpacer, GridContainer, Divider } from 'containers/FormLayout';
import { TitleH2, TitleH3, InvalidText } from 'components/Texts';
import useI18n from 'i18n/useI18n';
import { ACTIONS } from '../Reducer';
import GroupSelect from 'pages/Consolidate/components/GroupSelect';
import validate from './validate';
import { ACCESS_LIST } from 'resources/constants/config';

import { PrimaryButton } from '@etiquette-ui/buttons';
import { Spinner } from '@etiquette-ui/misc';
import { Input } from '@etiquette-ui/inputs-dropdowns';

import AddToDivision from 'pages/Consolidate/components/AddEmployee/AddToDivision';
import HeadersSelect from 'pages/Consolidate/components/AddEmployee/AddToDivision/HeadersSelect';

const Form = ({ onSubmit, formData, setFormData, loading = false }) => {
  const { translate } = useI18n();
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formData);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    onSubmit();
  };

  return (
    <Card>
      <FormContainer>
        <TitleH2 style={{ marginBottom: '20px' }}>{translate('New Division')}</TitleH2>
        <form onSubmit={handleSubmit} style={{ width: '90%' }}>
          <Card>
            <TitleH3 style={{ marginBottom: '20px' }}>{translate('Group')}</TitleH3>
            <GroupSelect
              selectedValues={formData.groups}
              onChange={(value) => setFormData({ type: ACTIONS.UPDATE_GROUP, payload: value })}
              onRemove={(value) => setFormData({ type: ACTIONS.REMOVE_GROUP, payload: value })}
              access={ACCESS_LIST.DIVISION}
            />
            {errors.groups && formData.groups.length === 0 &&  <InvalidText>{translate('You need to select at least one group')}</InvalidText>}
          </Card>
          {formData.groups.length > 0 && (
            <>
              <FormSpacer />
              <Card>
                <TitleH3>{translate('Details')}</TitleH3>
                <FormSpacer />
                <GridContainer>
                  <Input
                    labelProps={{label: translate('Name')}}
                    iconProps={{
                      icon: 'user'
                    }}
                    inputProps={{ 
                      value: formData?.name, 
                      onChange: (e) => setFormData({ type: ACTIONS.NAME, payload: e.target.value }), 
                      required: true 
                    }}
                  />
                  <Input
                    labelProps={{label: translate('Description of Division')}}
                    iconProps={{
                      icon: 'export',
                      iconStyles: {
                        line: {
                          fill: 'none',
                          strokeLinecap:'round',
                          strokeLinejoin:'round',
                          strokeWidth:'1.25px',
                          stroke: '#000'
                        }
                      }
                    }}
                    inputProps={{ 
                      value: formData?.description, 
                      onChange: (e) => setFormData({ type: ACTIONS.DESCRIPTION, payload: e.target.value }), 
                      required: true 
                    }}
                  />
                </GridContainer>
              </Card>
              <FormSpacer />
            </>
          )}
          {formData.groups.map((group, index) => (
            <div key={`${group.value}-${index}-${formData.id}`}>
              <Divider />
              <FormSpacer />
              <TitleH3 style={{ marginBottom: '20px', textAlign: 'center' }}>{group.label}</TitleH3>
              <Card>
                <TitleH3>{translate('Head(s) of the Division')}</TitleH3>
                <FormSpacer />
                <HeadersSelect 
                  title={translate('Head(s) of the Division (max 3)')}
                  formData={formData}
                  handleFormChange={(value) => setFormData({ type: ACTIONS.HEADS, payload: { groupId: group.value, users: value } })}
                  groupId={group.value}
                />
              </Card>
              <FormSpacer />
              <Card>
                <TitleH3>{translate('Employees')}</TitleH3>
                <FormSpacer />

                <AddToDivision
                  title={translate('Head(s) of the Division (max 3)')}
                  formData={formData}
                  handleFormChange={(value) => setFormData({ type: ACTIONS.USERS, payload: { groupId: group.value, users: value } })}
                  groupId={group.value}
                />

              </Card>
              <FormSpacer />
            </div>
          ))}
          <FormSpacer />
          {Object.keys(errors).length > 0 && (
            <>
              <InvalidText style={{ textAlign: 'center' }}>{translate('Errors found on Form')}</InvalidText>
              <FormSpacer />
            </>
          )}
          <PrimaryButton  type={'submit'} disabled={loading} >
            {loading 
              ? <Spinner 
                  style={{
                    '--width': '15px',
                    '--bg': 'white',
                    '--color': 'white'
                  }}
                />
              : translate('Create Division')}
          </PrimaryButton>
        </form>
      </FormContainer>
    </Card>
  );
};
export default Form;