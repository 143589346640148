import React from 'react';
import useApps from 'state/apps';
import styled from 'styled-components';
import { PUBLIC_URL } from 'resources/constants/urls';


const AppList = ({ idList }) => {
  const [apps] = useApps();

  return (
    <Container>
      {idList.map((id, i) => (
        <AppContainer key={i}>
          <Logo src={`${PUBLIC_URL}/logos/${apps.list.find((app) => app.id === +id)?.identifier}.png`} />
        </AppContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

const AppContainer = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

export default AppList;
