import React from 'react';
import colors from 'resources/constants/colors';
import styled from 'styled-components';
import { PassiveButton } from './Buttons';
import { InvalidText, Body } from './Texts';
import ImageUploading from 'react-images-uploading';
import MinusIcon from 'resources/img/minus-icon.svg'
import { IMAGES_URL } from 'resources/constants/urls';
import ImgSVG from 'resources/img/img-icon.svg';
import useI18n from 'i18n/useI18n';

const Container = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
`;

const Content = styled.div`
  height: 100%;
  position: relative;
  flex: 1;
`;

const ImageContainer = styled.div`
    display: flex;
    position: relative;

`;

export const IMAGE_SHAPES = {
  ROUNDED_RECTANGLE: 'rounded_rectangle',
  CIRCLE: 'circle',
}

const RoundedRectangleStyles = `
  max-width: 200px;
  max-height: 120px;
  width: 200px;
  border-radius: 8px;
`;

const CircleStyles = `
  max-width: 120px;
  max-height: 120px;
  width: 120px;
  border-radius: 60px;
`;

const Image = styled.img`
  object-fit: cover;
  overflow: hidden;
  ${({cutShape})=>{
    switch(cutShape){
      case IMAGE_SHAPES.CIRCLE:
        return CircleStyles;
      case IMAGE_SHAPES.ROUNDED_RECTANGLE:
      default:
        return RoundedRectangleStyles;
    }
  }}
`;

const Button = styled(PassiveButton)`
  width: 175px;
   &.alt {
      position: absolute;
      left: 180px;
      bottom: 0;
  }
`;

const RemoveImg = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: ${colors.cancel};
    position: absolute;
    ${({cutShape})=>{
      switch(cutShape){
        case IMAGE_SHAPES.CIRCLE:
          return 'left: 95px; top: 0px;';
        case IMAGE_SHAPES.ROUNDED_RECTANGLE:
        default:
          return 'left: 188px; top: -12px;';
      }
    }}
    align-items: center;
    justify-content: center;
    cursor: pointer;
     
    img {
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const ButtonText = styled(Body)`
  color: ${colors.accept};
`;

const ButtonIcon = styled.img`
  width: 25px;
  height: 25px;
`;

const ButtonContent = ({ text }) => (
  <ButtonContainer>
    <ButtonIcon src={ImgSVG} alt={'photo'} />
    <ButtonText>{text}</ButtonText>{' '}
  </ButtonContainer>
);

export const CustomImageInput = React.forwardRef(({ className, text, altText, error, src, onChange, shape=IMAGE_SHAPES.ROUNDED_RECTANGLE }, ref) => {

  const { translate } = useI18n();

  const handleChange = (imageList) => {
    if (imageList.length > 0) {
      onChange(imageList[0]);
    }
  };

  const getImgSrc = (src) => {
    if(typeof src === 'string' || src instanceof String) return `${IMAGES_URL}${src}`;
    if(typeof src === 'object') return src.dataURL;
    return '';
  }
  
  return (
    <Container className={className}>
      <Content>
        <ImageUploading onChange={handleChange} maxFileSize={0.2 * 1024 * 1024} acceptType={['jpg', 'jpeg', 'png']}>
          {({ onImageUpload, errors }) => (
            <ImageContainer>
              {src && 
              <Image src={getImgSrc(src)} alt={'new group profile'} cutShape={shape} />}
              <Button type="button" onClick={onImageUpload} className={src ? 'alt' : ''} >
                <ButtonContent text={src ? altText : text} />
              </Button>
              {src && <RemoveImg onClick={()=>onChange(null)} cutShape={shape}><img src={MinusIcon} alt={"remove profile"}/></RemoveImg>}
              {!!error && <InvalidText className="validationText">{error}</InvalidText>}
              {!!errors?.maxFileSize && <InvalidText className="validationText">{translate('Maximum file size is:', { size: '200 kB'})}</InvalidText>}
            </ImageContainer>
          )}
        </ImageUploading>
      </Content>
    </Container>
  );
});
