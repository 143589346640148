import React from 'react';
import Actions from 'components/DynamicTable/DynamicTableComponents/Editable/Actions';
import ActionEditIcon from 'resources/img/action-edit-icon.svg';
import ActionDeleteIcon from 'resources/img/action-delete-icon.svg';
import Switch from 'components/Switch';
import { ALERT_TYPES } from '@etiquette-ui/alert-dialog';
import { useAccess } from 'providers/access';
import { ACCESS_LIST, ACCESS_USER_TYPES } from 'resources/constants/config';

const RowActions = ({ item, state, globalState }) => {

  const { hasAccessInGroup } = useAccess();

  const onActiveChange = async () => {
    const obj = {};
    obj.id = item.id;
    obj.isActive = !!item.isActive ? 0 : 1;
    globalState.config.dispatcher.updateStart(obj);
  };

  const actions = [
    {
      event: () => {
        globalState.config
          .alert({
            type: ALERT_TYPES.CONFIRM,
            config: {
              title: globalState.config.translate('Delete Group'),
              body: globalState.config.translate('Are you sure you want to delete this group?'),
              closeText: globalState.config.translate('Go Back'),
              submitText: globalState.config.translate('Delete'),
            },
          })
          .then(() => {
            globalState.config.dispatcher.deleteStart({ id: +item.id });
          })
          .catch(() => {});
      },
      src: ActionDeleteIcon,
      disabled: !hasAccessInGroup(item.id, null, ACCESS_USER_TYPES.ROOT),
    },
    {
      event: () => globalState.config.history.push(`/${item.id}`),
      src: ActionEditIcon,
    },
    {
      child: <Switch active={item.isActive} onClick={onActiveChange} disabled={!hasAccessInGroup(item.id, ACCESS_LIST.GROUP)}/>,
    },
  ];

  return <Actions loading={globalState.config.data.fetching?.config?.id==item.id} actions={actions} />;
};

export default RowActions;
