import React from 'react';
import { BaseDialog } from '@etiquette-ui/alert-dialog';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import {PassiveButton, ColorButton} from 'components/Buttons';

const Container = styled.div`
  max-width: 380px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 30px;

  p{
    font-size: 18px;
    margin: 10px auto;
  }

`;

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-bottom: 20px;
`;

const Title = styled.h4`
  text-align: center;
`;

const Active = ({open, onSubmit, onClose}) => {
  const { translate } = useI18n();

  return (
    <BaseDialog open={open} onClose={onClose} dismissible={false} isResponsive>
        <Title>{translate('Activate Restaurant')}</Title>
        <Container>
             <p>{translate('Want Activate')}</p>

             <p>{translate('Apps available')}</p>
        </Container>

        <Section> 
            <PassiveButton onClick={onClose}>
            {translate('Go Back')}
            </PassiveButton>

            <ColorButton onClick={onSubmit}>
                {translate('Activate')}
            </ColorButton>
        </Section>
    </BaseDialog>
  );
};

export default Active;
