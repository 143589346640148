import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import TableCardContainer from "pages/Consolidate/components/TableCardContainer";
import FloatingActionButton from "components/FloatingActionButton";
import useI18n from "i18n/useI18n";
import Table from "components/Table";
import Structure from "./structure";
import LayoutTable from "pages/Consolidate/containers/LayoutTable";

import useUsers from "state/users";
import usePermissions from "state/permissions";
import useDivisions from 'state/divisions'
import useRoles from "state/roles";
import { useSearch } from "providers/search";
import { getFullName } from "utils/text";
import { compareStrings } from "utils";
import { useAlert } from '@etiquette-ui/alert-dialog';

const List = ({ location }) => {
  const { translate } = useI18n();

  const history = useHistory();
  const { path } = useRouteMatch();
  const [users, dispatcher] = useUsers();
  const [permissions, permissionsDispatcher, permissionsUtils] = usePermissions();
  const [divisions, divisionsDispatcher, divisionsUtils] = useDivisions();
  const [roles, rolesDispatcher, rolesUtils] = useRoles();
  const [list, setList] = useState([]);
  const { search, groupsList } = useSearch();
  const alert = useAlert();

  useEffect(() => {
    dispatcher.fetchStart();
    permissionsDispatcher.fetchStart();
    divisionsDispatcher.fetchStart();
    rolesDispatcher.fetchStart();
  }, []);

  useEffect(() => {
    const userList = users.list;
    const withPermissions = userList.map(user => ({ ...user, permissions: user.permissions ? user.permissions.map(id => permissionsUtils.findOne(id)) : [] }))
    const baseTree = withPermissions.map(user => ({ ...user, assignments: modifyAssignmentStructure(user.assignments) }))
    const combinedAssignments = combinePermissionsAndAssignments(baseTree)

    setList(combinedAssignments)
  }, [users.list, permissions.list, divisions.list, roles.list])

  const modifyAssignmentStructure = (assignments) => {
    const newAssignment = {};
    if (assignments) {
      for (const key in assignments) {
        if (Object.hasOwnProperty.call(assignments, key)) {
          newAssignment[key] = {
            assignments: loadDivisionAndRolesName(assignments[key])
          }
        }
      }
    }

    return newAssignment;
  }

  const combinePermissionsAndAssignments = (baseTree) => baseTree.map(item => {
    if (item.permissions) {
      item?.permissions.forEach(permission => {
        if (permission?.groupId && item.assignments[permission?.groupId]) {
          item.assignments[permission.groupId].permission = permission;
        } else {
          item.assignments[permission?.groupId] = { permission }
        }
      });
    }
    return item;
  })

  const loadDivisionAndRolesName = (assignment) => assignment ?
    assignment.map(a => ({ ...a, divisionName: translateDivision(a.division), rolesNames: a.roles.map(r => translateRoles(r)) })) : [];


  const divisionsDict = {};
  const translateDivision = (divisionId) => {
    if (!divisionsDict[divisionId]) {
      const division = divisionsUtils.findOne(divisionId);
      divisionsDict[divisionId] = division ? division.name : "";
    };

    return divisionsDict[divisionId];
  }

  const rolesDict = {};
  const translateRoles = (roleId) => {
    if (!rolesDict[roleId]) {
      const role = rolesUtils.findOne(roleId);
      rolesDict[roleId] = role ? role.name : "";
    }

    return rolesDict[roleId]
  }

  const items = useMemo(() => {
    let newList = [...list];
    if (search.length !== 0)
      newList = newList.filter(
        (user) =>
          compareStrings(getFullName(user), search) ||
          compareStrings(user.name, search) ||
          compareStrings(user.secondName ?? '', search) ||
          compareStrings(user.lastName ?? '', search) ||
          compareStrings(user.shortName ?? '', search)
      );
    
    if (groupsList.length > 0) newList = newList.filter(({assignments}) => {     
      const groupsMatched = Object.keys(assignments).filter( groupId => groupsList.includes(+groupId) );
      return groupsMatched.length > 0 ? true : false;
    });
    return newList;
  }, [list, search, groupsList]);

  return (
    <LayoutTable location={location}>
      <TableCardContainer>
        <Table
          structure={Structure}
          items={items}
          config={{ history, dispatcher, translate, alert }} />
        <FloatingActionButton to={`${path}/create`}>
          {translate("Add employee")}
        </FloatingActionButton>
      </TableCardContainer>
    </LayoutTable>
  );
};

export default List;
