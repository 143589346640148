import React, { useState } from 'react';

const SearchContext = React.createContext();

const SearchProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [groupsList, setGroupsList] = useState([]);
  
  const values = {
    visible, setVisible,
    search, setSearch,
    groupsList, setGroupsList
  }

  return <SearchContext.Provider value={values}>{children}</SearchContext.Provider>;
};

export const useSearch = () => React.useContext(SearchContext);

export default SearchProvider;