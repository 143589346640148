import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Card from 'components/Card';
import { BaseButton } from 'components/Buttons';
import colors from 'resources/constants/colors';
import RequestApps from './RequestApps';
import useI18n from 'i18n/useI18n';
import ApiRequestApps from 'resources/api/requestApps';
import { useParams } from 'react-router';

import { PrimaryButton } from '@etiquette-ui/buttons';


const AppContainer = ({ children, onClick, appListSelected = [] }) => {
  const { translate } = useI18n();
  const [alert, setAlert] = useState(false)
  const [isSelecting, setIsSelecting] = useState(true);
  const { groupId } = useParams();

  const appListSelectedLength = appListSelected.length;

  const handleSubmitApps = () => {
    const appsList = appListSelected.map(app => app.identifier)
    ApiRequestApps(appsList, groupId)
  }

  useEffect(() => {
    if (alert && appListSelectedLength !== 0) {
      setAlert(false)
    }
  }, [appListSelected])

  const handleSubmit = () => {
    if (!!appListSelectedLength) {
      setIsSelecting(false)
      handleSubmitApps();
    } else {
      setAlert(true)
    }
  }

  return (
    <Card>
      <TitleContainer>
        <p>{translate("Learn Apps")}</p>
      </TitleContainer>

      <LearnBlock display={isSelecting ? "inline" : "none"}>
        {children}
        <ButtonContainer>
          <div className="Ochenta">
            <ButtonEti onClick={handleSubmit}  >
              {`${translate("Request Apps")} (${appListSelectedLength})`}
            </ButtonEti>
          </div>
        </ButtonContainer>
        <Alert display={alert ? "block" : "none"}>
          <p>{translate("Should Select")}</p>
        </Alert>
      </LearnBlock>

      <ConfirmationBlock display={isSelecting ? "none" : "block"}>
        <RequestApps appListSelected={appListSelected} />
        <ButtonContainer>
          <ButtonSpan onClick={onClick}>
            {translate("Back Profile")}
          </ButtonSpan>
        </ButtonContainer>
      </ConfirmationBlock>

    </Card>
  )
}

const ButtonEti = styled(PrimaryButton)`
  font-weight: 600;
  &:hover {
    color: ${colors.accept};
    background-color: ${colors.lightBlueButton};
    border: ${colors.lightBlueButton};
  }
`;

const ButtonSpan = styled(BaseButton)`
  width: 199px;
  height: 45px;
  outline: none;
  border: none;
  background-color: ${colors.lightBlueButton};
  color: ${colors.accept};
  display: block;
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
  &:hover {
    box-shadow: -8px -8px 10px ${colors.shadow}, 
    8px 8px 10px ${colors.shadow}, 
    -8px 8px 10px ${colors.shadow}, 
    8px -8px 10px ${colors.shadow};
  }
  &.alt {
      position: absolute;
      left: 180px;
      bottom: 0;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  margin: 8px 0 16px 0;
  display: flex;
  justify-content: center;
  .Ochenta {
    width: 65%;
  }
`;

const LearnBlock = styled.div`
  display: ${({ display }) => (display || 'inline')};
`;

const ConfirmationBlock = styled.div`
  margin-top: 58px;
  display: ${({ display }) => (display || 'inline')};
`;

const Alert = styled.div`
  p {
    color: red;
    font-size: 0.875rem;
    text-align: center;
    display: ${({ display }) => (display || 'block')}
  }
`;

export default AppContainer;