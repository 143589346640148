import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import BasicText from 'components/DynamicTable/DynamicTableComponents/cells/BasicText';
import RowActions from './cells/Actions';
import RowContainer from 'components/Table/RowContainer';
import UserGrid from 'components/Table/UserGrid';
import SortText from 'components/Table/headers/SortText';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 3,
            header: ({globalState, ...rest}) => <SortText property={'name'} text={globalState.config.translate('Name')} {...rest}/>,
            body: ({ item }) => <BasicText text={item.name} style={{ marginLeft: '10px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 8,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Employees')} />,
            body: ({item, globalState}) => <UserGrid heads={item.heads} users={item.users} divisionId={item.id} groupId={globalState.config.groupId}/>,
        },
        {
            flex: 2,
            header: () => <HeaderText text={''} />,
            body: RowActions,
        },
    ],
};

export default structure;