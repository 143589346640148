import React, { useMemo } from 'react';
import useUsers from 'state/users';
import styled from 'styled-components';
import UserItem from 'components/UserItem';
import useI18n from 'i18n/useI18n';
import useDivisions from 'state/divisions';
import { getFullName } from 'utils/text';

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const User = styled(UserItem)`
  width: 260px;
  margin: 10px 0;
`;

const DEFAULT_USERS = [];

const RoleUserGrid = ({ users = DEFAULT_USERS }) => {
  const [usersState,, userUtils] = useUsers();
  const [divisions] = useDivisions();
  const { translate } = useI18n();

  const processUser = (user, divisions) => {
    const { name, lastName, image } = user;
    return { user: { name, lastName, image, divisions: getDivisionsList(divisions) } }
  }

  const getDivisionsList = (divisionIds) => {
    return `${divisionIds.length > 0 ? `${divisionIds.map((divisionId)=>divisions.list.find(({id})=>id===divisionId)?.name ?? '').join(', ')}` : ''}`;
  }

  const list = useMemo(() => {
    if (usersState.list.length === 0 || users.length === 0) return [];
    const reorder = users.map(({id, divisions}) => {
        const user = userUtils.findOne(id);
        return !!user ? processUser(user, divisions) : {};
      });
    return reorder;
  }, [users, usersState.list]);

  return (
    <Grid>
      {!!list && list.map(({ user }, i) => (
        <User
          key={i}
          name={user && getFullName(user)}
          image={user?.image}
          position={`${!!user.divisions ? (translate('Divisions')+': ') : ''}${user.divisions}`}
        />
      ))}
    </Grid>
  );
};

export default RoleUserGrid;
