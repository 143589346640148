import useI18n from 'i18n/useI18n';
import React, { useEffect, useState } from 'react';
import PermissionForm from '../components/PermissionForm';
import usePermissions from 'state/permissions';
import useApps from 'state/apps';
import { createPermissionObject } from '../permission';
import { useParams } from 'react-router-dom';
import { useRouter } from 'providers/router';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import FormHeader from 'pages/Group/components/FormHeader';


const Edit = ({ location }) => {
  const [permissionId, setPermissionId] = useState('');
  const { translate } = useI18n();
  const [data, dispatcher] = usePermissions();
  const [apps] = useApps();
  const [formData, setFormData] = useState({selectedApps: [], selectedPermissions: {}});
  const { groupId } = useParams();
  const { route, canGoBack } = useRouter();
  
  useEffect(() => {
    const url = location.pathname.split('/');
    const id = url.pop();
    setPermissionId(id);
    dispatcher.fetchOneStart({groupId, id});
    return () => dispatcher.resetDetail();
  }, []);

  useEffect(() => {
    if(!data.detail || !data.detail.permission || !data.detail.access || !apps.list) return;

    const formData = {};
    formData.name = data.detail.permission?.name ?? '';
    formData.description = data.detail.permission?.description ?? '';
    const selectedApps = [];
    const selectedPermissions = {};
    data.detail.access.forEach((acs)=>{

      if(!selectedApps.includes(acs.appId)) selectedApps.push(acs.appId);

      const appIdentifier = apps.list.find(({id})=>+id===+acs.appId)?.identifier;
      if(selectedPermissions[appIdentifier]) {
        selectedPermissions[appIdentifier].push(acs.accessIdentifier);
      } else {
        if(appIdentifier) selectedPermissions[appIdentifier] = [acs.accessIdentifier];
      }

    });
    formData.selectedApps = selectedApps;
    formData.selectedPermissions = selectedPermissions;

    setFormData(formData);

  }, [data.detail, apps]);

  const onSubmit = () => {
    const permission = createPermissionObject(formData, groupId, apps.list);
    permission.permission.id = permissionId;
    dispatcher.updateStart(permission);
  };  

  return (
    <FormScrollableContainer>
      <FormHeader route={canGoBack() ? route.from : `/${groupId}/management/permissions`} routeText={translate('Back to Permissions')} />
      <PermissionForm 
        onSubmit={onSubmit} 
        formData={formData} 
        setFormData={setFormData} 
        title={translate('Edit Permission')} 
        loading={data.fetching.state} 
        buttonText={translate('Update Permission')}
      />
    </FormScrollableContainer>
  );
}

export default Edit;
