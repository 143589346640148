import React, { useEffect } from 'react';
import colors from 'resources/constants/colors';
import usePermissions from 'state/permissions';
import styled from 'styled-components';
import { mapIdsToObjects } from 'utils';
import { Body } from 'components/Texts';

import { Spinner } from '@etiquette-ui/misc';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Permission = ({ permissions, groupId, count }) => {
  const [data] = usePermissions();

  return (
    <Container>
      {data.fetching.state ? (
        <Spinner 
          style={{
            '--width': '35px',
            '--margin': '0 5px',
            '--bg': `${count % 2 === 0 ? colors.backgroundLightGrey : 'white'}`,
            '--color': `${colors.backgroundDarkBlue}`
          }}
        />
       ) : (
        permissions &&
        mapIdsToObjects(permissions, data.list)
          .filter((p) => +p.groupId === +groupId)
          .map(({ id, name }) => <Body key={id}>{name}</Body>)
      )}
    </Container>
  );
};

export default Permission;
