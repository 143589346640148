import React from 'react';
import Actions from 'components/DynamicTable/DynamicTableComponents/Editable/Actions';
import ActionEditIcon from 'resources/img/action-edit-icon.svg';
import ActionDeleteIcon from 'resources/img/action-delete-icon.svg';
import { ALERT_TYPES } from '@etiquette-ui/alert-dialog';

const RowActions = ({ item, globalState }) => {

  const actions = [
    {
      event: () => {
        globalState.config
          .alert({
            type: ALERT_TYPES.CONFIRM,
            config: {
              title: globalState.config.translate('Delete Sale Adjustment'),
              body: globalState.config.translate('Are you sure you want to delete this sale adjustment?'),
              closeText: globalState.config.translate('Go Back'),
              submitText: globalState.config.translate('Delete'),
            },
          })
          .then(() => {
            globalState.config.dispatcher.deleteStart({ id: +item.id, groupId: globalState.config.groupId });
          })
          .catch(() => {});
      },
      src: ActionDeleteIcon,
    },
    {
      event: () => globalState.config.history.push(`adjustments/edit/${item.id}`),
      src: ActionEditIcon,
    },
  ];

  return <Actions loading={globalState.config.data.fetching?.config?.id === item.id} actions={actions} style={{ alignItems: 'start', marginTop: '10px', }} />;
};

export default RowActions;
