import Types from "./types";

export default class ProductsDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchPaymentMethods = () =>  this.dispatch({ type: Types.FETCH_START_PAYMENT_METHOD, payload: { } });

    fetchSaleTypes = () =>  this.dispatch({ type: Types.FETCH_START_SALE_TYPE, payload: { } });

    fetchApps = () =>  this.dispatch({ type: Types.FETCH_START_APP, payload: { } });

    fetchLangs = () =>  this.dispatch({ type: Types.FETCH_START_LANG, payload: { } });

    resetErrorPaymentMethods = () => this.dispatch({ type: Types.RESET_ERROR_PAYMENT_METHOD, payload: {} });

    resetErrorSaleTypes = () => this.dispatch({ type: Types.RESET_ERROR_SALE_TYPE, payload: {} });

    resetErrorApps = () => this.dispatch({ type: Types.RESET_ERROR_APP, payload: {} });
    
    resetErrorLangs = () => this.dispatch({ type: Types.RESET_ERROR_LANG, payload: {} });
}