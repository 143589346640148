import React, { useEffect } from 'react';
import Card from 'components/Card';
import useI18n from 'i18n/useI18n';
import useNomenclators from 'state/nomenclators';
import { TitleH3 } from 'components/Texts';
import { RadioButton } from '@etiquette-ui/selectors';
import styled from 'styled-components';

const RadioContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

const CustomRadio = styled(RadioButton)`
  width: auto;
  margin-right: 15px;
`;

const Types = ({ dispatcher, data }) => {
  const { translate } = useI18n();
  const [{ saleTypes }, nomenclatorDispatcher] = useNomenclators();

  useEffect(() => {
    nomenclatorDispatcher.fetchSaleTypes();
  }, []);

  const getCheckStatus = (saleType, sale) => {
    if(saleType.id !== sale.saleType) return false;

    if(!!sale.saleTypeName) return true;
    
    dispatcher({ type: 'saleType', payload: {id: saleType.id, name: saleType.name} });
    return true;
  }

  return (
    <Card>
      <TitleH3>{translate('Type')}</TitleH3>
      <RadioContainer>
        {!!saleTypes && saleTypes.map((saleType) => (
          <CustomRadio
            key={saleType.id}
            checked={getCheckStatus(saleType, data.sale)}
            onClick={() => dispatcher({ type: 'saleType', payload: {id: saleType.id, name: saleType.name} })}
          >{translate(saleType.name)}</CustomRadio>
        ))}
      </RadioContainer>
    </Card>
  );
};

export default Types;
