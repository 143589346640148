import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Dispatcher from './dispatcher';
import selector from './selector';

const useApps = () => {
    const dispatcher = new Dispatcher(useDispatch());
    const state = useSelector(selector, shallowEqual);

    return [state, dispatcher];
}

export default useApps;