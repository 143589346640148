import React, { useState } from 'react';
import { HeaderSort, HEADER_SORT_TYPES } from '@etiquette-ui/dynamic-table-components';

const SortText = ({ applyFilter, resetFilters, filters, property, text }) => {
  const [type, setType] = useState(HEADER_SORT_TYPES.UNORDER);

  const onClick = () => {
    resetFilters();
        
    let localType = type;
    switch(type){
      case HEADER_SORT_TYPES.DESC:
        localType = HEADER_SORT_TYPES.ASC;
        break;
      case HEADER_SORT_TYPES.ASC:
        localType = HEADER_SORT_TYPES.UNORDER;
        break;
      case HEADER_SORT_TYPES.UNORDER: 
        localType = HEADER_SORT_TYPES.DESC;
    }
    setType(localType);

    applyFilter(property, (items) => {
      return items.sort((a, b) => {          
        if(localType === HEADER_SORT_TYPES.UNORDER) return 0;

        const A = (a[property] ?? '').toUpperCase();
        const B = (b[property] ?? '').toUpperCase();
        if (A < B) {
          return localType===HEADER_SORT_TYPES.ASC ? 1 : -1;
        }
        if (A > B) {
          return localType===HEADER_SORT_TYPES.ASC ? -1 : 1;
        }
      });
    });
  };

  return <HeaderSort 
    text={text} 
    sortType={type}
    onClick={onClick} 
    style={{ marginLeft: '16px' }}
    enabled={!Array.from(filters.keys()).includes(property)} 
  />;
};

export default SortText;
