import PrivateRoute from 'containers/PrivateRoute';
import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { ACCESS_USER_TYPES } from 'resources/constants/config';
import useRoles from 'state/roles';
import Create from './pages/Create';
import List from './pages/List';


const Roles = () => {
  const { path } = useRouteMatch();
  const [, dispatcher] = useRoles();

  useEffect(()=>{
    dispatcher.fetchStart();
  },[])

  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route exact path={`${path}/create`} component={Create} />
    </Switch>
  );
};

export default Roles;
