import React, { useState } from 'react';
import { HeaderSort, HEADER_SORT_TYPES } from '@etiquette-ui/dynamic-table-components';

const SortDate = ({ title, property = 'createdAt', applyFilter, resetFilters, globalState, filters }) => {
  const [type, setType] = useState(HEADER_SORT_TYPES.UNORDER);

  const onClick = () => {
    resetFilters();

    let localType = type;
    switch(type){
      case HEADER_SORT_TYPES.DESC:
        setType(HEADER_SORT_TYPES.ASC);
        break;
      case HEADER_SORT_TYPES.ASC:
        setType(HEADER_SORT_TYPES.UNORDER);
        break;
      case HEADER_SORT_TYPES.UNORDER: 
        setType(HEADER_SORT_TYPES.DESC);         
    }
    setType(localType);

    applyFilter('date', (items) => {
      return items.sort((first, second) => {
        if(type === HEADER_SORT_TYPES.UNORDER) return 0;

        const A = new Date(first[property]);
        const B = new Date(second[property]);
        if (A < B) {
          return type===HEADER_SORT_TYPES.ASC ? 1 : -1;
        }
        if (A > B) {
          return type===HEADER_SORT_TYPES.ASC ? -1 : 1;
        }
      });
    });
  };

  return  <HeaderSort 
    text={title} 
    onClick={onClick} 
    style={{ marginLeft: '16px' }} 
    enabled={Array.from(filters.keys()).includes('date' + property)} 
    iconProps={{icon: type === HEADER_SORT_TYPES.UNORDER ? 'drag' : (type === HEADER_SORT_TYPES.ASC ? 'arrow-up' : 'arrow-down')}}
  />;
};

export default SortDate;
