import React, { useState } from 'react';
import { TitleH2 } from 'components/Texts';
import useI18n from 'i18n/useI18n';
import { InvalidText } from 'components/Texts';
import validate from './validate';
import { FormContainer, FormSpacer } from 'containers/FormLayout';
import GroupInformation from './sections/GroupInformation';
import Contacts from './sections/Contacts';
import OpeningHours from './sections/OpeningHours';
import { ACTIONS } from './Reducer';
import { PrimaryButton } from '@etiquette-ui/buttons';

const CreateGroupForm = ({ title, formData, dispatch, handleSubmit, button }) => {
  const { translate } = useI18n();
  const [errors, setErrors] = useState({});

  const handleGroupInfo = (field, value) => {
    const tmp = { ...errors };
    delete tmp[field];
    setErrors(tmp);

    const newField = { [field]: value };
    dispatch(ACTIONS.GROUP_INFO, newField);
  };

  const handleContacts = (index) => (key, value) => {
    const tmp = { ...errors };
    delete tmp[`${key}_${index}`];
    setErrors(tmp);
    
    dispatch(ACTIONS.EDIT_CONTACT, { index, key, value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formData);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    handleSubmit();
  };

  return (
    <FormContainer>
      <TitleH2 style={{ marginBottom: '20px' }}>{title}</TitleH2>
      <form onSubmit={onSubmit}>
        <GroupInformation formData={formData} handleGroupInfo={handleGroupInfo} errors={errors} />
        <FormSpacer />
        <OpeningHours formData={formData} dispatch={dispatch}/>
        <FormSpacer />
        <Contacts formData={formData} handleContacts={handleContacts} dispatch={dispatch} errors={errors} />
        <FormSpacer />
        {Object.keys(errors).length > 0 && (
          <>
            <InvalidText style={{ textAlign: 'center' }}>{translate('Errors found on Form')}</InvalidText>
            <FormSpacer />
          </>
        )}
        <PrimaryButton type={'submit'}>
          {button}
        </PrimaryButton>
      </form>
    </FormContainer>
  );
};

export default CreateGroupForm;
