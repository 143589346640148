import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { PassiveButton } from 'components/Buttons';
import { Body } from 'components/Texts';
import { TitleH2 } from 'components/Texts';
import { GridContainer } from 'containers/FormLayout';
import { FormContainer } from 'containers/FormLayout';
import useI18n from 'i18n/useI18n';
import React, { useState } from 'react';
import useApps from 'state/apps';
import styled from 'styled-components';
import AppCard from './AppCard';
import AppSelection from 'components/alert/AppSelection';
import { useParams } from 'react-router-dom';
import { FormSpacer, Center } from 'containers/FormLayout';
import validate from './validate';
import { InvalidText } from 'components/Texts';

import { PrimaryButton } from '@etiquette-ui/buttons';
import { Spinner, Card } from '@etiquette-ui/misc';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import { SecondaryButton } from '@etiquette-ui/buttons';

const AppCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  width: 100%;
`;

const PermissionForm = ({ formData, setFormData, onSubmit, title, buttonText, loading = false }) => {
  const { translate } = useI18n();
  const alert = useAlert();
  const [apps] = useApps();
  const { groupId } = useParams();
  const [errors, setErrors] = useState({});

  const handleFormChange = (key, value) => {
    const tmp = { ...errors };
    delete tmp[key];
    setErrors(tmp);
    const data = { ...formData };
    data[key] = value;
    setFormData(data);
  };

  const handleBulkChange = (changes) => {
    const tmp = { ...errors };
    const data = { ...formData };

    for(const {key, value} of changes) {
      delete tmp[key];
      setErrors(tmp);
      data[key] = value;
    }
    
    setFormData(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formData);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    onSubmit();
  };

  const handleOpen = () => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: AppSelection,
        props: {
          groupId,
          selectedApps: formData.selectedApps,
        },
      },
    })
      .then((selected) => {
        const apps = [...formData.selectedApps, ...selected];
        handleFormChange('selectedApps', apps);
      })
  };

  const handlePermissions = (key, permissions) => {
    const newPermissions = { ...formData.selectedPermissions };
    newPermissions[key] = permissions;
    handleFormChange('selectedPermissions', newPermissions);
  };

  const handleAppRemove = (app) => {
    const newPermissions = { ...formData.selectedPermissions };
    delete newPermissions[app.identifier];

    const apps = [...formData.selectedApps];
    const index = apps.indexOf(app.id);

    if (index !== null && index !== undefined) {
      apps.splice(index, 1);
      handleBulkChange([{ key:'selectedPermissions', value: newPermissions}, {key: 'selectedApps', value: apps}]);
    }
  };

  return (
    <Card>
      <FormContainer>
        <TitleH2 style={{ marginBottom: '20px' }}>{title}</TitleH2>
        <form onSubmit={handleSubmit} style={{ width: '90%' }}>
          <Card>
            <h3>{translate('Details')}</h3>
            <GridContainer>
              <Input
                labelProps={{label: translate('Permission Name (without spaces)')}}
                iconProps={{
                  icon: 'title'
                }}
                inputProps={{
                  value: formData.name, 
                  onChange: (e) => handleFormChange('name', e.target.value.replaceAll(' ', '')), 
                  required: true 
                }}
              />
              <Input
                labelProps={{label: translate('Description')}}
                inputProps={{
                  value: formData.description, 
                  onChange: (e) => handleFormChange('description', e.target.value), 
                  required: true 
                }}
                iconProps={{
                  icon: 'export',
                  iconStyles: {
                    line: {
                      fill: 'none',
                      strokeLinecap:'round',
                      strokeLinejoin:'round',
                      strokeWidth:'1.25px',
                      stroke: '#000'
                    }
                  }
                }}
              />
            </GridContainer>
          </Card>
          <FormSpacer />
          <Card>
            <h3>{translate('Applications')}</h3>
            {formData.selectedApps.length > 0 ? (
              <AppCardGrid>
                {formData.selectedApps.map((idApp) => {
                  const app = apps.list.find(({ id }) => id === idApp);
                  return (
                    <AppCard key={idApp} app={app} handleRemove={() => handleAppRemove(app)} selected={formData.selectedPermissions} setSelected={handlePermissions} />
                  );
                })}
                {apps.list.filter((app) => +groupId === app.groupId).length > formData.selectedApps.length && (
                  <Center>
                    <PassiveButton type="button" style={{ width: '210px', height: '50px' }} onClick={handleOpen}>
                      {translate('Add Applications')}
                    </PassiveButton>
                  </Center>
                )}
              </AppCardGrid>
            ) : (
              <>
                <Body style={{ textAlign: 'center' }}>{translate('Press button to add applications.')}</Body>
                <FormSpacer />
                <Center>
                  <div className="" style={{ width: '210px' }}>
                    <SecondaryButton type="button" onClick={handleOpen}>
                      {translate('Add Applications')}
                    </SecondaryButton>
                  </div>                  
                </Center>
              </>
            )}
            {errors.selectedPermissions && (
              <>
                <FormSpacer />
                <InvalidText style={{ textAlign: 'center' }}>{translate('At least one permission required')}</InvalidText>
                <FormSpacer />
              </>
            )}
          </Card>
          <FormSpacer />
          {Object.keys(errors).length > 0 && (
            <>
              <InvalidText style={{ textAlign: 'center' }}>{translate('Errors found on Form')}</InvalidText>
              <FormSpacer />
            </>
          )}
          <PrimaryButton type={'submit'} disabled={loading}>
            {loading 
              ? <Spinner 
                  style={{
                    '--width': '15px',
                    '--margin': '0 5px',
                    '--bg': 'white',
                    '--color': 'white'
                  }}
                /> 
              : buttonText
            }
          </PrimaryButton>
        </form>
      </FormContainer>
    </Card>
  );
};

export default PermissionForm;
