const colors = {
  base: "#364456",
  textMain: "#0F191B",
  accept: "#388CFF",
  cancel: "#E84961",
  disabled : "#5F5F5F",
  neutral: "#D3D4D6",
  lightGrey : "#033269",
  lightBlueButton : "#EEFBFF",
  backgroundLightGrey: "#EDEDED",
  backgroundDarkBlue: "#364456",
  white : '#FFF',
  backgroundLight: "#FFF",
  backgroundDark: "#364456",
  lightBlue: "#83E2FF4C",
  backgroundLightBlue: "#EEFBFF",
  shadow: "#03326914",
  lightRed: "#E849614C",
  active: "#A9F2AF",
  inactive: "#E84961",
  gray: "#EDEDED",
  darkGray: "#D3D4D6",
  green: "#61C94E",
  yellow: "#F9CE33",
  blue: "#2877FB",
  purple: "#A457DA",
 };

export default colors;