import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import { Text } from '@etiquette-ui/dynamic-table-components';
import RowActions from './cells/Actions';
import RowContainer from 'components/Table/RowContainer';
import { formatMoney } from 'utils/text';
import SortDate from './headers/SortDate';
import SortText from 'components/Table/headers/SortText';

const formatDate = (dateString) => {
    const dateArray = dateString.split("-");

    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0].substring(2,4)}`;
}

const calculateTotal = (item) => {
    let total = 0;
    item.movements.forEach(m => total += (+m.food + +m.drink + +m.iva));

    return formatMoney(total);
};

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 2,
            header: SortDate,
            body: ({ item }) => <Text text={formatDate(item.eventDate)} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 2,
            header: ({globalState, ...rest}) => <SortText property={'name'} text={globalState.config.translate('Name')} {...rest}/>,
            body: ({ item }) => <Text text={item.name} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 1,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Amount')} style={{ marginLeft: '16px' }} />,
            body: ({ item }) => <Text text={calculateTotal(item)} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 1,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Type')} style={{ marginLeft: '16px' }} />,
            body: ({ item }) => <Text text={item.type} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 2,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Payment')} style={{ marginLeft: '16px' }} />,
            body: ({ item }) => <Text text={item.payment} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 2,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Last Entry')} />,
            body: ({ item }) => <Text text={item.lastUpdatedByName} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 1,
            header: () => <HeaderText text={''} />,
            body: RowActions,
        },
    ],
};

export default structure;