import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import styled from 'styled-components';
import { TitleH4 } from 'components/Texts';
import useApps from 'state/apps';
import Permission from './Permission';
import RedMinus from 'resources/img/minus-icon.svg'
import { PUBLIC_URL } from 'resources/constants/urls';

const AppCard = ({ app, selected, setSelected, handleRemove }) => {
  const [permissions, setPermissions] = useState([]);
  const [apps] = useApps();

  useEffect(() => {
    const newApp = apps.available.find(({ identifier }) => identifier === app?.identifier);
    if (!!newApp?.access) {
      setPermissions(Object.entries(newApp.access));
    }
  }, [apps.available, app]);

  const onSelect = (permission) => {
    const permissions = selected[app.identifier] ? [...selected[app.identifier]] : [];
    if (permissions.includes(permission)) {
      const index = permissions.indexOf(permission);
      permissions.splice(index, 1);
    } else {
      permissions.push(permission);
    }
    setSelected(app.identifier, permissions);
  }

  return (
    <CardContainer>
      <Remove src={RedMinus} alt={'remove-app'} onClick={handleRemove} />
      <Header>
        <Icon src={`${PUBLIC_URL}/logos/${app?.identifier}.png`} />
        <TitleH4 style={{ marginLeft: '10px', alignSelf: 'center' }}>{app?.name}</TitleH4>
      </Header>
      <PermissionsContainer>
        {permissions.map((obj, i) => (
          <Permission
            key={i}
            text={obj[1]} checked={selected[app.identifier] ? selected[app.identifier].includes(obj[0]) : false} onClick={() => onSelect(obj[0])} />
        ))}
      </PermissionsContainer>
    </CardContainer>
  );
};



const Header = styled.div`
  display: flex;
`;

const CardContainer = styled(Card)`
  width: 242px;
  height: 320px;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Icon = styled.img`
  width: 64px;
  height: 64px;
`;

const PermissionsContainer = styled.div`
  flex: 1;
  overflow: auto;
  margin-top: 10px;
`;

const Remove = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
`;


export default AppCard;
