import INIT_STATE, { DEFAULT_CONFIG, DEFAULT_SPECIAL_DATE, INIT_CONTACT } from "./initState";

export const ACTIONS = {
  INIT: 'init',
  GROUP_INFO: 'groupInfo',
  EDIT_CONTACT: 'editContact',
  CONTACT: 'contact',
  EDIT_SCHEDULE: 'editSchedule',
  EDIT_SPECIAL_DATES: 'editSpecialDates',
  SPECIAL_DATES: 'specialDates',
};

const Reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.INIT: { 
      const newState = {...action.payload};
      if(!newState.config || Object.keys(newState.config).length === 0) {
        newState.config = {...DEFAULT_CONFIG};
      }
      return newState;
    }
    case ACTIONS.GROUP_INFO: {
      const newState = { ...state, ...action.payload };
      return newState;
    }
    case ACTIONS.EDIT_CONTACT: {
      const contacts = [...state.contacts];
      contacts[action.payload.index] = {...contacts[action.payload.index], [action.payload.key]: action.payload.value };

      return { ...state, contacts };
    }
    case ACTIONS.CONTACT: {
      const contacts = [...state.contacts];
      if(action.payload) {
        contacts.splice(action.payload, 1);
      } else {
        contacts.push({...INIT_CONTACT});
      }
      return { ...state, contacts };
    }
    case ACTIONS.EDIT_SCHEDULE: {
      const config = {...state.config};
      const openingHours = {...config.openingHours};
      const schedule = {...openingHours.schedule};

      schedule[action.payload.day] = { ...schedule[action.payload.day], [action.payload.key]: action.payload.value }

      openingHours.schedule = schedule;
      config.openingHours = openingHours;
      return { ...state, config };
    }
    case ACTIONS.EDIT_SPECIAL_DATES: {
      const config = {...state.config};
      const openingHours = {...config.openingHours};
      const specialDates = [...openingHours.specialDates];

      specialDates[action.payload.index] = { ...specialDates[action.payload.index], [action.payload.key]: action.payload.value }

      openingHours.specialDates = specialDates;
      config.openingHours = openingHours;
      return { ...state, config };
    }
    case ACTIONS.SPECIAL_DATES: {
      const config = {...state.config};
      const openingHours = {...config.openingHours};
      const specialDates = [...openingHours.specialDates];

      if(action.payload !== null && action.payload !== undefined) {
        specialDates.splice(action.payload, 1);
      } else {
        specialDates.push({...DEFAULT_SPECIAL_DATE, hours: [...DEFAULT_SPECIAL_DATE.hours]});
      }

      openingHours.specialDates = specialDates;
      config.openingHours = openingHours;
      return { ...state, config };
    }
    default:
      throw new Error(`Action of type ${action.type} not valid`);
  }
};

export default Reducer;
