import React, { useMemo } from 'react';
import useUsers from 'state/users';
import styled from 'styled-components';
import Checkbox from 'components/Checkbox';
import UserItem from 'components/UserItem';
import FormSearchableSelect from 'components/FormSearchableSelect';

const CustomUserItem = styled(UserItem)`
  flex: 1;
`;

const OptionContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Item = ({ item }) => <CustomUserItem name={`${item?.label} ${item?.lastName}`} image={item?.image} />;

const CustomOption = ({ selected, data }) => {
  return (
    <OptionContainer>
      <Checkbox checked={selected} />
      <UserItem name={`${data?.label} ${data?.secondName || ''} ${data?.lastName || ''}`} image={data?.image} />
    </OptionContainer>
  );
};

const HeadersSelect = ({ title, formData, handleFormChange, groupId}) => {  
  const [users] = useUsers();

  const removeEmpoyee = (employee) => {
    const newHeads = formData.heads.get(groupId).filter(({value}) => employee.value !== value);
    handleFormChange(newHeads);
  }

  const onChange = (employee) => {
    const selectedEmployee = formData.heads.get(groupId).filter(({value}) => employee.value === value);

    if( selectedEmployee.length > 0 ){
      removeEmpoyee(employee);
      return;
    }
    
    if( formData.heads.get(groupId).length >= 3 ) return;

    handleFormChange([...formData.heads.get(groupId), employee]);
  }

  const onRemove = (employee) => {
    removeEmpoyee(employee);
  }

  const options = useMemo(()=>{
    const newList = users.list
      .filter(({ assignments }) => {
        if (!assignments || !assignments[groupId]) return false;
        return true;
      })
      .filter(({ id }) => {
        return !formData.users.get(groupId).some(({ value }) => value === id);
      });
    let format = newList.map(({ id, name, ...rest }) => ({ value: id, label: name, ...rest }));
    return format;
  },[formData.users.get(groupId)]);

  return (
    <FormSearchableSelect
      title={title}
      onChange={onChange}
      onRemove={onRemove}
      selectedValues={formData.heads.get(groupId)}
      options={options}
      CustomBody={Item}
      CustomOptionBody={CustomOption}
    />
  );
};

export default HeadersSelect;
