import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import BasicText from 'components/DynamicTable/DynamicTableComponents/cells/BasicText';
import RowActions from './cells/Actions';
import RowContainer from 'components/Table/RowContainer';
import UserGrid from 'components/Table/UserGrid';
import Group from '../../../../cells/Group';
import SortText from 'components/Table/headers/SortText';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 3,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Restaurant')}  style={{ marginLeft: '16px'}}/>,
            body: Group,
        },
        {
            flex: 2,
            header: ({globalState, ...rest}) => <SortText property={'name'} text={globalState.config.translate('Division')} {...rest}/>,
            body: ({ item }) => <BasicText text={item.name} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 6,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Employees')} />,
            body: ({item}) => <UserGrid heads={item.heads} users={item.users} divisionId={item.id} groupId={+item.groupId} />,
        },
        {
            flex: 2,
            header: () => <HeaderText text={''} />,
            body: RowActions,
        },
    ],
};

export default structure;