import React from 'react';
import Actions from 'components/DynamicTable/DynamicTableComponents/Editable/Actions';
import ActionShareIcon from 'resources/img/share-icon.svg';
import ActionDeleteIcon from 'resources/img/action-delete-icon.svg';
import { ALERT_TYPES } from '@etiquette-ui/alert-dialog';
import colors from 'resources/constants/colors';

const RowActions = ({ item, globalState, count }) => {
    const actions = [
        {
            event: () => {
                globalState.config
                    .alert({
                        type: ALERT_TYPES.CONFIRM,
                        config: {
                            title: globalState.config.translate('Delete Sale Adjustment'),
                            body: globalState.config.translate('Are you sure you want to delete this sale adjustment?'),
                            closeText: globalState.config.translate('Go Back'),
                            submitText: globalState.config.translate('Delete'),
                        },
                    })
                    .then(() => {
                        globalState.config.onDelete(item._id);
                    })
                    .catch(() => {});
            },
            src: ActionDeleteIcon,
        },
        {
            event: () => window.open(`https://dotell.test.appsinti.com/${item._id}`, '_blank'),
            src: ActionShareIcon,
        },
    ];

    return (
        <Actions
            loading={globalState.config.loading.id === item._id}
            actions={actions}
            style={{ alignItems: 'start', marginTop: '10px' }}
            spinnerBackground={count % 2 === 0 ? colors.backgroundLightGrey : 'white'}
        />
    );
};

export default RowActions;
