import React, { useEffect, useState } from 'react';
import { CardSpace } from 'components/Space';
import { InternalCard, SubTitle, InputContainer } from 'components/CardStyles';
import useI18n from 'i18n/useI18n';
import TargetSelected from 'pages/Consolidate/components/TargetSelected';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';


const GroupSelector = ({ options, onChange, value, removeTarget, selected }) => {
  const { translate } = useI18n();

  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const availableOptions = [];
    const selectedOptions = [];
    options?.forEach(option => {
      if (selected?.includes(+option.value)) {
        selectedOptions.push(option)
      } else {
        availableOptions.push(option)
      }
    })

    setAvailableOptions(availableOptions);
    setSelectedOptions(selectedOptions);
  }, [selected, options])


  return (
    <InternalCard>
      <SubTitle>{translate("Restaurant")}</SubTitle>
      <CardSpace />
      <InputContainer columns={2}>
        <Dropdown
          labelProps={{
            label: translate('Restaurant to Assign')
          }} 
          leadingIconProps={{
            icon: 'restaurant',
            leadingIconStyles: {
              line: {
                fill: 'black',
                strokeLinecap:'round',
                strokeLinejoin:'round',
                strokeWidth:'.0px',
                stroke: 'black'
              }
            }
          }} 
          selectProps={{
            options: availableOptions,
            onChange: (e) => onChange(e),
            placeholder: translate('Choose one'),
            value,
            required: true
          }}
        />
      </InputContainer>
      <CardSpace height={'16px'} />
      <TargetSelected
        list={selectedOptions}
        removeTarget={removeTarget}
      />
    </InternalCard>
  );
};

export default GroupSelector;
