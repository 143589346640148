import { mapIdsToObjects } from 'utils';
import { parseAssignments, unParseAssignments } from 'utils/employee';

const parseFormState = (formData, initialEmployee, groupId, permissions) => {
  const newData = { ...formData.personalInfo };

  const image = formData.personalInfo.image;
  newData.image = !!image?.file ? image.file : image || null;

  const employee = { ...initialEmployee };
  const unparse = unParseAssignments(employee.assignments);
  delete unparse[groupId];
  const employeeAssignments = Object.values(unparse).reduce((acc, value) => {
    acc.push(...value);
    return acc;
  }, []);

  newData.assignments = [
    ...employeeAssignments,
    ...parseAssignments(
      formData.assignments
        .filter(({ division, role }) => !!division || !!role)
        .map(({ groupId, division, role }) => ({ groupId, division: division?.value ?? null, role: role?.value ?? null }))
    ),
    { groupId: +groupId },
  ];

  const data = mapIdsToObjects(employee.permissions ?? [], permissions);
  newData.permissions = [...data.filter((g) => +g.groupId !== +groupId).map(({ id }) => +id)];
  if (!!formData.permission?.value) newData.permissions.push(formData.permission.value);

  newData.groupId = groupId;
  return newData;
};

export default parseFormState;
