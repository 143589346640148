const TYPE = `[PLACES]`;

const TYPES = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default TYPES;