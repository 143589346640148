import React, {useEffect} from 'react';
import styled from 'styled-components';
import CloseSVG from 'resources/img/close-icon-dark.svg';
import colors from 'resources/constants/colors';
import UserItem from 'components/UserItem';
import useUsers from 'state/users';
import { getFullName } from 'utils/text';


export const TargetSelectedUser = ({ list, removeTargetUser }) => {
  const [users, usersDispatcher] = useUsers();

  useEffect(() => {
    usersDispatcher.fetchStart();
  }, []);

  return (
    <AddedContainer>
      {!!list &&
        list.map((userId) => {
          const user = users.list.find(({ id }) => id === userId);
          return (
            <UserContainer>
              <CustomUserItem name={user && getFullName(user)} image={user && user.image} />
              <Close
                src={CloseSVG}
                alt={"remove-user"}
                onClick={() => removeTargetUser('users', {value: user.id})}
              />
            </UserContainer>
          )
        })
      }
    </AddedContainer>
  );
};

const TargetSelected = ({ list, removeTarget }) => {

  return (
    <AddedContainer>
      {!!list &&
        list.map((item, i) => {
          return (
            <UserContainer>
              <CustomUserItem name={item.label} />
              <Close
                src={CloseSVG}
                alt={"remove-user"}
                onClick={() => removeTarget(item.value, i)}
              />
            </UserContainer>
          );
        })}
    </AddedContainer>
  );
};


const UserContainer = styled.div`
  display: flex;
  padding: 8px;
  background-color: ${colors.lightBlue};
  min-width: 250px;
  border-radius: 8px;
  margin-right: 16px;
  align-items: center;
`;

const CustomUserItem = styled(UserItem)`
    flex: 1;
`;

const Close = styled.img`
    width: 25px;
    height: 25px;
    cursor: pointer;
`;
const AddedContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 15px;
`;

export default TargetSelected;

