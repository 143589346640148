import styled from 'styled-components';
import Card from 'components/Card';

export const InternalCard = styled(Card)`
  padding: 16px 24px;
`;

export const SubTitle = styled.div`
    font-size: 1rem;
    font-weight : 600;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-column-gap: 30px; 
  grid-template-columns: repeat(${({columns})=>(columns || '3')}, 1fr);;
`;
