import styled from 'styled-components';

export const CardSpace = styled.div`
  height: 24px;
`;

export const Hr = styled.div`
    height: 100%;
    border: 1px solid #E1E1E1;
    background-color: #E1E1E1;
`;

const Space = styled.div`
  height: ${({height})=>(height||'32px')};
`;

export default Space;


