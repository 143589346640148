import React, { useEffect } from "react";
import Card from "components/Card";
import { FormContainer } from "containers/FormLayout";
import { TitleH2 } from "components/Texts";
import { FormSpacer } from "containers/FormLayout";
import useUsers from "state/users";
import Details from "./Details";
import Types from "./Types";
import Payments from "./Payments";

import { PrimaryButton } from '@etiquette-ui/buttons';
import { Spinner } from '@etiquette-ui/misc';

const AdjustmentForm = ({
  data,
  dispatcher,
  onSubmit,
  title,
  buttonText,
  loading = false,
}) => {
  const [, usersDispatcher] = useUsers();

  useEffect(() => {
    usersDispatcher.fetchStart();
  }, []);  

  const text = loading ? <ButtonSpiner /> : buttonText;

  return (
    <Card>
      <FormContainer>
        <TitleH2 style={{ marginBottom: "20px" }}>{title}</TitleH2>
        <Form onSubmit={onSubmit}>
          <FormSpacer />
          <Details
            data={data}
            dispatcher={dispatcher} />
          <FormSpacer />
          <Types
            data={data}
            dispatcher={dispatcher} />
          <FormSpacer />
          <Payments
            movements={data.movements}
            dispatcher={dispatcher}
            comment={data.sale.comment}
          />
          <FormSpacer />
          <PrimaryButton type={"submit"} disabled={loading}>
            {text}
          </PrimaryButton>
        </Form>
      </FormContainer>
    </Card>
  );
};

const ButtonSpiner = () => (
  <Spinner 
    style={{
      '--width': '15px',
      '--margin': '0 5px',
      '--bg': 'white',
      '--color': 'white'
    }}
  />
);

const Form = ({ onSubmit, children }) => (
  <form onSubmit={onSubmit} style={{ width: "90%" }}>
    {children}
  </form>
);
export default AdjustmentForm;
