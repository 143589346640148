const TYPE = `[USERS]`;

const TYPES = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    UPDATE_START: `${TYPE} UPDATE START`,
    UPDATE_SUCCESS: `${TYPE} UPDATE SUCCESS`,
    UPDATE_ERROR: `${TYPE} UPDATE ERROR`,

    DELETE_START: `${TYPE} DELETE START`,
    DELETE_SUCCESS: `${TYPE} DELETE SUCCESS`,
    DELETE_ERROR: `${TYPE} DELETE ERROR`,

    CREATE_START: `${TYPE} CREATE START`,
    CREATE_SUCCESS: `${TYPE} CREATE SUCCESS`,
    CREATE_ERROR: `${TYPE} CREATE ERROR`,

    TOGGLE_ACTIVE: `${TYPE} TOGGLE ACTIVE`,

    RESET_ERROR: `${TYPE} RESET ERROR`,

    UNREGISTER_START: `${TYPE} UNREGISTER START`,
    UNREGISTER_SUCCESS: `${TYPE} UNREGISTER SUCCESS`,
    UNREGISTER_ERROR: `${TYPE} UNREGISTER ERROR`,

}

export default TYPES;