import React from 'react';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';

const Container = styled.div`
  width: 300px;
  padding-top: 10px;
`;

const CustomDropdown = styled(Dropdown)`
  width: 100%;
  .selector__single-value {
    max-width: 90%;
    overflow: hidden;
  }
  .dropdown-module_container__fNYht {
    padding: 15px 10px 8px 15px;
  }
  .dropdown-module_inputContainer__25Hsy {
    figure {
      width: 20px;
      height: 20px;

      > div {
        margin-left: 0px;
        text-align: left;;
      }
    }
  }
`;

const ImportData = ({options, onChange, customOption}) => {
  const { translate } = useI18n();

  const onSelect = (value) => {
    onChange(value);
  };

  return (
    <Container>
      <CustomDropdown
        labelProps={{
          label: translate('Import Data')
        }} 
        selectProps={{
          options: options,
          onChange: onSelect,
          placeholder: translate('Search'),
          isSearchable: true,
          backspaceRemovesValue: false,
          tabSelectsValue: false,
          formatOptionLabel: customOption ? customOption : undefined,
          styles: {
            singleValue: (base) => ({
              ...base,
              maxWidth: '100%',
              overflow: 'hidden',
              margin: '0px'
            }),
          }
        }}
      />
    </Container>
    
  );
};

export default ImportData;
