import React, { useEffect, useState } from 'react';
import useI18n from 'i18n/useI18n';
import { useHistory, useParams } from 'react-router-dom';
import structure from './structure';
import FloatingActionButton from 'components/FloatingActionButton';
import styled from 'styled-components';
import { useAlert } from '@etiquette-ui/alert-dialog';
import { Card } from '@etiquette-ui/misc';
import { H3 } from '@etiquette-ui/typography';
import DynamicTable from '@etiquette-ui/dynamic-table';
import { surveyAPI } from 'resources/api';

const Table = styled(DynamicTable)`
    height: 100%;
    background-color: transparent;
    background: transparent;

    @media (max-width: 768px) {
        .body > div {
            &:last-child {
                margin-bottom: 135px;
            }
        }
    }
`;

const Content = styled(Card)`
    width: 90%;
    margin-bottom: 40px;
    display: grid;
    grid-template-rows: 50px minmax(0, 1fr);
`;

const TableContainer = styled.div`
    flex: 1;
`;

const List = ({ location }) => {
    const history = useHistory();
    const { translate, formatDate } = useI18n();
    const { groupId } = useParams();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState({ id: null, state: false });
    const alert = useAlert();

    useEffect(() => {
        updateList();
    }, []);

    const updateList = () => {
        surveyAPI
            .getAll()
            .then((res) => {
                console.log(res);
                setItems(res.filter(({ meta }) => meta.groupId === +groupId));
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const onDelete = (id) => {
        setLoading({ id, state: true });
        surveyAPI
            .delete({ id })
            .then((res) => {
                setLoading({ id: null, state: false });
                updateList();
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const onUpdate = (survey) => {
        const { _id, ...rest} = survey;
        setLoading({ id: _id, state: true });
        surveyAPI
            .update(_id, rest)
            .then((res) => {
                setLoading({ id: null, state: false });
                updateList();
            })
            .catch((e) => {
                console.error(e);
            });
    };

    return (
        <>
            <Content>
                <H3 style={{ textAlign: 'center' }}>{translate('Your Surveys')}</H3>
                <TableContainer>
                    <Table
                        structure={structure}
                        config={{ history, translate, alert, groupId, formatDate, loading, onDelete, onUpdate }}
                        items={items}
                    />
                </TableContainer>
            </Content>

            <FloatingActionButton to={`${location.pathname}/create`}>{translate('Add Adjustment')}</FloatingActionButton>
        </>
    );
};

export default List;
