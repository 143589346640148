import React from 'react';
import Actions from 'components/DynamicTable/DynamicTableComponents/Editable/Actions';
import ActionEditIcon from 'resources/img/action-edit-icon.svg';
import ActionDeleteIcon from 'resources/img/action-delete-icon.svg';
import Switch from 'components/Switch';
import { ALERT_TYPES } from '@etiquette-ui/alert-dialog';
import { ACCESS_USER_TYPES } from 'resources/constants/config';
import { useAccess } from 'providers/access';

const RowActions = ({ item, globalState }) => {
  const { hasAccess } = useAccess();
  const { dispatcher, history } = globalState.config;
  const onActiveChange = (item) => {
    dispatcher.toggleActive({
      id: item.id,
      isActive: item.isActive ? 0 : 1
    });
  };

  const actions = [
    {
      event: () => {
        globalState.config
          .alert({
            type: ALERT_TYPES.CONFIRM,
            config: {
              title: globalState.config.translate('Delete Employee'),
              body: globalState.config.translate('Are you sure you want to delete this employee?'),
              closeText: globalState.config.translate('Go Back'),
              submitText: globalState.config.translate('Delete'),
            },
          })
          .then(() => {
            globalState.config.dispatcher.deleteStart({ id: +item.id });
          })
          .catch(() => {});
      },
      src: ActionDeleteIcon,
      disabled: !hasAccess(null, ACCESS_USER_TYPES.ROOT),
    },
    {
      event: () => history.push(`/consolidate/employees/update/${item.id}`),
      src: ActionEditIcon,
      disabled: !hasAccess(null, ACCESS_USER_TYPES.ROOT),
    },
    {
      child: <Switch active={item.isActive} onClick={() => onActiveChange(item)} disabled={!hasAccess(null, ACCESS_USER_TYPES.ROOT)}/>,
    },
  ];

  return <Actions actions={actions} />;
};

export default RowActions;
