import React, { useEffect, useReducer } from "react";
import IconLink from "components/IconLink";
import useI18n from "i18n/useI18n";
import usePermissions from "state/permissions";
import Form from "../components/Form";
import styled from "styled-components";
import Reducer, { initialState } from './Reducer'
import { useParams } from 'react-router-dom';
import useSalesAdjustment from "state/salesAdjustment";
import { yesterdayString } from "utils/date";
import FormScrollableContainer from "pages/Group/containers/FormScrollableContainer";
import { useRouter } from "providers/router";

const Create = ({ location }) => {

  const { translate } = useI18n();
  const [data, dispatcher] = useReducer(Reducer, initialState);
  const [, saleDispatcher] = useSalesAdjustment();
  const [permissions] = usePermissions();
  const { groupId } = useParams();
  const { route, canGoBack } = useRouter();

  useEffect(() => {
    const eventDate = yesterdayString();
    const payload = {
      sale: { groupId, eventDate },
      movements: [{
        paymentMethod: null,
        paymentMethodName: null,
        food: null,
        drink: null,
        iva: null,
      }]
    }

    dispatcher({ type: "init", payload })
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    
    saleDispatcher.createStart(data);
  };

  return (
    <FormScrollableContainer>
      <HeaderContainer>
        <IconLink to={canGoBack() ? route.from : `/${groupId}/adjustments`}>
          {translate("Back to Sales Adjustment")}
        </IconLink>
      </HeaderContainer>
      <div style={{ height: "10px" }}></div>

      <Form
        data={data}
        dispatcher={dispatcher}
        onSubmit={onSubmit}
        title={translate("New Adjustment")}
        loading={permissions.fetching.state}
        buttonText={translate("Save Adjustment")}
      />
    </FormScrollableContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Create;
