import React from 'react';
import styled from 'styled-components';
import { Body } from './Texts';

const Container = styled.div`
  display: grid;
  grid-template-columns: 38px 1fr;
  width: 100%;
  align-items: center;
`;

const Icon = styled.img`
  width: 28px;
  height: 28px;
`;

const InfoElement = ({ icon, alt = '', description }) => {
  return (
    <Container>
      <Icon alt={alt} src={icon} />
      <Body>{description}</Body>
    </Container>
  );
};

export default InfoElement;
