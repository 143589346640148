import React from 'react';
import { GridContainer } from 'containers/FormLayout';
import MultipleSelect from './Inputs/MultipleSelect';
import styled from 'styled-components';
import colors from 'resources/constants/colors';
import CloseSVG from 'resources/img/close-icon-dark.svg';
import { Body } from './Texts';

const SelectedContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 15px;
  margin-top: 15px;
`;

const ElementContainer = styled.div`
  display: flex;
  padding: 8px;
  background-color: ${colors.lightBlue};
  min-width: 250px;
  border-radius: 8px;
  margin-right: 16px;
  align-items: center;
  justify-content: space-between;
`;

const Close = styled.img`
    width: 25px;
    height: 25px;
    cursor: pointer;
`;

const FormSearchableSelect = ({ title, options, onChange, onRemove, selectedValues, CustomBody, CustomOptionBody, error }) => {
  return (
    <>
      <GridContainer>
        <MultipleSelect
          title={title}
          selectedValues={selectedValues}
          onChange={onChange}
          options={options}
          required={true}
          CustomOption={CustomOptionBody}
          error={error}
        />
      </GridContainer>
      <SelectedContainer>
        {selectedValues.map((item) => {
          const Component = CustomBody ? <CustomBody item={item} /> : <Body>{item.label}</Body>;
          return (
            <ElementContainer key={item.value}>
              {Component}
              <Close src={CloseSVG} alt={'remove-user'} onClick={() => onRemove(item)} />
            </ElementContainer>
          );
        })}
      </SelectedContainer>
    </>
  );
};

export default FormSearchableSelect;
