import React, {useState, useEffect} from 'react';
import { PassiveButton } from 'components/Buttons';
import Card from 'components/Card';
import AppCard from '../AppCard';
import { Center } from 'containers/FormLayout';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';

const AppCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;

const Aplications = ({item, update, handleOpen}) => {
    const { translate } = useI18n();
    const handleSelect = (idx) => (selected) => item.apps[idx].access = selected;
    const handleAppRemove = (idx) => update(idx);

    return (
            <Card>
              <h3>{translate('Applications')}</h3>
                <AppCardGrid>   
                  {!!item?.apps?.length > 0 && 
                    item.apps.map((access, idx) => (
                      <AppCard 
                      key={idx} 
                      id={access.id} 
                      handleSelect={handleSelect(idx)}
                      handleRemove={() => handleAppRemove(idx)} 
                      />
                    ))
                  }
                  </AppCardGrid>

                    <Center>
                      <PassiveButton 
                      type="button" 
                      style={{ width: '210px', height: '50px', marginTop: '30px' }} 
                      onClick={() => handleOpen(+item.permission.groupId)}
                      >
                        {translate('Add Applications')}
                      </PassiveButton>
                    </Center>
            </Card>
    );
};

export default Aplications;