import React from 'react';
import useI18n from 'i18n/useI18n';
import { InternalCard, SubTitle, InputContainer } from 'components/CardStyles';
import Space from 'components/Space';

import { Input } from '@etiquette-ui/inputs-dropdowns';

const Details = ({ formData, handleFormChange }) => {
  const { translate } = useI18n();

  return (
    <InternalCard>
      <SubTitle>{translate("Details")}</SubTitle>
      <Space />
      <InputContainer columns={2}>
        <Input
          labelProps={{label: translate('Role Name')}}
          iconProps={{
            icon: 'user'
          }}
          inputProps={{
            value: formData.name,
            onChange: (e) => handleFormChange("name", e.target.value),
            required: true
          }}
        />
        <Input
          labelProps={{label: translate('Description')}}
          iconProps={{
            icon: 'export',
            iconStyles: {
              line: {
                fill: 'none',
                strokeLinecap:'round',
                strokeLinejoin:'round',
                strokeWidth:'1.25px',
                stroke: '#000'
              }
            }
          }}
          inputProps={{
            value: formData.description,
            onChange: (e) => handleFormChange("description", e.target.value),
            required: true
          }}
        />
      </InputContainer>
    </InternalCard>
  );
};

export default Details;
