import FloatingActionButton from 'components/FloatingActionButton';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PassiveButton } from 'components/Buttons';
import useI18n from 'i18n/useI18n';
import HomeContainer from 'pages/Home/containers/HomeContainer';
import Card from 'components/Card';
import Table from 'components/Table';
import headerStructure from './headerStructure';
import structure from './structure';
import useGroups from 'state/groups';
import { TitleH3 } from 'components/Texts';
import { HEADER_SORT_TYPES } from '@etiquette-ui/dynamic-table-components';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';
import { useAccess } from 'providers/access';
import { ACCESS_USER_TYPES } from 'resources/constants/config';
import useApps from 'state/apps';

const HeaderTable = styled(Table)`
  height: auto;
  .body {
    height: 0;
  }
`;

const BodyTable = styled(Table)`
  height: auto;
  margin-top: -45px;
  .header {
    min-height: 0;
  }
`;

const Title = styled(TitleH3)`
  margin: 20px 0;
  margin-left: 16px;
`;

const Consolidate = styled(PassiveButton)`
  width: 200px;
  margin-bottom: 20px;
`;

const List = () => {
  const history = useHistory();
  const { translate } = useI18n();
  const [groups, dispatcher] = useGroups();
  const [, appsDispatcher] = useApps();
  const [list, setList] = useState([]);
  const [filters, setFilters] = useState({ name: null, employeeCount: null });
  const { search, setVisible } = useSearch();
  const { hasAccess } = useAccess();

  useEffect(() => {
    dispatcher.fetchStart();
    appsDispatcher.fetchStart();
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const filterList = (filter, type, items) => {
    switch (filter) {
      case 'name':
        return items.sort((a, b) => {
          if(type === HEADER_SORT_TYPES.UNORDER) return 0;

          const A = (a.name ?? '').toUpperCase();
          const B = (b.name ?? '').toUpperCase();
          if (A < B) {
            return type===HEADER_SORT_TYPES.ASC ? 1 : -1;
          }
          if (A > B) {
            return type===HEADER_SORT_TYPES.ASC ? -1 : 1;
          }
        });
      case 'employeeCount':
        return items.sort((a, b) => {
          if(type === HEADER_SORT_TYPES.UNORDER) return 0;
          const A = a.employees ?? 0;
          const B = b.employees ?? 0;
          if (A < B) {
            return type===HEADER_SORT_TYPES.ASC ? 1 : -1;
          }
          if (A > B) {
            return type===HEADER_SORT_TYPES.ASC ? -1 : 1;
          }
        });
      default:
        return [...items];
    }
  };

  const handleFilter = (key) => {
    const newFilters = {name: null, employeeCount: null, [key]: filters[key]};
    const filter = filters[key];

    let orderType =  '';
    switch(filter){
      case HEADER_SORT_TYPES.DESC:
        orderType = HEADER_SORT_TYPES.ASC;
        break;
      case HEADER_SORT_TYPES.ASC:
        orderType = HEADER_SORT_TYPES.UNORDER;
        break;
      default:
      case HEADER_SORT_TYPES.UNORDER: 
        orderType = HEADER_SORT_TYPES.DESC;
    }
    newFilters[key] = orderType;
    setFilters(newFilters)
  }

  useEffect(() => {
    let newList = [...groups.list];
    for (const key of Object.keys(filters)) {
      if (filters[key]) {
        newList = filterList(key, filters[key], newList);
      }
    }
    if(search.length!==0) newList = newList.filter(({name})=> compareStrings(name, search));
    setList(newList);
  }, [groups, filters, search]);

  return (
    <HomeContainer>
      <Consolidate onClick={() => history.push('/consolidate')}>{translate('Consolidate')}</Consolidate>
      <Card>
        <HeaderTable structure={headerStructure} config={{ translate, handleFilter, filters}} items={[]} />
        <Title>{translate('Active Groups', { count: list.filter(({ isActive }) => !!isActive).length })}</Title>
        <BodyTable
          structure={structure}
          config={{ history, dispatcher, data: groups, translate }}
          items={list.filter(({ isActive }) => !!isActive)}
        />
        <div style={{ height: '20px' }}></div>
        <Title>{translate('Inactive Groups', { count: list.filter(({ isActive }) => !isActive).length })}</Title>
        <BodyTable
          structure={structure}
          config={{ history, dispatcher, data: groups, translate }}
          items={list.filter(({ isActive }) => !isActive)}
        />
      </Card>
      <FloatingActionButton to="/create" disabled={!hasAccess(null, ACCESS_USER_TYPES.ROOT)}>{translate('Add Group')}</FloatingActionButton>
    </HomeContainer>
  );
};

export default List;
