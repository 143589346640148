import { findCountryPhoneCode } from 'utils/country';
import { dateFormat, DATE_FORMAT } from 'utils/date';

const addLeadingZero = (value) => value > 9 || `${value}`.length > 1 ? value : `0${value}`;

const formatHour = (open, timeObj) => {
  const {hour, minute, time} = timeObj;
  if(!hour || !minute || !time || !open) return '';

  if(time === 'am') {
    return `${ hour==12 ? '00' : addLeadingZero(hour)}:${addLeadingZero(minute)}`
  }
  return `${ hour==12 ? '12' : +hour + 12}:${addLeadingZero(minute)}`
}

const toSubmitObject = (formData) => {
  const newGroup = {};
  newGroup.name = formData.name;
  newGroup.contacts = [
    ...formData.contacts.map((contact) => ({
      name: contact.contactFirstName,
      lastName: contact.contactLastName,
      email: contact.contactEmail,
      phone: `+${findCountryPhoneCode(contact.contactCountryCode)}${contact.contactPhone}`,
      position: contact.contactPosition,
    })),
  ];
  newGroup.address = {
    street: formData.streetAddress,
    number: formData.streetNumber,
    zipCode: formData.zipCode,
    state: formData.stateAddress.label,
    stateId: formData.stateAddress.value,
    city: formData.cityAddress.label,
    cityId: formData.cityAddress.value,
  };
  newGroup.imageLight = !!formData.img?.file ? formData.img.file : ( !!formData.img ? formData.img : null );
  newGroup.config = {
    openingHours: {
      schedule: {},
      specialDates: [],
    }
  };
  
  const schedule = {};
  for(const key of Object.keys(formData.config.openingHours.schedule)) {
    const open = formData.config.openingHours.schedule[key].isOpen;
    schedule[key] = {isOpen: open, hours: [...formData.config.openingHours.schedule[key].hours].map(hour=>formatHour(open, hour))}
  }

  const specialDates = formData.config.openingHours.specialDates.filter(({date})=>!!date).map(({date, isOpen, hours})=>{
    return ({date: dateFormat(date, DATE_FORMAT), isOpen, hours: hours.map(hour=>formatHour(isOpen, hour))})
  });


  newGroup.config.openingHours.schedule = schedule;
  newGroup.config.openingHours.specialDates = specialDates;

  return newGroup;
};

export default toSubmitObject;
