import { IMAGES_URL } from '../resources/constants/urls';
import styled from 'styled-components';
import { Body } from './Texts';
import { TextBodyCell } from './DynamicTable/DynamicTableComponents/styles';
import StarIcon from 'resources/img/star-icon.svg';
import UserIcon from 'resources/img/perfil/user.svg'

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const HighlighContainer = styled.div`
  width: 20px;
  padding-right: 4px;
  padding-top: 4px;
`;

const Highlight = styled.img`
  width: 16px;
`;

const ImageContainer = styled.figure`
  width: 2rem;
  height: 2rem;
  margin: 0px;
  margin-left: 15px;
  border-radius: 50%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const Name = styled.div`
  margin-left: 10px;
`;

const Sub = styled(Body)`
  font-size: 0.75rem;
`;

const MaxLabel = styled(TextBodyCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
`;

const UserItem = ({ name, image, className, position, highlight = false }) => (
  <Container className={className}>
    
    {highlight && (
      <HighlighContainer>
        <Highlight src={StarIcon} alt={"Highlight"} />
      </HighlighContainer>
    )}
    
    
    <ImageContainer>
      {image ? (
        <Image src={IMAGES_URL + image} />
      ) :(
        <Image src={UserIcon} />
      )}
    </ImageContainer>

    <Name>
      <Body><MaxLabel>{name}</MaxLabel></Body>
      {position && <Sub>{position}</Sub>}
    </Name>
  </Container>
);

export default UserItem;
