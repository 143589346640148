import React, { useEffect, useReducer } from 'react';
import { useRouter } from 'providers/router';
import useI18n from 'i18n/useI18n';
import Reducer, { ACTIONS, initialState } from '../../Reducer';
import Form from '../../components/Form';
import useUsers from 'state/users';
import usePermissions from 'state/permissions';
import CreateContainer from 'pages/Consolidate/containers/CreateContainer';
import submitProcessor from '../../components/Form/submitProcessor';
import useDivisions from 'state/divisions';
import useRoles from 'state/roles';
import toFormState from './toFormState';
import useGroups from 'state/groups';

const Update = ({ location }) => {
  const { route, canGoBack } = useRouter();
  const { translate } = useI18n();
  const [formData, formDispatcher] = useReducer(Reducer, initialState);
  const [users, usersDispatcher, usersUtils] = useUsers();
  const [divisions, divisionsDispatcher, divisionUtils] = useDivisions();
  const [roles, rolesDispatcher, roleUtils] = useRoles();
  const [permissions, permissionsDispatcher, permissionUtils] = usePermissions();
  const [groups, groupsDispatcher, groupsUtils] = useGroups();

  const dispatch = (type, payload) => formDispatcher({ type, payload });

  const onSubmit = () => {
    const newData = submitProcessor(formData);

    usersDispatcher.updateStart(newData);
  };

  useEffect(() => {
    usersDispatcher.fetchStart();
    divisionsDispatcher.fetchStart();
    rolesDispatcher.fetchStart();
    permissionsDispatcher.fetchStart();
    groupsDispatcher.fetchStart();
  }, []);

  useEffect(() => {
    if (users.list.length === 0 || divisions.fetching.state || roles.fetching.state || permissions.fetching.state || groups.fetching.state) return;

    const url = location.pathname.split('/');
    const id = url.pop();

    const employee = usersUtils.findOne(id);

    const newData = toFormState(employee, groupsUtils, permissionUtils, divisionUtils, roleUtils);
    dispatch(ACTIONS.INIT, newData);
  }, [users.list, divisions.fetching, roles.fetching, permissions.fetching, groups.fetching]);

  return (
    <CreateContainer route={canGoBack() ? route.from : `/consolidate/employees`} text={translate('Back to Employees')}>
      <Form
        onSubmit={onSubmit}
        dispatch={dispatch}
        state={formData}
        isEdit={true}
        title={translate('Update Employee')}
        buttonText={translate('Update Employee')}
      />
    </CreateContainer>
  );
};

export default Update;
