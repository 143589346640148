import React, { useEffect, useState } from 'react';
import BasicText from 'components/DynamicTable/DynamicTableComponents/cells/BasicText';
import useGroups from 'state/groups';

const Group = ({ item }) => {
  const [data] = useGroups();
  const [text, setText] = useState('');

  useEffect(()=>{
    const group = data.list.find(({id})=>+id===+item.groupId);
    setText(group?.name ?? '');
  },[data.list]);

  return <BasicText text={text} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />;
};

export default Group;
