import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import useI18n from 'i18n/useI18n';
import useUsers from 'state/users';
import Table from 'components/Table';
import structure from './structure';
import { useParams } from 'react-router-dom';
import useDivisions from 'state/divisions';
import useRoles from 'state/roles';
import usePermissions from 'state/permissions';
import ManagementTabsContainer from 'pages/Group/pages/Management/containers/ManagementTabsContainer';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';
import { getFullName } from 'utils/text';
import { useAlert } from '@etiquette-ui/alert-dialog';

const List = ({ location }) => {
  const { translate } = useI18n();
  const history = useHistory();
  const [users, dispatcher] = useUsers();
  const [, divisionsDispatcher] = useDivisions();
  const [, rolesDispatcher] = useRoles();
  const [, permissionsDispatcher] = usePermissions();
  const { groupId } = useParams();
  const { search, setVisible } = useSearch();
  const alert = useAlert();

  useEffect(() => {
    dispatcher.fetchStart();
    divisionsDispatcher.fetchStart(groupId);
    rolesDispatcher.fetchStart(groupId);
    permissionsDispatcher.fetchStart(groupId);
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const items = useMemo(() => {
    let list = [...users.list];
    if (search.length !== 0)
      list = list.filter(
        (user) =>
          compareStrings(getFullName(user), search) ||
          compareStrings(user.name, search) ||
          compareStrings(user.secondName ?? '', search) ||
          compareStrings(user.lastName ?? '', search) ||
          compareStrings(user.shortName ?? '', search)
      );
    return list;
  }, [users.list, search]);

  return (
    <ManagementTabsContainer location={location} fabLink={`${location.pathname}/create`} fabText={translate('Add Employee')}>
      <Table
        structure={structure}
        config={{ history, dispatcher, users, translate, groupId, alert }}
        items={items.filter(({ assignments }) => assignments && Object.keys(assignments).some((id) => +id === +groupId))}
      />
    </ManagementTabsContainer>
  );
};

export default List;
