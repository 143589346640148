import React from 'react';
import useI18n from 'i18n/useI18n';
import Card from 'components/Card';
import { GridContainer } from 'containers/FormLayout';
import { TitleH3 } from 'components/Texts';
import { Input } from '@etiquette-ui/inputs-dropdowns';

const Subject = ({ formData, handleFormChange }) => {
  const { translate } = useI18n();

  const handleMessage = (value, i) => {
    const translations = [...formData.translations];
    const translation = { ...formData.translations[i] };
    translation.message = value;
    translations[i] = translation;
    handleFormChange('translations', translations);
  };

  return (
    formData.translations.length > 0 && (
      <Card>
        <TitleH3 style={{marginBottom: '18px'}}>{translate('Message')}</TitleH3>
        <GridContainer>
          {formData?.translations?.map((translation, idx) => (
            <Input
            labelProps={{label: translate('Message in', {lang: translation.lang.label})}}
              iconProps={{
                icon: 'align-left'
              }}
              inputProps={{ 
                value: translation.message, 
                onChange: (e) => handleMessage(e.target.value, idx), 
                required: true 
              }}
            />
          ))}
        </GridContainer>
      </Card>
    )
  );
};

export default Subject;
