import React, { useEffect, useReducer } from "react";
import IconLink from "components/IconLink";
import useI18n from "i18n/useI18n";
import usePermissions from "state/permissions";
import Form from "../components/Form";
import styled from "styled-components";
import Reducer, { initialState } from './Reducer'
import { useParams } from 'react-router-dom';
import useSalesAdjustment from "state/salesAdjustment";
import FormScrollableContainer from "pages/Group/containers/FormScrollableContainer";
import { useRouter } from "providers/router";

const Update = () => {
  const { translate } = useI18n();
  const [data, dispatcher] = useReducer(Reducer, initialState);
  const [, saleDispatcher, utils] = useSalesAdjustment();
  const [permissions] = usePermissions();
  const { id, groupId } = useParams();
  const { route, canGoBack } = useRouter();

  useEffect(() => {
    const data = utils.findOne(+id);  
    const payload = { sale: data, movements: data.movements }
    dispatcher({ type: "init", payload })
  }, []);
  
  const onSubmit = (e) => {
    e.preventDefault();
    const pack = {
      sale: {
        id: data.sale.id,
        eventDate: data.sale.eventDate,
        name: data.sale.name,
        comment: data.sale.comment,
        saleType: data.sale.saleType,
        saleTypeName: data.sale.saleTypeName,
        groupId,
      }, movements: data.movements
    }
    saleDispatcher.updateStart(pack);
  };

  return (
    <FormScrollableContainer>
      <HeaderContainer>
        <IconLink to={canGoBack() ? route.from : `/${groupId}/adjustments`}>
          {translate("Back to Sales Adjustment")}
        </IconLink>
      </HeaderContainer>
      <div style={{ height: "10px" }}></div>

      <Form
        data={data}
        dispatcher={dispatcher}
        onSubmit={onSubmit}
        title={translate("New Adjustment")}
        loading={permissions.fetching.state}
        buttonText={translate("Save Adjustment")}
      />
    </FormScrollableContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Update;
