import useI18n from 'i18n/useI18n';
import React, { useState, useEffect } from 'react';
import useRoles from 'state/roles';
import RoleForm from '../components/RoleForm';
import { useParams } from 'react-router-dom';
import { useRouter } from 'providers/router';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import FormHeader from 'pages/Group/components/FormHeader';
import { formatObjectForSelect, formatUsersForSelect, mapIdsToObjects, getUserDivisionsByRole } from 'utils';
import useUsers from 'state/users';

const Create = () => {
  const { translate } = useI18n();
  const [data, dispatcher] = useRoles();
  const [users, usersDispatcher] = useUsers();
  const { groupId } = useParams();
  const [formData, setFormData] = useState({ name:'', description:'', id: '-999', groupId: +groupId, users: [] });
  const { route, canGoBack } = useRouter();

  useEffect(() => {
    dispatcher.fetchStart();
    usersDispatcher.fetchStart();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const role = {
      name: formData.name,
      groupId,
      users: getUserDivisionsByRole(formData.users, groupId, formData.id),
      config: { description: formData.description },
    };
    dispatcher.createStart(role);
  };

  const importData = (role) => {
    const roleUsers = formatUsersForSelect(mapIdsToObjects(role.users.map(user=>user.id), users.list).filter(({ assignments }) => assignments && Object.keys(assignments).some((id) => +id === +groupId)));
    const formData = { 
      id: `${role.value}`,
      name: role.label,
      description: role.config?.description ?? '',
      groupId: +groupId,
      users: replaceId(roleUsers, +groupId, role.value, `${role.value}`),
    };
    setFormData(formData);
  }

  const replaceId = (users, groupId, roleId, newRoleId) => {
    return users.map( user => {
      user.assignments[groupId] = user.assignments[groupId].map( assignment => {
        if( assignment.roles.includes(roleId)){
          assignment.roles = assignment.roles.map( role => {
            return role === roleId ? newRoleId : role
          });
        }
        return assignment;
      });
      return user;
    });
  }

  return (
    <FormScrollableContainer>
      <FormHeader
        route={canGoBack() ? route.from : `/${groupId}/management/roles`}
        routeText={translate('Back to Roles')}
        importOptions={data.list.map((role) => formatObjectForSelect(role))}
        importData={importData}
      />
      <RoleForm
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        title={translate('New Role')}
        loading={data.fetching.state}
        buttonText={translate('Create Role')}
        groupId={groupId}
      />
    </FormScrollableContainer>
  );
};

export default Create;
