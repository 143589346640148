import PrivateRoute from 'containers/PrivateRoute';
import React from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { ACCESS_LIST } from 'resources/constants/config';
import Edit from './pages/Edit';
import LearnApps from './pages/Learn';
import View from './pages/View';

const Profile = () => {
  const { path } = useRouteMatch();
  const { groupId } = useParams();

  return (
    <Switch>
      <Route exact path={path} component={View} />
      <PrivateRoute exact path={`${path}/edit`} component={Edit} access={ACCESS_LIST.GROUP} groupId={groupId} />
      <Route exact path={`${path}/learn`} component={LearnApps} />
    </Switch>
  );
};

export default Profile;
