import React from 'react';
import { Card } from '@etiquette-ui/misc';
import { FormContainer } from 'containers/FormLayout';
import { TitleH2 } from 'components/Texts';
import { GridContainer } from 'containers/FormLayout';
import useI18n from 'i18n/useI18n';
import { FormSpacer } from 'containers/FormLayout';
import AddToRole from '../../../components/AddEmployee/AddToRole';

import { PrimaryButton } from '@etiquette-ui/buttons';
import { Spinner } from '@etiquette-ui/misc';
import { Input } from '@etiquette-ui/inputs-dropdowns';

const RoleForm = ({ formData, setFormData, onSubmit, title, buttonText, loading = false, groupId }) => {
  const { translate } = useI18n();

  const handleFormChange = (key, value) => {
    const data = { ...formData };
    data[key] = value;
    setFormData(data);
  };

  return (
    <Card>
      <FormContainer>
        <TitleH2 style={{ marginBottom: '20px' }}>{title}</TitleH2>
        <form onSubmit={onSubmit} style={{ width: '90%' }}>
          <Card>
            <h3>{translate('Details')}</h3>
            <GridContainer>
              <Input
                labelProps={{label: translate('Role Name')}}
                iconProps={{
                  icon: 'title'
                }}
                inputProps={{
                  value: formData.name,
                  onChange: (e) => handleFormChange('name', e.target.value),
                  required: true
                }}
              />
              <Input
                labelProps={{label: translate('Description')}}
                iconProps={{
                  icon: 'export',
                  iconStyles: {
                    line: {
                      fill: 'none',
                      strokeLinecap:'round',
                      strokeLinejoin:'round',
                      strokeWidth:'1.25px',
                      stroke: '#000'
                    }
                  }
                }}
                inputProps={{
                  value: formData.description,
                  onChange: (e) => handleFormChange('description', e.target.value),
                  required: true
                }}
              />
            </GridContainer>
          </Card>
          <FormSpacer />

          <Card>
            <h3>{translate('Employees')}</h3>
            <AddToRole 
              title={translate('Add Employees')}
              formData={formData}
              handleFormChange={handleFormChange}
            />
          </Card>
          <FormSpacer />
          <PrimaryButton type={'submit'} disabled={loading} >
            {loading 
              ? <Spinner 
                  style={{
                    '--width': '15px',
                    '--margin': '0 5px',
                    '--bg': 'white',
                    '--color': 'white'
                  }}
                />  
              : buttonText
            }
          </PrimaryButton>
        </form>
      </FormContainer>
    </Card>
  );
};

export default RoleForm;
