import { format, subDays, add } from 'date-fns';
import { enUS, es } from 'date-fns/locale';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const ALT_DATE_FORMAT = 'dd/MM/yy';
export const DISPLAY_DATE_FORMAT = 'iiii, d MMMM yyyy';//Tuesday, 11 January 2021

export const yesterdayString = () => {
    const yesterday = subDays(new Date(), 1);
    return format(yesterday, DATE_FORMAT);
}

export const dateFormat = (date, FORMAT = ALT_DATE_FORMAT) => {
    const dateFormatted = format(!!date ? new Date(date) : new Date(), FORMAT);
    
    return dateFormatted;
};

const capitalize = string => string.split(" ").map(item => item.charAt(0).toLocaleUpperCase() + item.slice(1)).join(' ')

export const getDisplayDate = (date, format = DISPLAY_DATE_FORMAT, selectedLang = 0) => {
    const dateFormatted = dateFormat(new Date(date), format, {
        locale: selectedLang ? enUS : es
    });

    return capitalize(dateFormatted);
}

export const fullDateFormat = (date) => dateFormat(date, 'iiii, MMMM d, yyyy');

export const addDays = (date, days) => {
    const dateObj = !!date ? new Date(date) : new Date();
    return add(dateObj, { days });
}

export const removeDays = (date, days) => {
    const dateObj = !!date ? new Date(date) : new Date();
    return subDays(dateObj, days);
}