import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import { Text } from '@etiquette-ui/dynamic-table-components';
import RowActions from './cells/Actions';
import RowContainer from 'components/Table/RowContainer';
import Tags from './cells/Tags';
import SortText from 'components/Table/headers/SortText';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 2,
            header: ({globalState, ...rest}) => <SortText property={'name'} text={globalState.config.translate('Name')} {...rest}/>,
            body: ({ item }) => <Text text={item.name} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 6,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Description')}  style={{ marginLeft: '16px'}}/>,
            body: ({ item }) => <Text text={item.description} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 2,
            header: ({globalState, ...rest}) => <SortText property={'tag'} text={globalState.config.translate('Tag')} {...rest}/>,
            body: ({ item }) => <Tags tag={item.tag}/>,
        },
        {
            flex: 2,
            header: () => <HeaderText text={''} />,
            body: RowActions,
        },
    ],
};

export default structure;