import React, { useEffect, useReducer } from 'react';
import Form from '../../components/Form';
import useUsers from 'state/users';
import useI18n from 'i18n/useI18n';
import { useParams } from 'react-router-dom';
import Reducer, { ACTIONS, initialState } from '../../Reducer';
import { useRouter } from 'providers/router';
import useDivisions from 'state/divisions';
import useRoles from 'state/roles';
import usePermissions from 'state/permissions';
import FormHeader from 'pages/Group/components/FormHeader';
import toFormState from '../utils/toFormState';
import parseFormState from '../utils/parseFormState';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import { FormSpacer } from 'containers/FormLayout';

const Edit = ({ location }) => {
  const { translate } = useI18n();
  const [users, dispatcher, utils] = useUsers();
  const [divisions, divisionsDispatcher, divisionUtils] = useDivisions();
  const [roles, rolesDispatcher, roleUtils] = useRoles();
  const [permissions, permissionsDispatcher, permissionUtils] = usePermissions();
  const { groupId } = useParams();
  const { route, canGoBack } = useRouter();
  const [formData, formDispatcher] = useReducer(Reducer, groupId, initialState);

  const dispatch = (type, payload) => formDispatcher({ type, payload });

  useEffect(() => {
    dispatcher.fetchStart();
    divisionsDispatcher.fetchStart(groupId);
    rolesDispatcher.fetchStart(groupId);
    permissionsDispatcher.fetchStart(groupId);
  }, []);

  useEffect(() => {
    if (users.list.length === 0 || divisions.fetching.state || roles.fetching.state || permissions.fetching.state) return;

    const url = location.pathname.split('/');
    const id = url.pop();

    const employee = utils.findOne(id);
    dispatch(ACTIONS.INIT, toFormState(employee, groupId, permissionUtils, divisionUtils, roleUtils));
  }, [users.list, divisions.fetching, roles.fetching, permissions.fetching]);

  const onSubmit = () => {
    const newData = parseFormState(formData, utils.findOne(formData.personalInfo.id), groupId, permissions.list);
    dispatcher.updateStart(newData);
  };

  return (
    <FormScrollableContainer>
      <FormHeader route={canGoBack() ? route.from : `/${groupId}/management/employees`} routeText={translate('Back to Employees')} />
      <Form
        onSubmit={onSubmit}
        formData={formData}
        formDispatcher={dispatch}
        isEdit={true}
        title={translate('Edit Employee')}
        loading={users.fetching.state}
        buttonText={translate('Update Employee')}
      />
      <FormSpacer />
    </FormScrollableContainer>
  );
};

export default Edit;
