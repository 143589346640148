import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import CreateGroup from './pages/CreateGroup';
import List from './pages/List';
import MainLayout from 'containers/MainLayout';
import PrivateRoute from 'containers/PrivateRoute';
import { ACCESS_USER_TYPES } from 'resources/constants/config';

const Home = () => {
  const { path } = useRouteMatch();
  return (
    <MainLayout>
      <Switch>
        <PrivateRoute path={`${path}create`} component={CreateGroup} exact={true} userType={ACCESS_USER_TYPES.ROOT}/>
        <Route path={path} component={List} exact />
      </Switch>
    </MainLayout>
  );
};

export default Home;
