import React from 'react';
import styled from 'styled-components';
import { Body } from 'components/Texts';

const Container = styled.div`
  max-width: 100%;
  max-height: auto;
  display: flex;
  align-items: center;
  padding: 0px 5px;
`;

const BasicText = ({text='', style={}}) => {
  return (
    <Container style={style}>
      <Body>
        {text}
      </Body>
    </Container>
  )
}
export default BasicText;