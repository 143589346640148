import IconLink from 'components/IconLink';
import useI18n from 'i18n/useI18n';
import React, { useState } from 'react';
import useEmailTemplate from 'state/emailTemplates';
import Form from '../components/Form';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Space from 'components/Space';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import { useRouter } from 'providers/router';

const Create = () => {
  const { translate } = useI18n();
  const [formData, setFormData] = useState({ name: '', description: '', tag: 'positive', isActive: 1, translations: [] });
  const [data, dispatcher] = useEmailTemplate();
  const { groupId } = useParams();
  const { route, canGoBack } = useRouter();

  const onSubmit = () => {
    
    const newData = {
      ...formData,
      translations: formData.translations.map(({lang, ...rest})=>({lang: lang.value, ...rest}))
    }
    
    dispatcher.createStart({ ...newData, groupId });
  };

  const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  return (
    <FormScrollableContainer>
      <HeaderContainer>
        <IconLink to={canGoBack() ? route.from : `/${groupId}/templates`}>{translate('Back to Email Template')}</IconLink>
      </HeaderContainer>
      <Space />

      <Form
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        title={translate('New Email Template')}
        loading={data.fetching.state}
        buttonText={translate('Create Email Template')}
      />
    </FormScrollableContainer>
  );
};

export default Create;
