function reducer(state, action) {
  switch (action.type) {
    case 'name':
    case 'groupId':
    case 'eventDate':
    case 'comment': {
      const sale = { ...state.sale };
      sale[action.type] = action.payload
      return { ...state, sale }
    };
    case 'saleType': {
      const sale = { ...state.sale };
      const {id, name} = action.payload;
      sale.saleType = id;
      sale.saleTypeName = name;
      return { ...state, sale }
    }
    case 'addMovement': {
      const movements = [...state.movements];
      movements.push(action.payload);
      return { ...state, movements }
    };
    case 'updateMovement': {
      const movements = [...state.movements];
      movements[+action.payload.idx] = { ...movements[+action.payload.idx], ...action.payload.movement };
      return { ...state, movements }
    }
    case 'removeMovement': {
      const movements = [...state.movements];
      movements.slice(action.payload, 1)
      return { ...state, movements }
    }
    case 'init': return { ...action.payload }

    default: throw new Error(`Action type not valid ${action.type}`)
  }
}

export const initialState = {
  sale: {
    eventDate: null,
    name: null,
    saleType: null,
    saleTypeName: null,
    groupId: null,
    comment: "",
  },
  movements: []
};

export default reducer;