import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components';
import { Body } from 'components/Texts';

import useGroups from 'state/groups';

const Attributes = ({ user }) => {
    const [data, setData] = useState([]);
    const [, , groupUtils] = useGroups();

    const nameOfGroup = (id) => {
        const group = groupUtils.findOne(id);

        return group ? group.name : "";
    }


    useEffect(() => {
        if (!user?.assignments) return;
        setData(Object.entries(user?.assignments))
    }, [user])


    return (
        <MainGrid>
            {data.map(([groupId, groupAssignments], idx) => (
                <Fragment key={`${user.id}-${groupId}-${idx}`}>
                    <Container>
                    <Body>{nameOfGroup(groupId)}</Body>
                    </Container>
                    <div>
                    {groupAssignments?.assignments?.map(({ divisionName, division, rolesNames }, idx) =>
                        <DRContainer key={`${user.id}-${groupId}-${idx}-${division}-division`}>
                            <Container>
                                <Body>
                                    {!!divisionName && divisionName} {!!divisionName && "|"}
                                </Body>
                            </Container>
                            <RoleName>
                                <Container className={!!divisionName ? "regular" : "double"}>
                                    <Body>
                                        {!!rolesNames && rolesNames.join(", ")}
                                    </Body>
                                </Container>
                            </RoleName>
                        </DRContainer>)}
                    </div>
                    <Container>
                        <Body>
                            {groupAssignments.permission ? groupAssignments.permission.name : ""}
                        </Body>
                    </Container>
                </Fragment>))}
        </MainGrid>
    )
}

const MainGrid =styled.div`
    width: 100%;
    display: grid;
    max-width: 1200px;
    grid-template-columns: 1fr 2fr 2fr;
    justify-items: start;
    font-size: 18px;
`;
const Container = styled.div`
    width: 100%;
    margin-top: 20px;

    &.regular{
        margin-left: 5px;
    }

    &.double{
        margin-left: 20px;
    }
`;

const DRContainer =styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    justify-items: start;
`;

const RoleName = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export default Attributes
