import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Create from './pages/Create';
import Edit from './pages/Edit';
import List from './pages/List';

const Employees = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route exact path={`${path}/create`} component={Create} />
      <Route exact path={`${path}/edit/:permissionId([0-9]+)`} component={Edit} />
    </Switch>
  );
};

export default Employees;
