import React from 'react';
import Card from 'components/Card';
import { FormSpacer } from 'containers/FormLayout';
import { TitleH3 } from 'components/Texts';
import useI18n from 'i18n/useI18n';
import TimeSelection from './TimeSelection';
import { ACTIONS } from 'components/GroupForm/Reducer';
import SpecialDates from './SpecialDates';

const OpeningHours = ({ formData, dispatch }) => {
  const { translate } = useI18n();

  const onChangeSchedule = (day) => (key, value) => {
    dispatch(ACTIONS.EDIT_SCHEDULE, { day, key, value })
  }

  return (
    <Card>
      <TitleH3 style={{marginBottom: '18px'}}>{translate('Opening Hours')}</TitleH3>
      {Object.keys(formData?.config?.openingHours?.schedule ?? {}).map((key) => (
        <TimeSelection key={key} day={translate(key)} state={formData?.config?.openingHours?.schedule[key]} onChange={onChangeSchedule(key)}/>
      ))}
      <FormSpacer />
      <TitleH3 style={{marginBottom: '18px'}}>{translate('Holidays & Special events')}</TitleH3>
      <SpecialDates formData={formData} dispatch={dispatch} />
    </Card>
  );
};

export default OpeningHours;
