import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { I18nProvider } from 'i18n/provider';
import Home from 'pages/Home';
import Consolidate from 'pages/Consolidate';
import GroupHome from 'pages/Group';
import Login from 'pages/Login';

import AlertServiceProvider from '@etiquette-ui/alert-dialog';
import RouterProvider from 'providers/router';
import SearchProvider from 'providers/search';
import PrivateRoute from 'containers/PrivateRoute';
import ErrorManagement from 'components/ErrorManagement';
import useApps from 'state/apps';
import useDivisions from 'state/divisions';
import useEmailTemplates from 'state/emailTemplates';
import useGroups from 'state/groups';
import usePermissions from 'state/permissions';
import usePlaces from 'state/places';
import useRoles from 'state/roles';
import useSalesAdjustment from 'state/salesAdjustment';
import useUsers from 'state/users';
import useNomenclators from 'state/nomenclators';
import AccessProvider from 'providers/access';
import { ACCESS_USER_TYPES } from 'resources/constants/config';

const App = () => {
  const [apps, appsDispatcher] = useApps();
  const [divisions, divisionsDispatcher] = useDivisions();
  const [templates, templatesDispatcher] = useEmailTemplates();
  const [groups, groupsDispatcher] = useGroups();
  const [permissions, permissionsDispatcher] = usePermissions();
  const [places, placesDispatcher] = usePlaces();
  const [roles, rolesDispatcher] = useRoles();
  const [adjustments, adjustmentsDispatcher] = useSalesAdjustment();
  const [users, usersDispatcher] = useUsers();
  const [nomenclators, nomenclatorsDispatcher] = useNomenclators();

  return (
    <RouterProvider>
      <AccessProvider>
        <SearchProvider>
          <I18nProvider>
            <AlertServiceProvider>
              <ErrorManagement
                states={{
                  apps: { state: apps.error, resetError: appsDispatcher.resetError },
                  divisions: { state: divisions.error, resetError: divisionsDispatcher.resetError },
                  emailTemplates: { state: templates.error, resetError: templatesDispatcher.resetError },
                  groups: { state: groups.error, resetError: groupsDispatcher.resetError },
                  permissions: { state: permissions.error, resetError: permissionsDispatcher.resetError },
                  places: { state: places.error, resetError: placesDispatcher.resetError },
                  roles: { state: roles.error, resetError: rolesDispatcher.resetError },
                  salesAdjustments: { state: adjustments.error, resetError: adjustmentsDispatcher.resetError },
                  users: { state: users.error, resetError: usersDispatcher.resetError },
                  paymentMethods: {
                    state: nomenclators.errors.paymentMethods,
                    resetError: nomenclatorsDispatcher.resetErrorPaymentMethods,
                  },
                  saleTypes: { state: nomenclators.errors.saleTypes, resetError: nomenclatorsDispatcher.resetErrorSaleTypes },
                  appsNomenclator: { state: nomenclators.errors.apps, resetError: nomenclatorsDispatcher.resetErrorApps },
                  langs: { state: nomenclators.errors.langs, resetError: nomenclatorsDispatcher.resetErrorLangs },
                }}
              />
              <Switch>
                <Route path="/login" component={Login} exact />
                <PrivateRoute path="/consolidate" component={Consolidate} userType={ACCESS_USER_TYPES.GLOBAL} />
                <PrivateRoute path="/:groupId([0-9]+)" component={GroupHome} />
                <PrivateRoute path="/" component={Home} />
              </Switch>
            </AlertServiceProvider>
          </I18nProvider>
        </SearchProvider>
      </AccessProvider>
    </RouterProvider>
  );
};

export default App;
