import useI18n from 'i18n/useI18n';
import React from 'react';
import ContactCard from './ContactCard';
import { FormSpacer, Center } from 'containers/FormLayout';
import { ACTIONS } from 'components/GroupForm/Reducer';
import { SecondaryButton } from '@etiquette-ui/buttons';

const Contacts = ({ formData, dispatch, handleContacts, errors }) => {
  const { translate } = useI18n();

  return (
    <>
      {formData?.contacts.map((c, i) => (
        <>
          <ContactCard
            key={i}
            title={i === 0 ? translate('Main Contact') : translate('Secondary Contact')}
            formData={c}
            onChangeForm={handleContacts(i)}
            errors={errors}
            errorMailKey={`contactEmail_${i}`}
            errorPhoneKey={`contactPhone_${i}`}
            onRemove={i === 0 ? null : () => dispatch(ACTIONS.CONTACT, i)}
          />
          <FormSpacer />
        </>
      ))}
      <Center>
        <div style={{ width: '210px', height: '50px' }}>
          <SecondaryButton type="button" onClick={() =>dispatch(ACTIONS.CONTACT)}>
            {translate('Add Secondary Contact')}
          </SecondaryButton>
        </div>        
      </Center>
    </>
  );
};

export default Contacts;
