import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { 
  Container, 
  IconContainer, 
  Icon, 
  InputContainer, 
  Label, 
  InvalidAlert, 
  Dropdown,  
  Card,
  Value,
  Input,
  InputsContainer,
  SeparatorContainer,
  Separator,
  TimeSelectorContainer,
  TimeContainer,
} from './styles.js';

const CustomDropdown = styled(Dropdown)`
  .dropdown-menu-container {
    width: auto;
    margin: 0;
    left: 0;
    margin-top: 15px;
  }
`;

const addLeadingZero = (value) => (value === '' ? value : value > 9 || `${value}`.length > 1 ? value : `0${value}`);

const CustomTimeInput = ({
  className,
  containerProps = {},
  iconProps = {},
  value,
  onChange,
  inputProps = {},
  labelProps = {},
  errorProps = {},
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const minuteRef = useRef();
  const toggle = () => setOpen(!open);

  const defaultInputProps = { type: 'number' };
  const mergedInputProps = { ...defaultInputProps, ...inputProps };

  const { icon, ...restIconProps } = iconProps;
  const { label, ...restLabelProps } = labelProps;
  const { error, ...restErrorProps } = errorProps;

  const validValue = !!value.hour && !!value.minute && !!value.time;

  const onChangeHour = (newValue) => {
    if (newValue.length > 1 && newValue < 1) {
      return { ...value };
    }
    if (newValue !== '' && (newValue.length > 2 || newValue > 12)) {
      minuteRef.current.focus();
      return { ...value, minute: `${newValue}`.split('').pop() };
    }
    return { ...value, hour: newValue };
  };

  const onChangeMinute = (newValue) => {
    if (newValue !== '' && (newValue > 59 || newValue.length > 2)) {
      return { ...value };
    }
    return { ...value, minute: newValue };
  };

  const onChangeTime = (newValue) => {
    onChange({ ...value, time: newValue, minute: addLeadingZero(value.minute) });
    toggle();
  };

  const onToggle = () => {
    if (open) onChange({ ...value, minute: addLeadingZero(value.minute) });
    toggle();
  };

  return (
    <Container className={className} {...containerProps}>
      {icon && (
        <IconContainer className={disabled ? 'disabled' : ''}>
          <Icon src={icon} {...restIconProps} />
        </IconContainer>
      )}
      <InputContainer className={`${validValue ? 'up' : ''}${disabled ? ' disabled' : ''}`}>
        <CustomDropdown
          isOpen={disabled ? false : open}
          toggle={disabled ? () => {} : onToggle}
          target={
            validValue && <Value className={disabled ? 'disabled' : ''}>{`${value.hour}:${value.minute} ${value.time}`}</Value>
          }
        >
          <Card>
            <InputsContainer>
              <Input {...mergedInputProps} value={value.hour} onChange={(e) => onChange(onChangeHour(e.target.value))} />
              <SeparatorContainer>
                <Separator>:</Separator>
              </SeparatorContainer>
              <Input
                {...mergedInputProps}
                ref={minuteRef}
                value={value.minute}
                onChange={(e) => onChange(onChangeMinute(e.target.value))}
              />
              <TimeSelectorContainer>
                <TimeContainer isActive={value.time === 'am'} onClick={() => onChangeTime('am')}>
                  <p>a.m.</p>
                </TimeContainer>
                <TimeContainer isActive={value.time === 'pm'} onClick={() => onChangeTime('pm')} isBottom={true}>
                  <p>p.m.</p>
                </TimeContainer>
              </TimeSelectorContainer>
            </InputsContainer>
          </Card>
        </CustomDropdown>
        <Label {...restLabelProps}>{label}</Label>
        {!!error && <InvalidAlert {...restErrorProps}>{error}</InvalidAlert>}
      </InputContainer>
    </Container>
  );
};

export default CustomTimeInput;
