import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import BasicText from 'components/DynamicTable/DynamicTableComponents/cells/BasicText';
import AppList from 'components/Table/AppList';
import RowContainer from 'components/Table/RowContainer';
import RowActions from './cells/Actions';
import SortText from 'components/Table/headers/SortText';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 2,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Restaurant')} style={{ marginLeft: '16px' }} />,
            body: ({ item, globalState }) => <BasicText text={globalState.config.getGroupName(item.groupId)} style={{ marginLeft: '16px' }} />
        },
        {
            flex: 2,
            header: ({globalState, ...rest}) => <SortText property={'name'} text={globalState.config.translate('Permission')} {...rest}/>,
            body: ({ item }) => <BasicText text={item.name} style={{ marginLeft: '16px' }} />,
        },
        {
            flex: 2,
            header: ({globalState, ...rest}) => <SortText property={'description'} text={globalState.config.translate('Description')} {...rest}/>,
            body: ({ item }) => <BasicText text={item.description} />,
        },
        {
            flex: 4,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Apps')} />,
            body: ({ item }) => <AppList idList={item.apps ?? []} />,
        },
        {
            flex: 2,
            header: () => <HeaderText text={''} />,
            body: RowActions,
        },
    ],
};

export default structure;
