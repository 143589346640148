import DEFAULT_PERSONAL_INFO from "components/EmployeeForm/DefaultPersonalInfo";

export const DEFAULT_ASSIGNMENT = (groupId) => ({ groupId, division: null, role: null });

export const DEFAULT_GROUP = (group) => ({
  element: {...group},
  permission: null,
  assignments: [{ ...DEFAULT_ASSIGNMENT(group.value) }],
});

export const initialState = {
  groups: [],
  personalInfo: {
    ...DEFAULT_PERSONAL_INFO,
  },
};

export const ACTIONS = {
  INIT: 'init',
  UPDATE_GROUP: 'updateGroup',
  PERSONAL_INFO: 'personalInfo',
  PERMISSION: 'permission',
  ADD_ASSIGNMENT: 'addAssignment',
  REMOVE_ASSIGNMENT: 'removeAssignment',
  ADD_DIVISION: 'division',
  ADD_ROLE: 'role',
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.INIT:
      return action.payload;

    case ACTIONS.UPDATE_GROUP: {
      const groups = [...state.groups];

      if (groups.map(({ element }) => element.value).includes(action.payload.value)) {
        const index = groups.map(({ element }) => element.value).indexOf(action.payload.value);
        groups.splice(index, 1);
      } else {
        groups.push({...DEFAULT_GROUP(action.payload)});
      }
      return { ...state, groups };
    }

    case ACTIONS.PERSONAL_INFO: {
      const newState = { ...state };
      newState.personalInfo = { ...state.personalInfo, ...action.payload };

      return newState;
    }
    case ACTIONS.PERMISSION: {
      const groups = [...state.groups];
      groups[action.payload.index] = {...groups[action.payload.index], permission: action.payload.permission };

      return { ...state, groups };
    }
    case ACTIONS.ADD_ASSIGNMENT: {
      const groups = [...state.groups];
      const group = { ...groups[action.payload.index] };

      const assignments = [...group.assignments];
      assignments.push({ ...DEFAULT_ASSIGNMENT(action.payload.groupId) });

      group.assignments = assignments;
      groups[action.payload.index] = group;

      return { ...state, groups };
    }
    case ACTIONS.REMOVE_ASSIGNMENT: {
      const groups = [...state.groups];
      const group = { ...groups[action.payload.index] };

      const assignments = [...group.assignments];
      assignments.splice(action.payload.id, 1);

      group.assignments = assignments;
      groups[action.payload.index] = group;

      return { ...state, groups };
    }
    case ACTIONS.ADD_DIVISION:
    case ACTIONS.ADD_ROLE: {
      const groups = [...state.groups];
      const group = { ...groups[action.payload.index] };

      const assignments = [...group.assignments];      
      const newAssignment = { ...assignments[action.payload.element.index] };
      newAssignment[action.type] = action.payload.element.value;
      assignments[action.payload.element.index] = newAssignment;
      
      group.assignments = assignments;
      groups[action.payload.index] = group;
      return { ...state, groups };
    }
    default:
      throw new Error(`Action of type ${action.type} not valid`);
  }
};

export default Reducer;
