import React, { useReducer } from 'react';
import useI18n from 'i18n/useI18n';
import Form from './components/Form';
import useDivisions from 'state/divisions';
import { useRouter } from 'providers/router';
import { getUsersRoleByDivision } from 'utils';
import Reducer, { INITIAL_STATE } from './Reducer'
import CreateContainer from 'pages/Consolidate/containers/CreateContainer';

const Create = () => {
  const { translate } = useI18n();
  const { route, canGoBack } = useRouter();
  const [formData, dispatcher] = useReducer(Reducer, INITIAL_STATE);
  const [, divisionsDispatcher] = useDivisions();
  
  const onSubmit = () => {
    const form = formData.groups.map((group)=>({
      name: formData.name,
      config: { description: formData.description },
      groupId: group.value,
      users: getUsersRoleByDivision(formData.users.get(group.value), group.value, +formData.id),
      heads: formData.heads.get(group.value).map(({ value }) => value),
    }));
    divisionsDispatcher.createStartBulk(form);
  };
  
  return (
    <CreateContainer route={canGoBack() ? route.from : '/consolidate/divisions'} text={translate('Back to Divisions')}>
      <Form onSubmit={onSubmit} formData={formData} setFormData={dispatcher} />
    </CreateContainer>
  );
};

export default Create;
