import styled from 'styled-components';

export const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  a {
      text-decoration-line: none;
  }
  .main__container {
    width: 80%;
  }
  .main__container--arrowSection {
    color: white;
    display: flex;
    margin: 0 0 34px 0;
    img {
      margin-right: 8px;
      cursor: pointer;
    }
    h4 {
      cursor: pointer;
    }
  }
  .main__container--appSection {
    width: 100%;
  }
`;

export const Space = styled.div`
  height: 32px;
`;
