import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import User from './cells/User';
import Actions from './cells/Actions';
import AttributesHeader from './cells/AttributesHeader';
import Attributes from './cells/Attributes'
import RowContainer from 'components/Table/RowContainer';
import SortText from 'components/Table/headers/SortText';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 1,
            header: ({...rest}) => <SortText property={'name'} text={'Name'} style={{ marginLeft: '16px' }} {...rest}/>,
            body: ({ item }) => <User user={item} />,
        },
        {
            flex: 4,
            header: () => <AttributesHeader />,
            body: ({ item }) => <Attributes user={item} />,
        },
        {
            flex: 1,
            header: () => <HeaderText text={''} />,
            body: Actions,
        },
    ],
};

export default structure;