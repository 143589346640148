import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import SortHeader from './cells/SortHeader';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 3,
            header: SortHeader('name', 'Group Name'),
            body: ()=><></>,
        },
        {
            flex: 2,
            header: SortHeader('employeeCount', '# Employees', true),
            body: ()=><></>,
        },
        {
            flex: 4,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Apps')} />,
            body: ()=><></>,
        },
        {
            flex: 2,
            header: () => <HeaderText text={''} />,
            body: ()=><></>,
        },
    ],
};

export default structure;