import React, { useState, useEffect } from 'react';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import Card from 'components/Card';
import { FormContainer } from 'containers/FormLayout';
import useI18n from 'i18n/useI18n';
import useApps from 'state/apps';
import AppSelection from 'components/alert/AppSelection';
import { FormSpacer } from 'containers/FormLayout';
import { InvalidText, TitleH1, TitleH2 } from 'components/Texts';
import Details from './Details';
import Aplications from './Applications';
import useGroups from "state/groups";
import GroupSelector from 'pages/Consolidate/components/GroupSelector';
import { ACCESS_LIST } from 'resources/constants/config';
import { useAccess } from 'providers/access';

import { PrimaryButton } from '@etiquette-ui/buttons';
import { Spinner } from '@etiquette-ui/misc';

const PermissionForm = ({ data, dispatcher, onSubmit, title, buttonText, loading = false }) => {
  const { translate } = useI18n();
  const alert = useAlert();
  const [apps, appsDispatcher] = useApps();
  const [errors, setErrors] = useState({});
  const [groups, groupDispatcher] = useGroups();
  const [selected, setSelected] = useState([]);
  const { hasAccessInGroup } = useAccess();

  useEffect(() => {
    groupDispatcher.fetchStart();
    appsDispatcher.fetchAvailable()
  }, [])

  const handleOpen = (idx) => (groupId) => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: AppSelection,
        props: {
          groupId,
          selectedApps: apps.list.filter(item => item.groupId === groupId),
        },
      },
    })
      .then((selected) => {
        const currItem = data.permissions[idx];
        const newApps = selected.map(id => ({ id, access: [] }));

        dispatcher({
          type: "UPDATE",
          payload: {
            idx,
            data: { ...currItem, apps: [...currItem.apps, ...newApps] }
          }
        })
      })
  };

  const listGroups = () => {
    return !!groups ?
      groups.list.filter(({id})=>hasAccessInGroup(id, ACCESS_LIST.PERMISSION)).map(item => ({ value: item.id, label: item.name })) :
      []
  }

  const handleDetails = (field, value) => {
    dispatcher({
      type: "DETAILS",
      payload: {
        field, value
      }
    })
  };

  const restaurantsSelected = (value) => {
    const newSelected = [...selected];
    if (newSelected?.filter(item => +item.value === +value.value).length > 0) {
      setSelected(newSelected);
    } else {
      setSelected([...newSelected, +value.value]);
    }

    dispatcher({
      type: "ADD",
      payload: {
        permission: { groupId: value?.value },
        apps: []
      }
    })
  };

  const removeTarget = (groupId, index) => {
    const newSelected = [...selected];
    newSelected.splice(index, 1);
    setSelected(newSelected);

    dispatcher({
      type: "DELETE",
      payload: groupId
    })
  }

  const updateElement = (idx) =>  (appId) => {

    dispatcher({
      type: "APPS",
      payload: {
        appId,
        idx
      }
    })
  }
  return (
    <Card>
      <FormContainer>
        <TitleH2 style={{ marginBottom: '20px' }}>{title}</TitleH2>
        <form onSubmit={onSubmit} style={{ width: '90%' }}>
          <GroupSelector
            onChange={restaurantsSelected}
            errors={errors}
            removeTarget={removeTarget}
            label={"permission"}
            options={listGroups()}
            selected={selected}
          />
          <FormSpacer />
          <TitleH1>{data.value}</TitleH1>
          <Details
            details={data}
            setDetails={handleDetails}
          />
          <FormSpacer />
          {data.permissions.map((item, idx) => <>
              <Aplications
                item={item}
                update={updateElement(idx)}
                handleOpen={handleOpen(idx)}
              />
              <FormSpacer />
          </>)}
          {Object.keys(errors).length > 0 && (
            <>
              <InvalidText style={{ textAlign: 'center' }}>{translate('Errors found on Form')}</InvalidText>
              <FormSpacer />
            </>
          )}
          <FormSpacer />
          <PrimaryButton type={'submit'} disabled={loading} >
            {loading 
              ? <Spinner 
                  style={{
                    '--width': '15px',
                    '--margin': '0 5px',
                    '--bg': 'white',
                    '--color': 'white'
                  }}
                />
              : buttonText
            }
          </PrimaryButton>
        </form>
      </FormContainer>
    </Card>
  );
};

export default PermissionForm;
