import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getJson } from 'resources/api';
import { processError } from 'state/utils';
import Types from './types';

function* fetchStartAsync() {
  try {
    const states = yield getJson('/mx-states-2.json');
    const cities = yield getJson('/mx-cities-2.json');
    yield put({
      type: Types.FETCH_SUCCESS,
      payload: {
        states: states.sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }),
        cities,
      },
    });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

export default function* sagas() {
  yield all([call(fetchStart)]);
}
