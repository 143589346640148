import { DEFAULT_NO_ERROR } from 'state/constants';
import Types from './types';

const INITIAL_STATE = {
  saleTypes: [],
  paymentMethods: [],
  apps: [],
  langs: [],
  errors: {
    paymentMethods: { ...DEFAULT_NO_ERROR },
    saleTypes: { ...DEFAULT_NO_ERROR },
    apps: { ...DEFAULT_NO_ERROR },
    langs: { ...DEFAULT_NO_ERROR },
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case Types.FETCH_SUCCESS_PAYMENT_METHOD: return fetchSuccess("paymentMethods", state, action);
    case Types.FETCH_SUCCESS_SALE_TYPE: return fetchSuccess("saleTypes", state, action);
    case Types.FETCH_SUCCESS_APP: return fetchSuccess("apps", state, action);
    case Types.FETCH_SUCCESS_LANG: return fetchSuccess("langs", state, action);


    case Types.FETCH_ERROR_PAYMENT_METHOD: return fetchError('paymentMethods', state, action);
    case Types.FETCH_ERROR_SALE_TYPE: return fetchError('saleTypes', state, action);
    case Types.FETCH_ERROR_APP: return fetchError('apps', state, action);
    case Types.FETCH_ERROR_LANG: return fetchError('langs', state, action);

    case Types.RESET_ERROR_PAYMENT_METHOD: return resetError('paymentMethods', state );
    case Types.RESET_ERROR_SALE_TYPE: return resetError('saleTypes', state );
    case Types.RESET_ERROR_APP: return resetError('apps', state );
    case Types.RESET_ERROR_LANG: return resetError('langs', state );


    default: return state;
  }
};


const fetchSuccess = (type, state, action) => {
  const newState = { ...state }
  const list = [...action.payload.list]
  newState[type] = list;

  return newState;
}

const fetchError = (type, state, action) => {
  const newState = { ...state }
  newState.errors[type] = { timestamp: Date.now(), message: action.payload };

  return newState;
}

const resetError = (type, state ) => {
  const newState = { ...state }
  newState.errors[type] = { ...DEFAULT_NO_ERROR };

  return newState;
}

export default reducer;