import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import List from './pages/List';
import Create from './pages/Create';

const Permissions = () => {
  const { path } = useRouteMatch();
 
  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route exact path={`${path}/create`} component={Create} />
    </Switch>
  );
};

export default Permissions;
