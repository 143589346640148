import Types from "./types";

export default class ProductsDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchStart = () => this.dispatch({ type: Types.FETCH_START, payload: {} });


    deleteStart = (payload) => this.dispatch({ type: Types.DELETE_START, payload });

    createStart = (payload) => {
        this._cleanPayload(payload);
        return this.dispatch({ type: Types.CREATE_START, payload });
    }

    updateStart = (user, goBack = true) => {
        this._cleanPayload(user);
        return this.dispatch({ type: Types.UPDATE_START, payload:{user, goBack} });
    }

    toggleActive = (payload) => {
        this.dispatch({ type: Types.TOGGLE_ACTIVE, payload })
        this.dispatch({ type: Types.UPDATE_START, payload });
    }

    _cleanPayload(payload) {
        for (const key in payload) {
            if (typeof payload[key] === "string") {
                payload[key] = payload[key].trim();
            }
            if (!payload[key] && key!=='secondName') delete payload[key];
        }
    }

    resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });

    unregisterStart = (payload) => this.dispatch({ type: Types.UNREGISTER_START, payload });
}