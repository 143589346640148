import React, {useMemo} from "react";
import useI18n from "i18n/useI18n";
import styled from "styled-components";
import NavOption from "./NavOption";
import { useHistory, useParams } from "react-router";
import { isCurrent } from "utils/routes";
import { useAccess } from "providers/access";
import GroupSwitch from "./GroupSwitch";

const Container = styled.div`
  width: 100%;
`;

const NavBar = styled.nav`
  width: 100%;
  margin-top: 70px;
`;

const SideNav = ({routes, current}) => {
  const { translate } = useI18n();
  const history = useHistory();
  const { hasAccessInGroup } = useAccess();
  const { groupId } = useParams();

  const currentGroupId = useMemo(()=> {
    return !!groupId ? groupId : undefined;
  },[groupId]);

  return (
    <Container>
      <GroupSwitch groupId={currentGroupId}/>
      <NavBar>
        {routes.map(({ path, title, icon, access }, i) => (
          <NavOption
            key={i}
            icon={icon}
            title={translate(title)}
            isActive={isCurrent(path, current, 2)}
            onClick={()=>history.push(path)}
            disabled={access ? !hasAccessInGroup(groupId, access) : false}
          />
        ))}
      </NavBar>      
    </Container>
  );
};

export default SideNav;
