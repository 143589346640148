import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import BasicText from 'components/DynamicTable/DynamicTableComponents/cells/BasicText';
import RowActions from './Actions';
import RowContainer from 'components/Table/RowContainer';
import RoleUserGrid from 'components/Table/RoleUserGrid';
import Group from '../../../../cells/Group';
import SortText from 'components/Table/headers/SortText';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 3,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Restaurant')}  style={{ marginLeft: '16px'}}/>,
            body: Group,
        },
        {
            flex: 2,
            header: ({globalState, ...rest}) => <SortText property={'name'} text={globalState.config.translate('Role')} {...rest}/>,
            body: ({ item }) => <BasicText text={item.name} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 6,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Employees')} />,
            body: ({item}) => <RoleUserGrid users={item.users} groupId={+item.groupId} />,
        },
        {
            flex: 2,
            header: () => <HeaderText text={''} />,
            body: RowActions,
        },
    ],
};

export default structure;