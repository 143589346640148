import React, { useEffect, useState } from 'react';
import Card from "components/Card";
import { GridContainer } from "containers/FormLayout";
import useI18n from "i18n/useI18n";
import { dateFormat, DATE_FORMAT, removeDays } from 'utils/date';
import { TitleH3 } from 'components/Texts';
import { FormSpacer } from 'containers/FormLayout';
import { Input, DateInput } from '@etiquette-ui/inputs-dropdowns';

const Details = ({ dispatcher, data }) => {
  const { translate, selectedLang } = useI18n();
  const [day, setDay] = useState(new Date());

  useEffect(() => {
    if (data.sale.eventDate === null || data.sale.eventDate.length === 0) return;
    setDay(new Date(data.sale.eventDate + 'T00:00:00'))
  },[data])

  const handleDay = (day) => {
    dispatcher({ type: 'eventDate', payload: dateFormat(day, DATE_FORMAT) })
  }

  return (
    <Card>
      <TitleH3>{translate("Details")}</TitleH3>
      <FormSpacer />
      <GridContainer>
        <Input
          labelProps={{label: translate('Sales adjustment name')}}
          iconProps={{
            icon: 'title'
          }}
          inputProps={{
            value: data.sale.name, 
            onChange: (e) => dispatcher({ type: "name", payload: e.target.value }), 
            required: true
          }}
        />
        <DateInput
          calendarProps={{
            maxDate: removeDays(null, 1),
            onChange: handleDay
          }}        
          handleChange={handleDay}
          inputProps={{
            disabled: false,
            required: true,
          }}
          labelProps={{
            label: translate("Event Date")
          }}
          language={!!selectedLang ? "en" : "es"}
          value={day}
          setValue={setDay}
        />
      </GridContainer>
    </Card>
  )
}

export default Details
