import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApps from 'state/apps';
import usePermissions from 'state/permissions';
import PermissionForm from '../components/PermissionForm';
import { createPermissionObject } from '../permission';
import useI18n from 'i18n/useI18n';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import FormHeader from 'pages/Group/components/FormHeader';
import { useRouter } from 'providers/router';
import { formatObjectForSelect } from 'utils';

const Create = () => {
  const { translate } = useI18n();
  const [formData, setFormData] = useState({ selectedApps: [], selectedPermissions: {} });
  const [data, dispatcher] = usePermissions();
  const [apps] = useApps();
  const { groupId } = useParams();
  const { route, canGoBack } = useRouter();

  const onSubmit = () => {
    const permission = createPermissionObject(formData, groupId, apps.list);
    dispatcher.createStart(permission);
  };

  const importData = (permission) => {
    dispatcher.resetDetail();
    dispatcher.fetchOneStart(permission.value);
  }

  useEffect(() => {
    if(!data.detail || !data.detail.permission || !data.detail.access || !apps.list) return;

    const formData = {};
    formData.name = data.detail.permission?.name ?? '';
    formData.description = data.detail.permission?.description ?? '';
    const selectedApps = [];
    const selectedPermissions = {};

    for(const acs of data.detail.access) {
      const appIdentifier = apps.list.find(({id})=>+id===+acs.appId)?.identifier;
      if(!apps.list.filter((app)=>app.groupId===+groupId).some(({identifier})=>identifier===appIdentifier)) continue;

      if(!selectedApps.includes(acs.appId)) selectedApps.push(acs.appId);

      if(selectedPermissions[appIdentifier]) {
        selectedPermissions[appIdentifier].push(acs.accessIdentifier);
      } else {
        if(appIdentifier) selectedPermissions[appIdentifier] = [acs.accessIdentifier];
      }
    }

    formData.selectedApps = selectedApps;
    formData.selectedPermissions = selectedPermissions;

    setFormData(formData);

  }, [data.detail, apps]);

  return (
    <FormScrollableContainer>
      <FormHeader
        route={canGoBack() ? route.from : `/${groupId}/management/permissions`}
        routeText={translate('Back to Permissions')}
        importOptions={data.list.map((permission)=>formatObjectForSelect(permission))}
        importData={importData}
      />
      <PermissionForm
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        title={translate('New Permission')}
        loading={data.fetching.state}
        buttonText={translate('Create Permission')}
      />
    </FormScrollableContainer>
  );
};

export default Create;
