import React, { useEffect, useState } from 'react';
import { HeaderSort, HEADER_SORT_TYPES } from '@etiquette-ui/dynamic-table-components';

const SortDate = ({ applyFilter, resetFilters, globalState, filters }) => {
  const [type, setType] = useState(HEADER_SORT_TYPES.UNORDER);
  const [change, setChange] = useState(false);

  useEffect(()=>{
    if(Array.from(filters.keys()).length===0 && change) {
      applyFilter('date', (items) => {
        return items.sort((first, second) => {
          if(type === HEADER_SORT_TYPES.UNORDER) return 0;

          const A = first.eventDate.split('-').join('');
          const B = second.eventDate.split('-').join('');
          if (A < B) {
            return type===HEADER_SORT_TYPES.ASC ? 1 : -1;
          }
          if (A > B) {
            return type===HEADER_SORT_TYPES.ASC ? -1 : 1;
          }
        });
      });
      setChange(false);
    }
  }, [filters, change, type])

  const onClick = () => {
    resetFilters();
    setChange(true);

    switch(type){
      case HEADER_SORT_TYPES.DESC:
        setType(HEADER_SORT_TYPES.ASC);
        break;
      case HEADER_SORT_TYPES.ASC:
        setType(HEADER_SORT_TYPES.UNORDER);
        break;
      case HEADER_SORT_TYPES.UNORDER: 
        setType(HEADER_SORT_TYPES.DESC);         
    }
  };

  return <HeaderSort 
    iconProps={{icon: type === HEADER_SORT_TYPES.UNORDER ? 'drag' : (type === HEADER_SORT_TYPES.ASC ? 'arrow-up' : 'arrow-down')}}
    text={globalState.config.translate('Event Date')} 
    style={{ marginLeft: '16px' }} 
    onClick={onClick} 
  />;
};

export default SortDate;
