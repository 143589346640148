import CustomTimeInput from 'components/Inputs/CustomTimeInput';
import { Body } from 'components/Texts';
import useI18n from 'i18n/useI18n';
import React from 'react';
import colors from 'resources/constants/colors';
import styled from 'styled-components';
import ClockSVG from 'resources/img/clock-icon.svg';

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

const Tab = styled.div`
    width: 100%;
    background-color: ${({active})=> active ? `${colors.lightBlue}` : `${colors.backgroundLightGrey}`};
    color: ${colors.textMain};
    ${({active})=> active ? '' : 'opacity: 0.5;'}
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    flex: 2;
    margin-right: 10px;
`;

const TimeInput = styled(CustomTimeInput)`
  flex: 3;
  margin-right: 10px;
`;

const TimeSelection = ({className, day, state, onChange}) => {
  const { translate } = useI18n();
  const changeTime = (index) => (newValue) => {
    const hours = [...state.hours];
    hours[index] = {...newValue};
    onChange('hours', hours);
  }
  const status = state.isOpen ? translate('open') : translate('closed');
  return (
    <Container className={className}>
      <Tab active={state.isOpen} onClick={()=>onChange('isOpen', !state.isOpen)}><Body>{day ? day : status }</Body></Tab>
      {day && <Body style={{flex: '1'}}>{status}</Body>}
      <TimeInput iconProps={{icon: ClockSVG, alt: 'time'}} value={state.hours[0]} onChange={changeTime(0)} labelProps={{label: translate('Opening')}} disabled={!state.isOpen}/>
      <TimeInput iconProps={{icon: ClockSVG, alt: 'time'}} value={state.hours[1]} onChange={changeTime(1)} labelProps={{label: translate('Mid Start')}} disabled={!state.isOpen}/>
      <TimeInput iconProps={{icon: ClockSVG, alt: 'time'}} value={state.hours[2]} onChange={changeTime(2)} labelProps={{label: translate('Close')}} disabled={!state.isOpen}/>
    </Container>
  );
};

export default TimeSelection;
