import useI18n from 'i18n/useI18n';
import React, { useEffect, useState } from 'react';
import useRoles from 'state/roles';
import { useParams } from 'react-router-dom';
import RoleForm from '../components/RoleForm';
import { useRouter } from 'providers/router';
import useUsers from 'state/users';
import { formatUsersForSelect, mapIdsToObjects, getUserDivisionsByRole } from 'utils';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import FormHeader from 'pages/Group/components/FormHeader';


const Edit = ({ location }) => {
  const { translate } = useI18n();
  const [formData, setFormData] = useState({name: '', description: '', users: []});
  const [data, dispatcher, utils] = useRoles();
  const [users, usersDispatcher] = useUsers();
  const { groupId } = useParams();
  const { route, canGoBack } = useRouter();

  useEffect(() => {
    dispatcher.fetchStart();
    usersDispatcher.fetchStart();
  }, []);

  useEffect(() => {
    if (users.length === 0 || data.list.length === 0) return;

    const url = location.pathname.split('/');
    const id = url.pop();
    const role = utils.findOne(id);

    const formData = { 
      id: role.id,
      name: role.name,
      description: role.config?.description ?? '',
      groupId: role.groupId,
      users: formatUsersForSelect(mapIdsToObjects(role.users.map(user=>user.id), users.list)),
    };
    setFormData(formData);
  }, [users.list, data.list]);

  const onSubmit = (e) => {
    e.preventDefault();
    const role = {
      id: formData.id,
      name: formData.name,
      groupId,
      config: { description: formData.description },
      users: getUserDivisionsByRole(formData.users, groupId, formData.id),
    };
    dispatcher.updateStart(role); 
  };

  return (
    <FormScrollableContainer>
      <FormHeader route={canGoBack() ? route.from : `/${groupId}/management/roles`} routeText={translate('Back to Roles')} />
      <RoleForm
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        title={translate('Edit Role')}
        loading={data.fetching.state}
        buttonText={translate('Update Role')}
        groupId={groupId}
      />
    </FormScrollableContainer>
  );
};

export default Edit;