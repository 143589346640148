import React, { useEffect, useReducer } from "react";
import { CustomContainer } from "containers/FormLayout";
import Money from "resources/img/money-icon.svg";
import { FormSpacer } from "containers/FormLayout";
import useI18n from "i18n/useI18n";
import { TitleH3 } from "components/Texts";
import { formatMoney } from "utils/text";
import { Input, Dropdown } from "@etiquette-ui/inputs-dropdowns";

const initialState = {
  amount: null,
  food: null,
  drink: null,
  iva: null,
  paymentMethod: null,
  paymentMethodName: null,
}

const reducer = (state, action) => {
  const IVA_MULTIPLIER = 0.16;

  switch (action.type) {
    case "init": {
      const newState = { ...action.payload };
      newState.amount = +newState.food + +newState.drink + +newState.iva;
      return newState
    };
    case 'update': {
      const newState = { ...state, ...action.payload };
      const foodIva = +(+newState.food * IVA_MULTIPLIER).toFixed(2);
      const drinkIva = +(+newState.drink * IVA_MULTIPLIER).toFixed(2);
      const iva = foodIva + drinkIva;
      newState.iva = iva;
      newState.amount = +newState.food + +newState.drink + +newState.iva;

      return newState
    };
    case "iva":{
      const newState = { ...state };
      newState.iva = action.payload;
      newState.amount = +newState.food + +newState.drink + +newState.iva;

      return newState
    };
    case "paymentMethod": {
      const newState = { ...state };
      const {id, name} = action.payload
      newState.paymentMethod = id;
      newState.paymentMethodName = name;

      return newState;
    }

    default: console.error("ACTION NOT VALID", action);
  }

}

const Movement = ({ data, update, paymentMethods }) => {
  const { translate } = useI18n();
  const [{ amount, food, drink, iva, paymentMethod, paymentMethodName }, dispatcher] = useReducer(reducer, initialState);


  useEffect(() => {
    dispatcher({ type: "init", payload: { ...data } })
  }, [])

  useEffect(() => {
    update({
      food: food,
      drink: drink,
      paymentMethod: paymentMethod,
      paymentMethodName: paymentMethodName,
      iva: iva
    });
  }, [food, drink, iva, paymentMethod, paymentMethodName]);

  const paymentMethodOptions = paymentMethods.map( (payment) => ({ value: payment.id, label: payment.name }));

  const getOptionValue = () => {
    if(!!data.paymentMethod && !!data.paymentMethodName) 
      return({ value: data.paymentMethod, label: data.paymentMethodName});

    if(!!paymentMethod && !paymentMethodName){
      const defaultOption = paymentMethodOptions.find( option => option.value === paymentMethod);
      dispatcher({ type: 'paymentMethod', payload: {id: defaultOption.value, name: defaultOption.label} });
      return defaultOption;
    }
    
    return null;
  }


  return (
    <>
      <CustomContainer columns="1">
        {paymentMethodOptions.length > 0 && (<Dropdown
          type="number"
          labelProps={{
            label: translate('Payment Method')
          }} 
          leadingIconProps={{
            icon: 'card'
          }} 
          selectProps={{
            options: paymentMethodOptions,
            value: getOptionValue(),
            onChange: (value) => dispatcher({ type: 'paymentMethod', payload: {id: value.value, name: value.label} }),
            required: true,
            placeholder: translate('Choose one'),            
            type: "number"
          }}
        />)}

      </CustomContainer>
      <FormSpacer />

      <CustomContainer columns="3">
        <Input
          labelProps={{
            label: translate("Food Sale")
          }}
          iconProps={{
            icon: 'price'
          }}
          inputProps={{
            value: data.food, 
            step: "0.01", 
            lang: "en", 
            onChange: (e) => dispatcher({ type: 'update', payload: { food: +e.target.value } }), 
            required: true, 
            type: 'number'
          }}
        />
        <Input
          labelProps={{
            label: translate("Drink Sale")
          }}
          iconProps={{
            icon: 'price'
          }}
          inputProps={{
            value: data.drink, 
            step: "0.01", 
            lang: "en", 
            onChange: (e) => dispatcher({ type: 'update', payload: { drink: +e.target.value } }), 
            required: true, 
            type: 'number'
          }}
        />

        <Input
          labelProps={{
            label: "IVA"
          }}
          iconProps={{
            icon: 'price'
          }}
          inputProps={{
            value: data.iva, 
            step: "0.01", 
            lang: "en", 
            onChange: (e) => dispatcher({ type: 'iva', payload: +e.target.value }), 
            required: true, 
            type: 'number'
          }}
        />
      </CustomContainer>
      <FormSpacer />
      <div>
        <TitleH3>{translate("Event Amount")}</TitleH3>
        <FormSpacer />
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src={Money} alt="money" style={{ width: "28px" }} />
          {amount === null ? "-----" : formatMoney(+amount, 2, ".", ",", '')}
        </div>
      </div>
      <FormSpacer />
      <FormSpacer />
    </>
  );
};

export default Movement;
