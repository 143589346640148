import React, {useState} from 'react';
import { BaseDialog } from '@etiquette-ui/alert-dialog';
import EmployeeSelect from 'components/Assignments/EmployeeSelect';
import RoleSelect from 'components/Assignments/RoleSelect';
import DivisionSelect from 'components/Assignments/DivisionSelect';
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';
import styled from 'styled-components';
import useUsers from 'state/users';
import useI18n from 'i18n/useI18n';
import { addRoleToDivision, getConsolidateAssignments } from 'utils/employee';

const ButtonsContainer = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;

  margin-top: 20px;
  margin-bottom: 30px;

  button {
    width: 130px;
    transition-duration: .6s;
    transition-property: transform;

    &:hover {
      transform: scale(.95);
    }
  }
`;

const Title = styled.h1`
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
`;

const Instruction =  styled.div`
  text-align: center;
  margin-top: 0px;
  margin-bottom: 25px;
  font-size: 15px;
`;

const AddEmployee = ({ open, onClose, groupId, roleId = null, divisionId = null}) => {

  const [employee, setEmployee] = useState(undefined);  
  const [division, setDivision] = useState(undefined);
  const [role, setRole] = useState(undefined);  
  
  const { translate } = useI18n();
  const [users, usersDispatcher] = useUsers();  

  const onSaveAssignment = (type) => {
    if(employee === undefined) return;

    const roleAux = type === 'division' ? role.value : roleId;
    const divisionAux = type === 'division' ? divisionId : division.value;

    if(!roleAux || !divisionAux) return;

    let editedEmployee = addRoleToDivision(employee,roleAux, divisionAux, groupId);
    editedEmployee.assignments = getConsolidateAssignments(editedEmployee.assignments);
    
    usersDispatcher.updateStart(editedEmployee, false);
    onClose();
  }

  return (
    <BaseDialog open={open} onClose={onClose} dismissible={false} isResponsive={true}>
      <Title>{translate('Add Employee')}</Title>
      <Instruction>{translate('Select an Employee and one role for Division.')}</Instruction>
      <EmployeeSelect
        employee={employee}
        setEmployee={setEmployee}
        groupId={groupId}
        employees={users.list.filter(({assignments}) => !!assignments && Object.keys(assignments).includes(groupId.toString()))}
      />  
      { roleId === null && !!divisionId && (
        <RoleSelect
          employee={employee}
          role={role}
          onChangeRole={setRole}
          divisionId={divisionId}
          groupId={groupId}
        />
      )}    
      { divisionId === null && !!roleId && (
        <DivisionSelect
          employee={employee}
          division={division}
          onChangeDivision={setDivision}
          roleId={roleId}
          groupId={groupId}
        />
      )}
      <ButtonsContainer>
        <SecondaryButton onClick={onClose}>{translate('Cancel')}</SecondaryButton>
        { roleId === null && !!divisionId && (<PrimaryButton onClick={ ()=>onSaveAssignment('division') }>{translate('Save')}</PrimaryButton>)}
        { divisionId === null && !!roleId && (<PrimaryButton onClick={ ()=>onSaveAssignment('role') }>{translate('Save')}</PrimaryButton>)}
      </ButtonsContainer>
    </BaseDialog>
  )
}
export default AddEmployee;