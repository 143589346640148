import React, { useState } from 'react';
import useUsers from 'state/users';

import EditDivisions from './EditDivisions';
import EmployeeSelect from 'components/Assignments/EmployeeSelect';
import DivisionSelect from 'components/Assignments/DivisionSelect';
import { addRoleToDivision } from 'utils/employee';

import {Row, SelectContainer} from '../styles';


const AddToRole = ({ title, formData, handleFormChange}) => {

  const [users] = useUsers();
  const [employee, setEmployee] = useState(undefined);

  const onChangeDivision = (division) => {
    if(!division) return; 
    
    let employees;    
    const repeatedEmployee = formData.users.find(item => item.value === employee.id);

    if(!!repeatedEmployee) {
      const filteredEmployees = formData.users.filter(item => item.value !== employee.id);
      const editedEmployee = addRoleToDivision(repeatedEmployee, formData.id,division.value, formData.groupId);
      employees = [editedEmployee,...filteredEmployees];

    } else {
      const editedEmployee = addRoleToDivision(employee, formData.id, division.value, formData.groupId);
      employees = [{
        value: editedEmployee.id,
        label: editedEmployee.name,
        ...editedEmployee
        },
        ...formData.users
      ];
    }

    handleFormChange('users', employees);
    setEmployee(undefined);
  }

  return (
    <div>
      <Row>
        <SelectContainer>
          {!!formData.groupId && (<EmployeeSelect
            employee={employee}
            setEmployee={setEmployee}
            employees={users.list.filter(({assignments}) => !!assignments && Object.keys(assignments).includes(formData.groupId.toString()))}
          />)}
        </SelectContainer>
        <SelectContainer>
          <DivisionSelect
            onChangeDivision={onChangeDivision}
            employee={employee}
            roleId={formData.id}
            groupId={formData.groupId}
          />
        </SelectContainer>
      </Row>
      {!!formData.users && formData.users.length > 0 && (
        <Row>
          {formData.users.map((employee, index) =>(
            <EditDivisions 
              key={`${employee.value}-${index}`}
              employee={employee} 
              formData={formData}
              handleFormChange={handleFormChange}
            />
          ))}
        </Row>
      )}      
    </div>
  );
};

export default AddToRole;