import React from 'react'
import GroupSelect from 'pages/Consolidate/components/GroupSelect';
import Space from 'components/Space';
import ByGroupLayout from 'pages/Consolidate/containers/LayoutByGroup';
import Details from 'pages/Consolidate/components/Details';
import useI18n from 'i18n/useI18n';
import useGroups from 'state/groups';

import AddToRole from 'pages/Consolidate/components/AddEmployee/AddToRole';

import { PrimaryButton } from '@etiquette-ui/buttons';

import { ACCESS_LIST } from 'resources/constants/config';

const Layout = ({
    onSubmit,
    addGroup,
    state,
    removeGroup,
    selectedValues,
    onChangeForm,
    onChangeUsers
}) => {
    const { translate } = useI18n();
    const [, , groupsUtils] = useGroups();
    return (
        <form onSubmit={onSubmit}>
            <GroupSelect
                onChange={addGroup}
                value={state.groups}
                onRemove={removeGroup}
                selectedValues={selectedValues}
                access={ACCESS_LIST.ROLE}
            />
            <Space />
            <>
                <Details
                    formData={state.data}
                    handleFormChange={onChangeForm}
                />
                <Space />

                {state.groups.map((groupId) => (
                    <ByGroupLayout key={`g-${groupId}`} title={groupsUtils.findOne(groupId).name}>
                        <AddToRole 
                            title={translate('Add Employees')}
                            formData={state}
                            handleFormChange={(values) => onChangeUsers( groupId, values )}
                            groupId={groupId}
                        />
                        <Space />
                    </ByGroupLayout>
                ))}
            </>

            <PrimaryButton type={'submit'}>
                {translate('Create Role')}
            </PrimaryButton>
        </form>
    )
}

export default Layout
