export const initialState = {
    name: "",
    description: "",
    permissions: []
};


/**
 * {
 *  groupdId : 1,
 *  apps : [
 *          {id, access : ["create_report"]}
 *  ]
 * }
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */

export default function reducer(state, action) {
    switch (action.type) {
        case "ADD": {
            const permissions = [...state.permissions];
            permissions.push(action.payload);
            return { ...state, permissions };
        }
        case "UPDATE": {
            const idx = action.payload.idx;
            const data = action.payload.data;

            const permissions = [...state.permissions];
            permissions[idx] = data;

            return { ...state, permissions };
        }
        case "DELETE": {
            const groupId = action.payload;
            const permissions = state.permissions.filter(p => +p.permission.groupId !== +groupId);
            
            return { ...state, permissions };
        }
        case "DETAILS": {
            const field = action.payload.field;
            const value = action.payload.value;

            const newState = { ...state };
            newState[field] = value;

            return newState;
        }

        case "APPS": {
            const appId = action.payload.appId;
            const idx = action.payload.idx;
            
            state.permissions[idx].apps.splice(appId, 1);
            return { ...state };

        }

        default: throw new Error('Action type not valid')
    }
}


