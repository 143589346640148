import useI18n from 'i18n/useI18n';
import React from 'react';
import styled from 'styled-components';
import TimeSelection from './TimeSelection';
import CloseIcon from 'resources/img/close-icon-dark.svg';
import { PassiveButton } from 'components/Buttons';
import { ACTIONS } from 'components/GroupForm/Reducer';
import { Center } from 'containers/FormLayout';
import { DateInput } from '@etiquette-ui/inputs-dropdowns';

const DateContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 8fr 1fr;
  grid-gap: 10px;
  align-items: center;
`;

const Close = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        cursor: pointer;
        width: 25px;
        height: 25px;
    }
`;

const Time = styled(TimeSelection)`
    margin: 0;
`;

const SpecialDates = ({ formData, dispatch }) => {
  const { translate, formatDate, selectedLang } = useI18n();
  const specialDates = formData?.config?.openingHours?.specialDates ?? [];

  const onChange = (index) => (key, value) => {
    dispatch(ACTIONS.EDIT_SPECIAL_DATES, { index, key, value });
  };

  return (
    <>
      {specialDates.map((specialDate, i) => (
        <>
          <DateContainer key={i}>
            <DateInput
              dateFormat={"MMM. d''yy"}
              calendarProps={{
                onChange: (value) => onChange(i)('date', value)
              }}        
              inputProps={{
                disabled: false,
                required: true,
              }}
              labelProps={{
                label: translate("Date")
              }}
              language={!!selectedLang ? "en" : "es"}
              value={specialDate.date}
              setValue={(val)=>{}}
            />
            {!!specialDate.date ? <Time state={specialDate} onChange={onChange(i)} /> : <div></div>}
            {specialDates.length > 1 ? <Close><img src={CloseIcon} alt={'remove'} onClick={() => dispatch(ACTIONS.SPECIAL_DATES, i)} /></Close> : <div></div>}
          </DateContainer>
          <div style={{ height: '10px' }}></div>
        </>
      ))}
      <div style={{ height: '10px' }}></div>
      <Center>
        {(specialDates.length > 1 ||
          (specialDates.length > 0 && specialDates[specialDates.length - 1] && specialDates[specialDates.length - 1].date)) && (
          <PassiveButton type="button" style={{ width: '210px', height: '50px' }} onClick={() => dispatch(ACTIONS.SPECIAL_DATES)}>
            {translate('Add Special Date')}
          </PassiveButton>
        )}
      </Center>
    </>
  );
};

export default SpecialDates;
