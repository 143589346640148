import React from 'react';
import useI18n from 'i18n/useI18n';
import { TitleH3 } from 'components/Texts';
import { FormSpacer, CustomContainer } from 'containers/FormLayout';
import usePermissions from 'state/permissions';
import FormErrorMessage from 'components/FormErrorMessage';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import { Card } from '@etiquette-ui/misc';
import RequiredContainer from './components/RequiredContainer';

const Permission = ({ formData, handleChange, groupId, errors='' }) => {
  const { translate } = useI18n();
  const [data] = usePermissions();

  return (
    <Card>
      <TitleH3>{translate('Assign Permission')}</TitleH3>
      <FormSpacer />
      <CustomContainer columns="2">
        <RequiredContainer>
          <Dropdown
            labelProps={{
              label: translate('Permission Name')
            }} 
            leadingIconProps={{
              icon: 'permission'
            }} 
            selectProps={{
              options: data.list.filter((p) => +p.groupId === +groupId).map(({ id, name }) => ({ value: id, label: name })),
              value: formData.permission,
              onChange: handleChange,
              required: true,
              placeholder: translate('Choose one')
            }}
          />
        </RequiredContainer>        
        <FormErrorMessage message={errors}/>
      </CustomContainer>
    </Card>
  );
};

export default Permission;
