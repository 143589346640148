import Axios from 'axios';
import Authorization from './auth';
import { executeCall } from './utils';

const surveyCrudder = (domain, resource) => {

  const url = `${domain}/${resource}`;

  const headers = Authorization;

  return {
      getAll: () => executeCall(() => Axios.get(url, { headers: headers })),
      create: (data) => executeCall(() => Axios.post(url, data, { headers: headers })),
      update: (id, data) => executeCall(() => Axios.patch(`${url}/${id}`, data, { headers: headers })),
      delete: (options) => executeCall(() => Axios.delete(url, { data: options, headers: headers })),
  };
};

export default surveyCrudder;
