import { DEFAULT_NO_ERROR } from 'state/constants';
import Types from './types';

const DEFAULT_FETCHING_STATE = {state: false, config: {}};

const INITIAL_STATE = {
    list: [],
    fetching: {state: false, config: {}},
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START: return { ...state, fetching: {...DEFAULT_FETCHING_STATE, state: true} }
        case Types.FETCH_SUCCESS: return { ...state, list: action.payload, fetching: {...DEFAULT_FETCHING_STATE}, error: { ...DEFAULT_NO_ERROR } }
        case Types.FETCH_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} }

        case Types.TOGGLE_ACTIVE:
        case Types.UPDATE_START: return { ...state, fetching: {state: true, config: {id: action.payload.id} } }
        case Types.UPDATE_SUCCESS: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: { ...DEFAULT_NO_ERROR } }
        case Types.UPDATE_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} }

        case Types.CREATE_START: return { ...state, fetching: {...DEFAULT_FETCHING_STATE, state: true} }
        case Types.CREATE_SUCCESS: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: { ...DEFAULT_NO_ERROR } }
        case Types.CREATE_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} }

        case Types.DELETE_START: return { ...state, fetching: {...DEFAULT_FETCHING_STATE, state: true} }
        case Types.DELETE_SUCCESS: 
            const list = [...state.list];
            const item = list.find((it)=>it.id==action.payload.id);
            const index = list.indexOf(item);
            if (index > -1) {
                list.splice(index, 1);
            }
            return { ...state, list, fetching: {...DEFAULT_FETCHING_STATE}, error: { ...DEFAULT_NO_ERROR } }
        case Types.DELETE_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} }
        
        case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

        default: return state;
    }
}

export default reducer