export const formatAddress = (address) => {
    return address ? `${address.street} ${address.number}, ${address.zipCode}, ${address.city}, ${address.state}.` : '';
}

export const getFullName = (user) => `${user.name}${user.secondName ? ` ${user.secondName}` : ""}${user.lastName ? ` ${user.lastName}` : ""}`

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",", currencySymbol = '$') => {
    try {
  
      const negativeSign = amount < 0 ? "-" : "";
      const amountFixed = Math.abs(amount).toFixed(decimalCount);
      const amountRounded = parseInt(amountFixed);
      const amountDecimal = parseInt((amountFixed%1).toFixed(decimalCount) * 100).toString();
  
      const amountLength = amountRounded.toString().length;
      const thousandPartsCount = Math.floor(amountLength / 3);
      const thousandsPartsModulus = amountLength % 3;    
  
      const parts = [];
      for(let i = 1; i<=thousandPartsCount; i++){
        parts.unshift(amountRounded.toString().substring(amountLength-i*3, (amountLength-i*3) + 3));
      }
      if(thousandsPartsModulus!==0){
        parts.unshift(amountRounded.toString().substring(0, thousandsPartsModulus));
      }
      return (
        negativeSign + 
        currencySymbol +
        (amountLength <=3 ? amountRounded : parts.join(thousands) )+
        decimal +
        (amountDecimal < 10 ? `0${amountDecimal}` : amountDecimal)
      );
      
    } catch (e) {
      console.error(e)
    }
  };