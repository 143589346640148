import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import List from './pages/List';
import Create from './pages/Create';
import Update from './pages/Edit';
import PrivateRoute from 'containers/PrivateRoute';
import { ACCESS_USER_TYPES } from 'resources/constants/config';


const Employees = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route exact path={`${path}/create`} component={Create} />
      <PrivateRoute exact path={`${path}/update/:id([0-9]+)`} component={Update} userType={ACCESS_USER_TYPES.ROOT} />
    </Switch>
  );
};

export default Employees;
