export const toFormData = (object) => {
  const formdata = new FormData();

  Object.entries(object).forEach(([key, value]) => {
    value = typeof value === "object" && !(value instanceof File) ? JSON.stringify(value) : value;
    formdata.append(key, value);
  });

  return formdata;
};

export const compareStrings = (a, b) => a.trim().toLowerCase().includes(b.trim().toLowerCase())

export const mapIdsToObjects = (ids, objects) => {
  const filteredIds = ids.filter((uid) => objects.some(({id})=>+uid===+id));
  const mappedObjects = filteredIds.map((uid) => objects.find(({id})=>+uid===+id));
  return mappedObjects;
}

export const formatObjectForSelect = (thing, valueKey='id', labelKey='name') => {
  if(!thing) return null;
  const { [valueKey]: value, [labelKey]: label , ...rest } = thing;
  return { value, label, ...rest };
}

export const formatUsersForSelect = (users) => {
  return users.map(({id, name, ...rest})=>({value: id, label: name, ...rest}));
}

export const getUsersRoleByDivision = (users, groupId, divisionId) => {
  const userRoles = {};
  for(const user of users){
    for(const assignment of user.assignments[groupId]){
      if(assignment.division === divisionId){
        userRoles[user.value] = {
          id: user.value,
          roles: assignment.roles
        };
        break;
      }
    }      
  }
  return Object.values(userRoles);
}

export const getUserDivisionsByRole = (users, groupId, roleId) => {
  const userDivisions = {};
  for(const user of users){
    for(const assignment of user.assignments[groupId]){
      if(assignment.roles.length > 0 && assignment.roles.includes(roleId)){
        if(!userDivisions[user.value]){
          userDivisions[user.value] = {
            id: user.value,
            divisions: []
          };
        }
        
        if(!assignment.division) continue;

        userDivisions[user.value].divisions = [
          ...userDivisions[user.value].divisions,
          assignment.division
        ];
        continue;
      }
    }      
  }
  return Object.values(userDivisions);
}