import PrivateRoute from 'containers/PrivateRoute';
import React from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { ACCESS_LIST } from 'resources/constants/config';
import Create from './pages/Create';
import Edit from './pages/Edit';
import List from './pages/List';

const Roles = () => {
  const { path } = useRouteMatch();
  const { groupId } = useParams();

  return (
    <Switch>
      <Route exact path={path} component={List} />
      <PrivateRoute exact path={`${path}/create`} component={Create} access={ACCESS_LIST.ROLE} groupId={groupId}/>
      <PrivateRoute exact path={`${path}/edit/:permissionId([0-9]+)`} component={Edit} access={ACCESS_LIST.ROLE} groupId={groupId}/>
    </Switch>
  );
};

export default Roles;
