const TYPE = `[NOMENCLATORS]`;

const TYPES = {
    FETCH_START_PAYMENT_METHOD: `${TYPE} FETCH START PAYMENT_METHOD`,
    FETCH_SUCCESS_PAYMENT_METHOD: `${TYPE} FETCH SUCCESS PAYMENT_METHOD`,
    FETCH_ERROR_PAYMENT_METHOD: `${TYPE} FETCH ERROR PAYMENT_METHOD`,

    RESET_ERROR_PAYMENT_METHOD: `${TYPE} RESET ERROR PAYMENT_METHOD`,

    FETCH_START_SALE_TYPE: `${TYPE} FETCH START SALE_TYPE`,
    FETCH_SUCCESS_SALE_TYPE: `${TYPE} FETCH SUCCESS SALE_TYPE`,
    FETCH_ERROR_SALE_TYPE: `${TYPE} FETCH ERROR SALE_TYPE`,

    RESET_ERROR_SALE_TYPE: `${TYPE} RESET ERROR SALE_TYPE`,

    FETCH_START_APP: `${TYPE} FETCH START APP`,
    FETCH_SUCCESS_APP: `${TYPE} FETCH SUCCESS APP`,
    FETCH_ERROR_APP: `${TYPE} FETCH ERROR APP`,

    RESET_ERROR_APP: `${TYPE} RESET ERROR APP`,

    FETCH_START_LANG: `${TYPE} FETCH START LANG`,
    FETCH_SUCCESS_LANG: `${TYPE} FETCH SUCCESS LANG`,
    FETCH_ERROR_LANG: `${TYPE} FETCH ERROR LANG`,

    RESET_ERROR_LANG: `${TYPE} RESET ERROR LANG`,
}

export default TYPES;
