import React, { useEffect, useMemo } from "react";
import { useRouteMatch } from 'react-router';
import TableCardContainer from "pages/Consolidate/components/TableCardContainer";
import FloatingActionButton from "components/FloatingActionButton";
import useI18n from "i18n/useI18n";
import LayoutTable from "pages/Consolidate/containers/LayoutTable";
import usePermissions from 'state/permissions';
import Table from "components/Table";
import { useHistory } from 'react-router';
import Structure from './structure';
import useGroups from "state/groups";
import { useSearch } from "providers/search";
import { compareStrings } from "utils";
import { useAlert } from '@etiquette-ui/alert-dialog';


const List = ({ location }) => {
  const { translate } = useI18n();
  const { path } = useRouteMatch();
  const [data, dispatcher] = usePermissions();
  const [, groupsDispatcher, groupUtils] = useGroups();
  const history = useHistory();
  const { search, groupsList } = useSearch();
  const alert = useAlert();

  useEffect(() => {
    dispatcher.fetchStart();
    groupsDispatcher.fetchStart();
  }, []);

  const groupDict = {};

  function getGroupName(groupId) {
    if (!groupDict[groupId]) {
      const group = groupUtils.findOne(groupId);
      groupDict[groupId] = group ? group.name : "-";
    }

    return groupDict[groupId]
  }

  const items= useMemo(() => {
    let list = [...data.list];
    if (search.length !== 0) list = list.filter(({ name, description }) => compareStrings(name, search) || compareStrings(description, search));
    if (groupsList.length > 0) list = list.filter(({groupId}) => groupsList.includes(groupId));
    return list;
  }, [data.list, search, groupsList]);

  return (
    <LayoutTable location={location}>
      <TableCardContainer>
        <Table
          structure={Structure}
          config={{ history, dispatcher, data, translate, getGroupName, alert }}
          items={items}
        />
        <FloatingActionButton to={`${path}/create`}>
          {translate("Add Permission")}
        </FloatingActionButton>
      </TableCardContainer>
    </LayoutTable>
  );
};

export default List;