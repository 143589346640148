import Axios from 'axios';
import Authorization from './auth';
import { executeCall } from './utils';

const crudder =
  (domain) =>
  (resource, withAuth = true) => {
    const url = `${domain}/${resource}`;

    const headers = (groupId) => {
      const headers = withAuth ? Authorization : {};
      return groupId ? { ...headers, groupId } : headers;
    };

    return {
      options: {
        headers,
        url,
      },
      getAll: (groupId) => executeCall(() => Axios.get(url, { headers: headers(groupId) })),
      getOne: (id, groupId) => executeCall(() => Axios.get(url + '/' + id, { headers: headers(groupId) })),
      create: (data, groupId) => executeCall(() => Axios.post(url, data, { headers: headers(groupId) })),
      update: (data, groupId) => executeCall(() => Axios.patch(url, data, { headers: headers(groupId) })),
      delete: (options) => executeCall(() => Axios.delete(url, { data: options, headers: headers() })),
    };
  };

export default crudder;
