import React, { useState } from 'react';
import { BaseDialog } from '@etiquette-ui/alert-dialog';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import useApps from 'state/apps';
import colors from 'resources/constants/colors';
import { ColorButton } from 'components/Buttons';
import { PUBLIC_URL } from 'resources/constants/urls';

const AppSelection = ({ open, onSubmit, onClose, groupId, selectedApps }) => {
  const { translate } = useI18n();
  const [apps] = useApps();
  const [selected, setSelected] = useState([]);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      const index = selected.indexOf(id);
      const tmp = [...selected];
      tmp.splice(index, 1);
      setSelected(tmp);
    } else {
      const tmp = [...selected];
      tmp.push(id);
      setSelected(tmp);
    }
  };
  return (
    <BaseDialog open={open} onClose={onClose} dismissible={false} header={translate('Adding Applications')}>
      <Container>
        <div>
          <Grid>
            {apps.list
              .filter((app) => +groupId === app.groupId || app.groupId === null)
              .filter((app) => !selectedApps.includes(app.id))
              .filter(({ identifier }) => {
                const app = apps.available.find((app) => app.identifier === identifier);
                if(!app) return false;
                return app.access && Object.keys(app.access).length!==0;
              })
              .map(({ id, name, identifier }, i) => (
                <AppCard key={i} selected={selected.includes(id)} onClick={() => handleSelect(id)}>
                  <AppIcon src={`${PUBLIC_URL}/logos/${identifier}.png`} />
                  <AppName>{name}</AppName>
                </AppCard>
              ))}
          </Grid>
        </div>
        <ColorButton type="button" onClick={() => onSubmit(selected)}>
          {translate('Add Selected Applications')}
        </ColorButton>
      </Container>
    </BaseDialog>
  );
};

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 55px;
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  max-height: 100%;
  overflow: auto;
`;

const AppCard = styled.div`
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-rows: 1fr 40px;
  align-items: center;
  justify-content: center;
  width: 98px;
  border-radius: 8px;
  padding: 5px;
  background-color: ${({ selected }) => (selected ? `${colors.lightBlue}` : 'transparent')};
  cursor: pointer;
  user-select: none;
`;

const AppIcon = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 5px;
  justify-self: center;
  align-self: center;
`;

const AppName = styled.p`
  font-weight: 400;
  font-size: 0.7rem;
  margin: 0;
  max-width: 70px;
  text-align: center;
  align-self: center;
  justify-self: center;
`;

export default AppSelection;
