import React from 'react';
import styled from 'styled-components';
import { WRONG_V_LIGHT, WRONG_DARK } from '@etiquette-ui/colors';

const Error = styled.div`
  font-size: 13px;
  font-weight: 600;    
  padding: 10px 15px;
  color: ${WRONG_DARK};
  background-color: ${WRONG_V_LIGHT};
`;

const FormErrorMessage = ({message=''}) => {
  return (
    <>
      {!!message &&(<Error>{message}</Error>)}
    </>    
  )
}
export default FormErrorMessage;
