import React, { useState } from 'react'
import styled from 'styled-components';
import Card from 'components/Card';
import AppFeatures from './AppFeatures';
import useI18n from 'i18n/useI18n';
import { PUBLIC_URL } from 'resources/constants/urls';


const AppCard = ({ data, onClick }) => {
  const { translate } = useI18n();
  const [selected, setSelected] = useState(true);
  const [cardStyle, setCardStyle] = useState({});
  const [spanStyle, setSpanStyle] = useState({});
  const [checkStyle, setCheckStyle] = useState({})

  const handleClick = () => {
    onClick(data);
    updateStyles(selected)
    setSelected(!selected);
  }

  const updateStyles = (selected) => {
    setCardStyle({ borderColor: selected ? "#4990E2" : "transparent" });
    setSpanStyle({ borderColor: selected ? "#4990E2" : "#0332693D" });
    setCheckStyle({ display: selected ? "block" : "none" });
  }

  return (
    <Main style={cardStyle} onClick={handleClick} cursor={'pointer'}>
      <CustomCard>
        <Container>
          <section className="container__appSection">
            <TitleApp>
              <p>{data.name}</p>
            </TitleApp>
            <AppColor>
              <img src={`${PUBLIC_URL}/logos/${data.identifier}.svg`} alt="" />
            </AppColor>
            <CheckApp>
              <span style={spanStyle}>
                <div style={checkStyle} />
              </span>
              <p>{translate("I Want")}</p>
            </CheckApp>
          </section>
          <section className="container__descriptionSection">
            {data.features.map((feature, idx) => <AppFeatures key={idx + "-feature-" + data.identifier} data={feature} />)}
          </section>
        </Container>
      </CustomCard>
    </Main>
  );
}

const Main = styled.div`
  cursor: ${(cursor => cursor || 'pointer')};
  border: 2px solid transparent;
  border-radius: 24px;
  box-sizing: border-box;
`;

const CustomCard = styled(Card)`
  border-radius: 24px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  .container__appSection {
    width: 20%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .container__descriptionSection {
    width: 80%;
    padding: 65px 5px 5px 5px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
  }
`;

const TitleApp = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  p {
    margin: 0;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const AppColor = styled.div`
  width: 100%;
  height: 89px;
  display: flex;
  align-items: center;
  img {
    width: 89px;
    height: 89px;
  }
`;

const CheckApp = styled.div`
  width: 100%;
  height: 89px;
  display: flex;
  align-items: center;
  position: relative;
  input {
    position: absolute;
    opacity: 1;
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin: 0;
  }
  span {
    width: 24px;
    height: 24px;
    border: 2px solid #0332693D;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      display: none;
      width: 8px;
      height: 12px;
      border: solid #4990E2;
      border-width: 0 3px 3px 0;
      border-radius: 1px;
      position: relative;
      top: -1px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  p {
    margin: 0 0 0 8px;
    font-weight: 400;
    font-size: 0.875rem;
  }
`;


export default AppCard;
