import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import User from './cells/User';
import Permission from './cells/Permission';
import Assignments from './cells/Assignments';
import Actions from './cells/Actions';
import SortText from 'components/Table/headers/SortText';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 2,
            header: ({globalState, ...rest}) => <SortText property={'name'} text={globalState.config.translate('Name')} style={{ marginLeft: '16px' }} {...rest}/>,
            body: ({ item }) => <User user={item} />,
        },
        {
            flex: 2,
            header: ({globalState}) => <HeaderText text={globalState.config.translate('Division/Role')} />,
            body: Assignments,
        },
        {
            flex: 2,
            header: ({globalState}) => <HeaderText text={globalState.config.translate('Permission')} />,
            body: ({ globalState, item, ...rest }) => <Permission permissions={item.permissions} groupId={globalState.config.groupId} {...rest}/>,
        },
        {
            flex: 2,
            header: () => <HeaderText text={''} />,
            body: Actions,
        },
    ],
};

export default structure;