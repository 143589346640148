import React, {useEffect} from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { TitleH3, TitleH4, Body } from 'components/Texts';
import CarouselItem from './components/CarouselItem';
import ChevronIcon from 'resources/img/chevron-Icon.svg';
import {FlexContainer, Learn, Contact, CarouselApp, CarouselContainer, Main} from './styles';
import useApps from 'state/apps';
import useI18n from 'i18n/useI18n';
import { REACT_APP_MAIL,  REACT_APP_PHONE} from 'resources/constants/contact';

const AppList = () => {
  const { translate } = useI18n(); 
  const { url } = useRouteMatch();
  const [apps, dispatcher] = useApps();

  useEffect(()=>{
    dispatcher.fetchAvailable();
  },[]);
  
  return (
    <Main>
      <TitleH3 style={{ marginBottom: '0.5rem' }}>{translate("Available Apps")}</TitleH3>
      <FlexContainer>
        <CarouselContainer>
          <CarouselApp>
            {!!apps.available && apps.available.map(item=>(
              <CarouselItem key={item.identifier} data={item} />
            ))}
          </CarouselApp>
        </CarouselContainer>
        <Link to={`${url}/learn`}>
          <Learn>
            <div className= "join">
              <TitleH4>{translate("Learn More")}</TitleH4>
              <img src={ChevronIcon} alt=''/>
            </div>
          </Learn>
        </Link>
      </FlexContainer>
      <Contact>
          <Body>{translate("Need Applications")}</Body>
          <TitleH4>{REACT_APP_MAIL}</TitleH4>
          <TitleH4>{REACT_APP_PHONE}</TitleH4>
      </Contact>
    </Main>
  );
}

export default AppList

