const TYPE = `[DIVISIONS]`;

const TYPES = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    UPDATE_START: `${TYPE} UPDATE START`,
    UPDATE_SUCCESS: `${TYPE} UPDATE SUCCESS`,
    UPDATE_ERROR: `${TYPE} UPDATE ERROR`,

    CREATE_START: `${TYPE} CREATE START`,
    CREATE_SUCCESS: `${TYPE} CREATE SUCCESS`,
    CREATE_ERROR: `${TYPE} CREATE ERROR`,

    CREATE_START_BULK: `${TYPE} CREATE START BULK`,

    DELETE_START: `${TYPE} DELETE START`,
    DELETE_SUCCESS: `${TYPE} DELETE SUCCESS`,
    DELETE_ERROR: `${TYPE} DELETE ERROR`,

    FETCH_ONE_START: `${TYPE} FETCH ONE START`,
    FETCH_ONE_SUCCESS: `${TYPE} FETCH ONE SUCCESS`,

    RESET_ERROR: `${TYPE} RESET ERROR`,

    UPDATE_ASSIGNMENTS_START: `${TYPE} UPDATE ASSIGNMENTS START`,
    UPDATE_ASSIGNMENTS_SUCCESS: `${TYPE} UPDATE ASSIGNMENTS SUCCESS`,
    UPDATE_ASSIGNMENTS_ERROR: `${TYPE} UPDATE ASSIGNMENTS ERROR`,
}

export default TYPES;