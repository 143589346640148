import React from 'react';
import styled from 'styled-components';
import SearchIcon from 'resources/img/search-icon.svg';
import useI18n from 'i18n/useI18n';
import { useSearch } from 'providers/search';

const InternalContainer = styled.div`
  display: flex;
  width: 80%;
  height: 48px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const InputAlternative = styled.input`
  flex: 1;
  font-weight: 600;
  background-color: transparent;
  border: 0px;
  padding: 0px;
  font-size: 0.875rem;
  outline: none;
`;

const Icon = styled.img`
  width: 20px;
`;

const Search = () => {
  const { translate } = useI18n();
  const { search, setSearch, visible } = useSearch();
  return visible ? (
    <InternalContainer>
      <InputAlternative
        type="text"
        placeholder={translate('Search')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Icon src={SearchIcon} alt="search icon" />
    </InternalContainer>
  ) : (
    <div></div>
  );
};

export default Search;
