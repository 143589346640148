import styled from "styled-components";
import { PassiveButton } from "components/Buttons";
import AddIcon from "resources/img/add-icon.svg";
import useI18n from "i18n/useI18n";
import { SecondaryButton } from "@etiquette-ui/buttons";

const AddPayment = ({ onClick }) => {
  const { translate } = useI18n();
  return (
    <Container>
      <Button type="button" onClick={onClick}>
        <div className="label">
           <div className="button__icon">
            <img src={AddIcon} alt="" />
          </div>
          <p>{translate("Add New Payment")}</p>
        </div>       
      </Button>
    </Container>
  );
};

const Button = styled(SecondaryButton)`
  .label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    img {
      width: 17px;
      height: 17px;
    }
  }
  p {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export default AddPayment;
