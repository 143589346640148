import React, { useState, useMemo, useEffect } from 'react';
import useUsers from 'state/users';

import EditRoles from './EditRoles';
import EmployeeSelect from 'components/Assignments/EmployeeSelect';
import RoleSelect from 'components/Assignments/RoleSelect';
import { addRoleToDivision } from 'utils/employee';

import { Row, SelectContainer} from '../styles';

const AddToDivision = ({ formData, handleFormChange}) => {

  const [users] = useUsers();
  const [employee, setEmployee] = useState(undefined);

  const onChangeRole = (role) => {
    if(!role) return; 
    
    let employees;    
    const matchedEmployee = formData.users.find(item => item.value === employee.id);

    if(!!matchedEmployee) {
      const filteredEmployees = formData.users.filter(item => item.value !== employee.id);
      const editedEmployee = addRoleToDivision(matchedEmployee,role.value, formData.id, formData.groupId);
      employees = [editedEmployee,...filteredEmployees];

    } else {
      const editedEmployee = addRoleToDivision(employee,role.value, formData.id, formData.groupId);
      employees = [{
        value: editedEmployee.id,
        label: editedEmployee.name,
        ...editedEmployee
        },
        ...formData.users
      ];
    }

    handleFormChange('users', employees);
    setEmployee(undefined);
  }

  const options = useMemo(()=>{
    const list = users.list
      .filter(({ assignments }) => {
        if (!assignments || !assignments[+formData.groupId]) return false;
        return true;
      })
      .filter(({ id }) => {
        return !formData.heads.some(({ value }) => value === id);
      });
    return list;
  },[formData.heads]);
  

  return (
    <div>
      <Row>
        <SelectContainer>
          <EmployeeSelect
            employee={employee}
            setEmployee={setEmployee}
            employees={options.filter(({assignments}) => !!assignments && Object.keys(assignments).includes(formData.groupId.toString()))}
          />
        </SelectContainer>
        <SelectContainer>
          <RoleSelect
            onChangeRole={onChangeRole}
            employee={employee}
            divisionId={+formData.id}
            groupId={+formData.groupId}
          />
        </SelectContainer>
      </Row>
      {!!formData.users && formData.users.length > 0 && (
        <Row>
          {formData.users.map((employee, index) =>(
            <EditRoles 
              key={`${employee.value}-${index}`}
              employee={employee} 
              formData={formData}
              handleFormChange={handleFormChange}
            />
          ))}
        </Row>
      )}      
    </div>
  );
};

export default AddToDivision;