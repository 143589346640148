import React from 'react';
import colors from 'resources/constants/colors';
import useDivisions from 'state/divisions';
import useRoles from 'state/roles';
import { Body } from 'components/Texts';

import { Spinner } from '@etiquette-ui/misc';

const Assignments = ({ item, globalState, count }) => {
  const [divisions,, divUtils] = useDivisions();
  const [roles,, roleUtils] = useRoles();

  return (
    <div>
      {divisions.fetching.state || roles.fetching.state ? (
        <Spinner 
          style={{
            '--width': '35px',
            '--margin': '0 5px',
            '--bg': `${count % 2 === 0 ? colors.backgroundLightGrey : 'white'}`,
            '--color': `${colors.backgroundDarkBlue}`
          }}
        />
      ) : (
        !!item.assignments &&
        !!item.assignments[globalState.config.groupId] &&
        item.assignments[globalState.config.groupId].filter(({division, roles})=> !!division || roles.length > 0).map(({ division, roles }) => (
          <Body style={{margin: 'revert'}} key={division}>
            {`${divUtils.findOne(division)?.name ?? globalState.config.translate('No Division')}${roles.length > 0 ? ` | ${roles.map((id) => roleUtils.findOne(id)?.name).join(', ')}` : ''}`}
          </Body>
        ))
      )}
    </div>
  );
};

export default Assignments;
