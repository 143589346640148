import React, { useEffect } from 'react';
import Layout from 'pages/Consolidate/containers/Layout';
import useI18n from "i18n/useI18n";
import useGroups from "state/groups";
import Form from './components/Form'
import { useRouter } from 'providers/router';


const Create = () => {
  const { translate } = useI18n();
  const [, dispatcher] = useGroups();
  const { route, canGoBack } = useRouter();

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  const onClickImportData = () => {
    console.log('***Flag, onClickImportData***')
  }

  return (
    <Layout
      pathBack={canGoBack() ? route.from : `/consolidate/permissions`}
      pathBackText={translate('Back to Roles')}
      onClickImportData={onClickImportData}
      title={translate('New Role')}
      onSubmitText={translate('Create Role')}
    >
      <Form />
    </Layout>
  );
};

export default Create;

