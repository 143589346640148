import React, { Fragment} from 'react';
import useI18n from 'i18n/useI18n';
import { TitleH3 } from 'components/Texts';
import { FormSpacer, Center } from 'containers/FormLayout';
import useDivisions from 'state/divisions';
import useRoles from 'state/roles';
import { CardActionButton } from 'components/Buttons';
import TrashSVG from 'resources/img/trash-icon.svg';
import FormErrorMessage from 'components/FormErrorMessage';
import styled from 'styled-components';
import Assignment from './Assignment';
import { SecondaryButton } from '@etiquette-ui/buttons';
import { Card } from '@etiquette-ui/misc';

const MessageContainer = styled.div`
  margin-top: -20px;
  margin-bottom: 15px;
`;

const ButtonContainer = styled.div`
  width: 210px;
`;

const CustomCard = styled(Card)`
  position: relative;
`;

const Assignments = ({ formData, handleDivisionChange, handleRoleChange, addAssignment, removeAssignment, groupId, errors }) => {
  const { translate } = useI18n();
  const [divisions] = useDivisions();
  const [roles] = useRoles();
  
  return (
    <>
      {formData.assignments.map((asgnmt, i) => (
        <Fragment key={i}>
          <CustomCard>
            <TitleH3>{translate('Division and Role')}</TitleH3>
            <FormSpacer />
            <Assignment 
              formData={formData}
              asgnmt={asgnmt}
              index={i}
              handleDivisionChange={handleDivisionChange}
              handleRoleChange={handleRoleChange}
              divisions={divisions.list.filter((p) => +p.groupId === +groupId).map(({ id, name }) => ({ value: id, label: name }))}
              roles={roles.list.filter((p) => +p.groupId === +groupId).map(({ id, name }) => ({ value: id, label: name }))}
            />            
            {i!==0 && <CardActionButton onClick={()=>removeAssignment(i)} icon={TrashSVG} >{translate('Remove Assignment')}</CardActionButton>}
          </CustomCard>
          <FormSpacer />
        </Fragment>
      ))}
      {!!errors &&(
        <MessageContainer>
          <FormErrorMessage message={errors}/>
        </MessageContainer>  
      )}          
      <Center>
        <ButtonContainer>
          <SecondaryButton type="button" onClick={addAssignment}>
            {translate('Add Division')}
          </SecondaryButton>
        </ButtonContainer>
      </Center>
    </>
  );
};

export default Assignments;
