import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import styled from 'styled-components';
import { TitleH4 } from 'components/Texts';
import useApps from 'state/apps';
import Permission from './Permission';
import RedMinus from 'resources/img/minus-icon.svg'
import { PUBLIC_URL } from 'resources/constants/urls';

const AppCard = ({ id, handleRemove }) => {
  const [apps, appDispatcher] = useApps();
  const [isSelected, setIsSelected] = useState([]);

  useEffect(() => {
    appDispatcher.fetchAvailable()
  }, [])

  const app = apps.list.find(item => item.id === id);
  const available = apps.available.find(item => item?.identifier === app?.identifier);

  const onSelect = (permission) => {
    const newSelect = [...isSelected];

    if (newSelect?.includes(permission)) {
      const index = newSelect.indexOf(permission);
      newSelect.splice(index, 1);
      setIsSelected(newSelect);
    }
    else {
      setIsSelected([...newSelect, permission]);
    }
  }


  return (
    <CardContainer>
      <Remove src={RedMinus} alt={'remove-app'} onClick={handleRemove} />
      <Header>
        <Icon src={`${PUBLIC_URL}/logos/${app?.identifier}.svg`} />
        <TitleH4 style={{ marginLeft: '10px', alignSelf: 'center' }}>{app?.name}</TitleH4>
      </Header>
      <PermissionsContainer>
        {!!available?.access && Object.values(available?.access).map((item, idx) =>
          <Permission
            key={item}
            text={item}
            checked={isSelected?.includes(Object.keys(available.access)[idx]) === true ? true : false}
            onClick={() => onSelect(Object.keys(available.access)[idx])}
          />
        )}
      </PermissionsContainer>
    </CardContainer>
  );
};

const Header = styled.div`
  display: flex;
`;
const CardContainer = styled(Card)`
  width: 242px;
  height: 320px;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Icon = styled.img`
  width: 64px;
  height: 64px;
`;
const PermissionsContainer = styled.div`
  flex: 1;
  overflow: auto;
  margin-top: 10px;
`;
const Remove = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
`;


export default AppCard;
