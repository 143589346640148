import Types from './types';
import { DEFAULT_NO_ERROR } from 'state/constants';
import { getGroupedAssignments } from 'utils/employee';

const DEFAULT_FETCHING_STATE = { state: false, config: {} };

const INITIAL_STATE = {
    list: [],
    fetching: { state: false, config: {} },
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case Types.FETCH_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } }
      case Types.FETCH_SUCCESS: return { ...state, list: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } }
      case Types.FETCH_ERROR: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } }

      case Types.UPDATE_START: return { ...state, fetching: { state: true, config: { id: action.payload.id } } }
      case Types.UPDATE_SUCCESS: {
        const list = [...state.list];
        const id = action.payload.id;
        for( const item of list) {
          if(+item.id===+id &&  Array.isArray(item.assignments) ) {
            item.assignments = getGroupedAssignments(item.assignments);
          }
        };
        return { ...state, list, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } }}
      case Types.UPDATE_ERROR: return {...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload },};

      case Types.CREATE_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } };
      case Types.CREATE_SUCCESS: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: 0, message: "" },};
        
      case Types.CREATE_ERROR:
        return {
          ...state,
          fetching: { ...DEFAULT_FETCHING_STATE },
          error: { timestamp: Date.now(), message: action.payload },
        };

      case Types.UNREGISTER_START:
      case Types.DELETE_START: return { ...state, fetching: {...DEFAULT_FETCHING_STATE, state: true} }
      case Types.DELETE_SUCCESS: {
        const list = [...state.list];
        const id = action.payload.id;
        const item = list.find((it)=>+it.id===+id);
        const index = list.indexOf(item);
        if (index > -1) {
          list.splice(index, 1);
        }
        return { ...state, list, fetching: {...DEFAULT_FETCHING_STATE}, error: { ...DEFAULT_NO_ERROR } }
      }
            
      case Types.UNREGISTER_ERROR:
      case Types.DELETE_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} }
        

      case Types.TOGGLE_ACTIVE : {
        const id = action.payload.id;
        const isActive = action.payload.isActive;

        const list = [...state.list];
        const idx = list.findIndex((it)=>+it.id===+id);
        list[idx].isActive = isActive;

        return {...state , list}
      }

      case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

      default: return state;
  }
};

export default reducer;