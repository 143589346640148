import { parseAssignments } from 'utils/employee';

const submitProcessor = (formData) => {
    const newData = {...formData.personalInfo};

    const image = formData.personalInfo.image;
    newData.image = image?.file ? image.file : image || null;

    newData.permissions = formData.groups.map(({permission})=> permission ? +permission.value : null).filter((val)=>val!==null);

    const assignments = formData.groups.reduce((acc, curr)=>[...acc, ...curr.assignments], []);
    newData.assignments = [
      ...parseAssignments(assignments.map(({ groupId, division, role }) => ({ groupId, division: division?.value ?? null, role: role?.value ?? null }))),
      ...formData.groups.map(({element})=>({ groupId: +element.value })),
    ];

    return newData;
}

export default submitProcessor;