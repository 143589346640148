import React, { useEffect, useMemo } from 'react';
import useI18n from 'i18n/useI18n';
import { useHistory, useParams } from 'react-router-dom';
import useDivisions from 'state/divisions';
import Table from 'components/Table';
import structure from './structure';
import ManagementTabsContainer from 'pages/Group/pages/Management/containers/ManagementTabsContainer';
import { useAlert } from '@etiquette-ui/alert-dialog';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';
import { useAccess } from 'providers/access';
import { ACCESS_LIST } from 'resources/constants/config';
import useUsers from 'state/users';
import useRoles from 'state/roles';

const List = ({ location }) => {
  const [data, dispatcher] = useDivisions();
  const history = useHistory();
  const { translate } = useI18n();
  const { groupId } = useParams();
  const alert = useAlert();
  const { search, setVisible } = useSearch();
  const { hasAccessInGroup } = useAccess();
  const [users, usersDispatcher] = useUsers();
  const [, rolesDispatcher] = useRoles();

  useEffect(() => {
    dispatcher.fetchStart(groupId);
    usersDispatcher.fetchStart(groupId);
    rolesDispatcher.fetchStart(groupId);
  }, [groupId, users.list]);

  useEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const items = useMemo(() => {
    let list = [...data.list];
    if (search.length !== 0) list = list.filter(({ name }) => compareStrings(name, search));
     return list;
  }, [data.list, search]);

  return (
    <ManagementTabsContainer location={location} fabLink={`${location.pathname}/create`} fabText={translate('Add Division')} fabDisabled={!hasAccessInGroup(groupId, ACCESS_LIST.DIVISION)}>
      <Table structure={structure} config={{ history, dispatcher, data, translate, groupId, alert }} items={items.filter((app) => +groupId === app.groupId)} />
    </ManagementTabsContainer>
  );
};

export default List;
