import React from 'react';
import Actions from 'components/DynamicTable/DynamicTableComponents/Editable/Actions';
import ActionEditIcon from 'resources/img/action-edit-icon.svg';
import ActionDeleteIcon from 'resources/img/action-delete-icon.svg';
import Switch from 'components/Switch';
import { ALERT_TYPES } from '@etiquette-ui/alert-dialog';
import { useAccess } from 'providers/access';
import { ACCESS_LIST, ACCESS_USER_TYPES } from 'resources/constants/config';

const RowActions = ({ item, state, globalState }) => {
  const { hasAccessInGroup, hasAccess } = useAccess();

  const actions = [
    {
      event: () => {
        globalState.config
          .alert({
            type: ALERT_TYPES.CONFIRM,
            config: {
              title: globalState.config.translate('Delete Employee'),
              body: globalState.config.translate('Are you sure you want to delete this employee?'),
              closeText: globalState.config.translate('Go Back'),
              submitText: globalState.config.translate('Delete'),
            },
          })
          .then(() => {
            globalState.config.dispatcher.unregisterStart({ id: +item.id, groupId: globalState.config.groupId });
          })
          .catch(() => {});
      },
      src: ActionDeleteIcon,
      disabled: !hasAccessInGroup(globalState.config.groupId, ACCESS_LIST.EMPLOYEE ),
    },
    {
      event: () => globalState.config.history.push(`employees/edit/${item.id}`),
      src: ActionEditIcon,
      disabled: !hasAccess(null, ACCESS_USER_TYPES.ROOT),
    }
  ];

  return <Actions actions={actions} style={{ alignItems: 'start', marginTop: '10px' }} />;
};

export default RowActions;
