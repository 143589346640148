import React, { useReducer, useEffect } from 'react';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import HomeContainer from 'pages/Home/containers/HomeContainer';
import Card from 'components/Card';
import IconLink from 'components/IconLink';
import useGroups from 'state/groups';
import CreateGroupForm from 'components/GroupForm';
import { useRouter } from 'providers/router';
import INIT_STATE from 'components/GroupForm/initState';
import Reducer from 'components/GroupForm/Reducer';
import toSubmitObject from 'components/GroupForm/submitProcessor';
import { HIDDEN_SCROLLBARS } from 'resources/constants/styles';

const CustomHomeContainer = styled(HomeContainer)`
  padding-bottom: 0;
  > div {
    display: block;
    height: 100%;
    overflow: scroll;
    ${HIDDEN_SCROLLBARS}
  }
`;

const CreateGroup = () => {
  const { translate } = useI18n();
  const [formData, formDispatcher] = useReducer(Reducer, INIT_STATE);
  const [, groupDistacher] = useGroups();
  const { route, canGoBack } = useRouter();

  const dispatch = (type, payload) => formDispatcher({ type, payload });

  const handleSubmit = () => {
    const newGroup = toSubmitObject(formData);
    groupDistacher.createStart(newGroup);
  };

  return (
    <CustomHomeContainer>
      <IconLink to={canGoBack() ? route.from : `/`}>{translate('Back to groups')}</IconLink>
      <div style={{ height: '32px' }}></div>
      <Card>
        <CreateGroupForm
          title={translate('New Group')}
          formData={formData}
          dispatch={dispatch}
          handleSubmit={handleSubmit}
          button={translate('Create New Group')}
        />
      </Card>
      <div style={{ height: '32px' }}></div>
    </CustomHomeContainer>
  );
};

export default CreateGroup;
