import { formatObjectForSelect, mapIdsToObjects } from 'utils';
import { unParseAssignments } from 'utils/employee';
import { DEFAULT_ASSIGNMENT } from '../../Reducer';

const findPermission = (permissionsIds, groupId, permissions) => {
  const data = mapIdsToObjects(permissionsIds, permissions);
  const permission = data.find((group) => +group.groupId === +groupId);
  return !!permission ? permission.id : null;
};

const getAssignments = (employeeAssignments, groupId) => {
  const assignments = !!employeeAssignments ? { [groupId]: employeeAssignments[groupId]} : {};
  const unparse = unParseAssignments(assignments);
  
  if(Object.keys(unparse).length > 0) 
    return unparse[groupId].filter(({ division, role }) => !!division || !!role);

  else return [DEFAULT_ASSIGNMENT(groupId)];
};

const toFormState = (employee, groupId, permissionUtils, divisionUtils, roleUtils) => {
  const permission = findPermission(employee.permissions ?? [], groupId, permissionUtils.getAll());

  const newData = {
    personalInfo: {
      id: employee.id,
      name: employee.name,
      lastName: employee.lastName ?? '',
      secondName: employee.secondName ?? '',
      userPosId: employee.userPosId ?? '',
      email: employee.email ?? '',
      image: employee.image ?? '',
      isActive: employee.isActive ?? 1,
      shortName: employee.shortName ?? '',
      pass: '',
      shortPassword: '',
      ofType: employee.ofType ?? 'regular',
    },
    permission: formatObjectForSelect(permissionUtils.findOne(permission)),
    assignments: getAssignments(employee.assignments, groupId).map(({ groupId, division, role }) => ({
      groupId,
      division: formatObjectForSelect(divisionUtils.findOne(division)),
      role: formatObjectForSelect(roleUtils.findOne(role)),
    })),
  };

  return newData;
};

export default toFormState;
