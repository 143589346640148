import React from 'react';
import { Text } from '@etiquette-ui/dynamic-table-components';
import RowActions from './cells/Actions';
import RowContainer from 'components/Table/RowContainer';
import SortDate from './headers/SortDate';
import SortText from 'components/Table/headers/SortText';
import Switch from 'components/Switch';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 2,
            header: ({ globalState, ...rest }) => (
                <SortText property={'name'} text={globalState.config.translate('Name')} {...rest} />
            ),
            body: ({ item }) => <Text text={item.name} style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }} />,
        },
        {
            flex: 2,
            header: ({ applyFilter, resetFilters, globalState, filters }) => (
                <SortDate
                    title={globalState.config.translate('Creation')}
                    property={'createdAt'}
                    applyFilter={applyFilter}
                    resetFilters={resetFilters}
                    globalState={globalState}
                    filters={filters}
                />
            ),
            body: ({ item, globalState }) => (
                <Text
                    text={globalState.config.formatDate(item.createdAt)}
                    style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }}
                />
            ),
        },
        {
            flex: 2,
            header: ({ applyFilter, resetFilters, globalState, filters }) => (
                <SortDate
                    title={globalState.config.translate('Modification')}
                    property={'updatedAt'}
                    applyFilter={applyFilter}
                    resetFilters={resetFilters}
                    globalState={globalState}
                    filters={filters}
                />
            ),
            body: ({ item, globalState }) => (
                <Text
                    text={globalState.config.formatDate(item.updatedAt)}
                    style={{ marginLeft: '16px', marginTop: '10px', alignItems: 'start' }}
                />
            ),
        },
        {
            flex: 1,
            header: () => <div></div>,
            body: ({ item, globalState }) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                        active={item.status === 'active'}
                        onClick={() => {
                            const newItem = { ...item };
                            newItem.status = item.status === 'active' ? 'inactive' : 'active';
                            globalState.config.onUpdate(newItem);
                        }}
                    />
                </div>
            ),
        },
        {
            flex: 2,
            header: ({ globalState }) => <HeaderText text={globalState.config.translate('Action Items')} />,
            body: RowActions,
        },
    ],
};

export default structure;
