import React from 'react';
import colors from 'resources/constants/colors';
import styled from 'styled-components';
import Checkmark from 'resources/img/checkbox.svg'

const Square = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid ${({checked})=>checked ? `${colors.accept}` : '#0332693D'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Check = styled.img`
    height: 16px;
    width: 16px;
`;

const Checkbox = ({ className, checked, onClick }) => {
  return <Square className={className} checked={checked} onClick={onClick}>{checked && <Check src={Checkmark}/>}</Square>;
};

export default Checkbox;
