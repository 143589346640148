import React, { useEffect, useMemo } from 'react';
import Card from 'components/Card';
import useI18n from 'i18n/useI18n';
import { useHistory, useParams } from 'react-router-dom';
import useSalesAdjustment from 'state/salesAdjustment';
import Table from 'components/Table';
import structure from './structure';
import FloatingActionButton from 'components/FloatingActionButton';
import styled from 'styled-components';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';
import { useAlert } from '@etiquette-ui/alert-dialog';

const Content = styled(Card)`
  width: 90%;
  margin-bottom: 40px;
`;

const List = ({ location }) => {
  const [data, dispatcher] = useSalesAdjustment();
  const history = useHistory();
  const { translate } = useI18n();
  const { groupId } = useParams();
  const { search, setVisible } = useSearch();
  const alert = useAlert();

  useEffect(() => {
    dispatcher.fetchStart(groupId);
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const items = useMemo(() => {
    let list = [...data.list];
    if (search.length !== 0)
      list = list.filter(
        ({ name, comment, lastUpdatedByName }) =>
          compareStrings(name ?? '', search) || compareStrings(comment ?? '', search) || compareStrings(lastUpdatedByName ?? '', search)
      );
    return list;
  }, [data.list, search]);

  return (
    <>
      <Content>
        <Table
          structure={structure}
          config={{ history, dispatcher, data, translate, alert, groupId }}
          items={items.filter((app) => +groupId === app.groupId)}
        />
      </Content>

      <FloatingActionButton to={`${location.pathname}/create`}>{translate('Add Adjustment')}</FloatingActionButton>
    </>
  );
};

export default List;
