import React, {useEffect, useMemo} from 'react';
import FloatingActionButton from 'components/FloatingActionButton';
import TableCardContainer from 'pages/Consolidate/components/TableCardContainer';
import useI18n from 'i18n/useI18n';
import LayoutTable from "pages/Consolidate/containers/LayoutTable";
import Table from "components/Table";
import Structure from './structure';
import { useHistory } from 'react-router-dom';
import useGroups from 'state/groups';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';
import { useAlert } from '@etiquette-ui/alert-dialog';
import useApps from 'state/apps';


const List = ({location}) => {
  const { translate } = useI18n();
  const history = useHistory();
  const [data, dispatcher] = useGroups();
  const { search, groupsList } = useSearch();
  const alert = useAlert();
  const [, appsDispatcher] = useApps();

  useEffect(() => {
    appsDispatcher.fetchStart();
  }, []);

  const items = useMemo(()=>{
    let list = [...data.list];
    if (search.length !== 0) list = list.filter(({ name }) => compareStrings(name, search));
    if (groupsList.length > 0) list = list.filter(({id}) => groupsList.includes(id));
    return list;
  },[data.list, search, groupsList]);

  return (
    <LayoutTable location={location}>
      <TableCardContainer>
        <Table
          structure={Structure}
          config={{ history, dispatcher, data, translate, alert }}
          items={items}
        />
        <FloatingActionButton to={`/create`}>
          {translate('Add Group')}
        </FloatingActionButton>
      </TableCardContainer>
    </LayoutTable>
  );
};

export default List;