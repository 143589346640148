import React, { useEffect, useState } from 'react';
import Tabs from 'components/Tabs';
import { useRouteMatch } from 'react-router';
import TabsContainer from 'pages/Group/pages/Management/containers/TabsContainer';

const routes = (url) => [
  { path: `${url}/profile`, title: 'Profile' },
  { path: `${url}/permissions`, title: 'Permissions' },
  { path: `${url}/divisions`, title: 'Divisions' },
  { path: `${url}/roles`, title: 'Roles' },
  { path: `${url}/employees`, title: 'Employees' },
];

const TopNavBar = ({ location }) => {
  const { url } = useRouteMatch();
  const [path, setPath] = useState('');

  useEffect(()=>{
    const urlArray = url.split('/');
    urlArray.pop();
    setPath(urlArray.join('/'));
  },[url]);
  
  return (
    <>
      <TabsContainer>
        <Tabs routes={routes(path)} pathIndex={3} current={location.pathname} />
      </TabsContainer>
    </>

  );
};

export default TopNavBar;
