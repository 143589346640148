import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from 'state/store';
import { ConnectedRouter } from 'connected-react-router'
import { history } from "./resources/history";

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);
