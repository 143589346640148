import ManagementIcon from "resources/img/sidebar/management.svg";
import EmailTemplatesIcon from "resources/img/sidebar/templates.svg";
import SalesIcon from "resources/img/sidebar/sales.svg";
import SurveyIcon from "resources/img/sidebar/survey-icon.svg";
import { ACCESS_LIST, SURVEY_BUILDER_ENABLED } from "resources/constants/config";

const routes = (url) => {
  const routes = [
    { path: `${url}/management`, title: 'Management', icon: ManagementIcon, access: '' },
    { path: `${url}/templates`, title: 'Email Templates', icon:EmailTemplatesIcon, access: ACCESS_LIST.EMAIL_TEMPLATE },
    { path: `${url}/adjustments`, title: 'Sales Adjustment', icon:SalesIcon, access: ACCESS_LIST.SALE },
  ];
  if(SURVEY_BUILDER_ENABLED) routes.push({ path: `${url}/survey-builder`, title: 'Survey Builder', icon:SurveyIcon, access: ACCESS_LIST.SALE });

  return routes;
};

export default routes;
