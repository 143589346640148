import colors from 'resources/constants/colors';
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: flex-end;
`;

export const FormSpacer = styled.div`
  height: 32px;
`;

export const Divider = styled.div`
  height: 2px;
  background-color: ${colors.disabled};
  opacity: 0.24;
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({columns}) => (columns)}, 1fr);
  column-gap: 30px;
  align-items: flex-end;
  width: ${({width}) => (width || '100%')};
`;