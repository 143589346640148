import IconLink from 'components/IconLink';
import ImportData from 'components/ImportData';
import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-bottom: 15px;
`;

const DEFAULT_IMPORT_OPTIONS = [];

const FormHeader = ({ route, routeText, importOptions=DEFAULT_IMPORT_OPTIONS, importData, importFormatOption }) => {
  return (
    <HeaderContainer>
      <IconLink to={route}>{routeText}</IconLink>
      {!!importData && <ImportData options={importOptions} onChange={importData} customOption={importFormatOption}/>}
    </HeaderContainer>
  );
};

export default FormHeader;
