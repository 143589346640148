import Axios from 'axios';
import Authorization from './auth';
import { API } from '../constants/urls';

const requestApps = (apps, groupId) => {
    const url = `${API}/security/app/request`;
    return Axios.post(url, { apps, groupId }, {
        headers: Authorization
    });
}

export default requestApps;