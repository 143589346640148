import React from 'react';
import { Body } from 'components/Texts';
import styled from 'styled-components';
import Checkbox from 'components/Checkbox';

const PermissionContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

const Permission = ({ onClick, checked, text }) => {
  return (
    <PermissionContainer>
      <Checkbox onClick={onClick} checked={checked} />
      <Body style={{userSelect: 'none', flex: '1', marginLeft: '5px'}}>{text}</Body>
    </PermissionContainer>
  );
};

export default Permission;