export default class Utils {
    constructor(state, dispatcher) {
        this.state = state;
        this.dispatcher = dispatcher;
    }

    findByGroupId(id) {
        return this.state.list.filter(e => +e.groupId === +id)
    }

    findOne(id) {
        return this.state.list.find(e => +e.id === +id)
    }
}