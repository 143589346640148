

import React from "react";
import useI18n from "i18n/useI18n";
 import { LangDropdown as LangSelector } from "@etiquette-ui/inputs-dropdowns";
 
const Lang = () => {
   const { selectedLang, setSelectedLang } = useI18n();

  return (
    <div>
      <LangSelector
        selectedLang={selectedLang}
        setSelectedLang={setSelectedLang}
        labelStyles={{
          color: 'white'
        }}
        chevronStyles={{
          svg: {
            marginLeft: '-2px',
          },          
          line: {
            fill: 'transparent',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.8px',
            stroke: 'white',
          }
        }}
      />
    </div>
  );
};

export default Lang;