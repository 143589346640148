import React from 'react';
import Card from 'components/Card';
import { GridContainer } from 'containers/FormLayout';
import useI18n from 'i18n/useI18n';
import { Input } from '@etiquette-ui/inputs-dropdowns';

const Details = ({ details, setDetails }) => {
  const { translate } = useI18n();
  return (
    <Card>
      <h3>{translate('Details')}</h3>
      <GridContainer>
        <Input
          labelProps={{label: translate('Permission Name (without spaces)')}}
          iconProps={{
            icon: 'title'
          }}
          inputProps={{ 
            value: details?.name, 
            onChange: (e) => setDetails('name', e.target.value),
            required: true
          }}
        />
        <Input
          labelProps={{label: translate('Description')}}
          iconProps={{
            icon: 'export',
            iconStyles: {
              line: {
                fill: 'none',
                strokeLinecap:'round',
                strokeLinejoin:'round',
                strokeWidth:'1.25px',
                stroke: '#000'
              }
            }
          }}
          inputProps={{ 
            value: details?.description, 
            onChange: (e) => setDetails('description', e.target.value),
            required: true
          }}
        />
      </GridContainer>
    </Card>
  );
};

export default Details;