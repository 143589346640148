import React, { useReducer, useEffect } from 'react';
import usePermissions from 'state/permissions';
import Form from '../../components/Form';
import useI18n from 'i18n/useI18n';
import Reducer, { initialState } from '../../Reducer';
import { useRouter } from 'providers/router';
import CreateContainer from 'pages/Consolidate/containers/CreateContainer';

const Create = () => {
  const { translate } = useI18n();
  const [permisssion, permissionDispatcher] = usePermissions();
  const [data, dispatcher] = useReducer(Reducer, initialState);
  const { route, canGoBack } = useRouter();

  useEffect(() => {
    permissionDispatcher.fetchStart();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, description, permissions } = data;
    const list = permissions.map((permission) => {
      const result = { ...permission };
      result.permission.name = name;
      result.permission.description = description;

      return result;
    });
    list.forEach((element) => {
      permissionDispatcher.createStart(element);
    });
  };

  return (
    <CreateContainer route={canGoBack() ? route.from : '/consolidate/permissions'} text={translate('Back to Permissions')}>
      <Form
        onSubmit={onSubmit}
        data={data}
        dispatcher={dispatcher}
        title={translate('New Permission')}
        loading={permisssion.fetching.state}
        buttonText={translate('Create Permission')}
      />
    </CreateContainer>
  );
};

export default Create;
