import React, { useState, useEffect } from 'react';
import Personal from 'components/EmployeeForm/sections/Personal';
import AccountAccess from 'components/EmployeeForm/sections/AccountAccess';
import Id from 'components/EmployeeForm/sections/Id';
import GroupSelect from 'pages/Consolidate/components/GroupSelect';
import Card from 'components/Card';
import { FormContainer } from 'containers/FormLayout';
import { TitleH2 } from 'components/Texts';
import { ACTIONS } from '../../Reducer';
import { FormSpacer } from 'containers/FormLayout';
import ShortAccess from 'components/EmployeeForm/sections/ShortAccess';
import GroupsDetails from './GroupsDetails';
import validate from './validate';
import { InvalidText } from 'components/Texts';
import useI18n from 'i18n/useI18n';
import FormErrorMessage from 'components/FormErrorMessage';

import { PrimaryButton } from '@etiquette-ui/buttons';

const Form = ({ onSubmit, dispatch, state, isEdit = false, title, buttonText }) => {
  const { translate } = useI18n();
  const [errors, setErrors] = useState({});

  const [permissionError, setPermissionError] = useState('');
  const [assignmentsError, setAssignmentsError] = useState('');

  const handlePersonalChange = (field, value) => {
    const newField = { [field]: value };
    dispatch(ACTIONS.PERSONAL_INFO, newField);
  };

  const validateAssingments = (assignments) => {
    const validations = assignments.map((assignment)=>{
      if(!!assignment.division && !!assignment.role)
        return true;

      return false;
    });
    return validations.every(item => item === true);
  }
  
  const verifyGroupsInfo = (groups) => {
    const validations = groups.map(group => {
      if(group.permission === null){
        setPermissionError(translate('Each group requires a permission type. Verify the info and try again.'));
        return false;
      }

      if(!group.assignments || group.assignments[0].division === null || group.assignments[0].role === null || !validateAssingments(group.assignments)){
        setAssignmentsError(translate('Each group requires a Division with its Role at least. Verify each Division has a Role assigned.'));
        return false;
      }

      return true;
    });

    return validations.every(item => item === true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(state);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    if(!verifyGroupsInfo(state.groups)){
      return;
    }
    setPermissionError('');
    setAssignmentsError('');
    onSubmit();
  };

  return (
    <Card>
      <FormContainer>
        <TitleH2 style={{ marginBottom: '20px' }}>{title}</TitleH2>
        <form onSubmit={handleSubmit} style={{ width: '90%' }}>
          <GroupSelect
            selectedValues={state.groups.map(({ element }) => element)}
            onChange={(value) => dispatch(ACTIONS.UPDATE_GROUP, value)}
            onRemove={(value) => dispatch(ACTIONS.UPDATE_GROUP, value)}
            error={errors.groups && state.groups.length===0 ? translate('You must assign at least one group') : undefined}
          />
          <FormSpacer />
          <Personal formData={state.personalInfo} onChangeForm={handlePersonalChange} errors={errors} />
          <FormSpacer />
          <AccountAccess formData={state.personalInfo} onChangeForm={handlePersonalChange} errors={errors} isEdit={isEdit} />
          <FormSpacer />
          <ShortAccess formData={state.personalInfo} onChangeForm={handlePersonalChange} isEdit={isEdit} />
          <FormSpacer />
          <Id formData={state.personalInfo} onChangeForm={handlePersonalChange} />
          <FormSpacer />
          <GroupsDetails formData={state} formDispatcher={dispatch} />
          {!!permissionError && (<FormErrorMessage message={permissionError}/>)}
          {!!assignmentsError && (<FormErrorMessage message={assignmentsError}/>)}
          <FormSpacer />
          {Object.keys(errors).length > 0 && (
            <>
              <InvalidText style={{ textAlign: 'center' }}>{translate('Errors found on Form')}</InvalidText>
              <FormSpacer />
            </>
          )}
          <PrimaryButton type={'submit'}>
            {buttonText}
          </PrimaryButton>
        </form>
      </FormContainer>
    </Card>
  );
};

export default Form;
