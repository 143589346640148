import Types from "./types";

export default class ProductsDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchStart = () => this.dispatch({ type: Types.FETCH_START, payload: {} });

    updateStart = (payload) => this.dispatch({ type: Types.UPDATE_START, payload });

    createStart = (payload) => this.dispatch({ type: Types.CREATE_START, payload });
    
    deleteStart = (payload) => this.dispatch({ type: Types.DELETE_START, payload });

    toggleActive = (payload) => this.dispatch({ type: Types.TOGGLE_ACTIVE, payload });

    resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}