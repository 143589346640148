import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router';
import Groups from './pages/Groups';
import Permissions from './pages/Permissions';
import Divisions from './pages/Divisions';
import Roles from './pages/Roles';
import Employees from './pages/Employees';
import useGroups from 'state/groups';
import MainLayout from 'containers/MainLayout';

const Consolidate = () => {
  const { path, url } = useRouteMatch();
  const [, dispatcher] = useGroups();

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  return (
    <MainLayout>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${url}/groups`} />
        </Route>
        <Route path={`${path}/groups`} component={Groups} />
        <Route path={`${path}/permissions`} component={Permissions} />
        <Route path={`${path}/divisions`} component={Divisions} />
        <Route path={`${path}/roles`} component={Roles} />
        <Route path={`${path}/employees`} component={Employees} />
      </Switch>
    </MainLayout>
  );
};

export default Consolidate;
