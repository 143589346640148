import React from 'react';
import styled from 'styled-components';

const Margin = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
`;

const HomeContainer = ({ children, className }) => {
  return (
    <Margin className={className}>
      <Container>{children}</Container>
    </Margin>
  );
};

export default HomeContainer;
