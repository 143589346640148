import Types from "./types";

export default class ProductsDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchStart = () => this.dispatch({ type: Types.FETCH_START, payload: {} });

    fetchAvailable = () => this.dispatch({ type: Types.FETCH_AVAILABLE_START, payload: {} });

    resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}