import styled from "styled-components";
import colors from 'resources/constants/colors';

export const Row = styled.div`
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
`;
export const SelectContainer = styled.div`
  min-width: 240px;
  max-width: 48%;
  flex: 0 0 48%;
`;

export const Container = styled.div`
  max-width: 250px;
  flex: 0 0 250px;
  min-width: 200px;
  margin-top: 15px;
  position: relative;
  padding: 15px 0px 5px;
  border-radius: 10px;
  background-color: ${colors.lightBlue};
`;
export const ProfileContainer = styled.div`
  width: 100%;
`;
export const Title = styled.h3`
  font-size: 15px;
  font-weight: 500;
  padding-left: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
`;
export const RolesContainer = styled.ul`
  width: 100%;
  padding-left: 20px;
  margin-top: 0px;
`;
export const RoleItem = styled.li`
  padding: 3px 0px;
  list-style: none;
  font-size: 14px;
  font-weight: 400;

  display: flex;
  align-items: center;
`;
export const DeleteEmployee = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 0;
  height: 25px;
  width: 25px;
`;
export const DeleteEmployeeIcon = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;
export const DeleteRoleItem = styled.span`
  width: 17px;
  height: 17px;
  margin-left: 10px;
`;
export const DeleteRoleIcon = styled.img`
  width: 17px;
  height: 17px;
  cursor: pointer;
`;