import MainHeader from 'components/MainHeader';
import React from 'react';
import styled from 'styled-components';

const AppContainer = styled.div`
  display: grid;
  grid-template-rows: 90px minmax(0, 1fr);
  height: 100vh;
  width: 100vw;
`;

const Main = styled.main`
    height: 100%;
`;

const MainLayout = ({ children }) => {
  return (
    <AppContainer>
      <MainHeader />
      <Main>{children}</Main>
    </AppContainer>
  );
};

export default MainLayout;
