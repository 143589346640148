import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router';
import Profile from './pages/Profile';
import Permissions from './pages/Permissions';
import Divisions from './pages/Divisions';
import Roles from './pages/Roles';
import Employees from './pages/Employees';
import useUsers from 'state/users';

const Management = () => {
  const { path, url } = useRouteMatch();
  const [, usersDispatcher] = useUsers();

  useEffect(() => {
    usersDispatcher.fetchStart();
  }, []);
  return (
    <Switch>
      <Route exact path={path}><Redirect to={`${url}/profile`} /></Route>
      <Route path={`${path}/profile`} component={Profile} />
      <Route path={`${path}/permissions`} component={Permissions} />
      <Route path={`${path}/divisions`} component={Divisions} />
      <Route path={`${path}/roles`} component={Roles} />
      <Route path={`${path}/employees`} component={Employees} />
    </Switch>
  );
};

export default Management;
