import styled from "styled-components";
import { Card as BaseCard } from '@etiquette-ui/misc';
import colors from "resources/constants/colors";
import { InvalidText } from 'components/Texts';

export const Container = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: ${colors.backgroundLightGrey};
  border-radius: 10px;
  padding: 14px;
  position: relative;
  &.top {
    align-items: flex-start;
  }
`;

export const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  &.disabled {
    opacity: 0.5;
  }
`;

export const Icon = styled.img`
  width: 28px;
  height: 28px;
`;

export const InputContainer = styled.div`
  height: 100%;
  position: relative;
  flex: 1;

  &.up {
    label {
      top: -9px; font-size: 0.75rem;
    }
  }

  &.disabled {
    label {
      color: ${colors.textMain};
      opacity: 0.5;
    }
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  padding-left: 0px;
  color: ${colors.accept};
  pointer-events: none;
  left: 0;
  top: 5px;
  transition: top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1), font-size 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
`;

export const InvalidAlert = styled(InvalidText)`
  position: absolute;
  bottom: -32px;
`;

export const Card = styled(BaseCard)`
  margin: 0;
  border-radius: 8px;
  padding: 15px 20px;
  position: relative;
`;

export const InputsContainer = styled.div`
    display: grid;
    grid-template-columns: 5rem 1rem 5rem 3.25rem;
    grid-gap: 5px;
`;

export const Value = styled.p`
  margin: 0;
  width: 100%;
  font-size: 1rem;
  padding-left: 18px;
  color: ${colors.textMain};
  padding-top: 12px;
  user-select: none;
  &.disabled {
    color: ${colors.textMain};
    opacity: 0.5;
  }
`;

export const Input = styled.input`
  width: 5rem;
  height: 5rem;
  font-size: 3rem;
  color: #0f191b;
  background-color: ${colors.backgroundLightGrey};
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 5px;
  text-align: center;
  user-select: none;
  transition: padding-top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
  }
`;

export const SeparatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-self: center;
`;

export const Separator = styled.p`
    font-size: 3.5rem;
    text-align: center;
    margin: 0;
    user-select: none;
`;

export const TimeSelectorContainer = styled.div`
    display: grid;
    background-color: ${colors.textMain};
    grid-template-rows: 1fr 1fr;
    grid-gap: 2px;
    border-radius: 20px;
    margin-left: 5px;
`;

export const TimeContainer = styled.div`
    background-color: ${({isActive}) => isActive ? colors.backgroundLightBlue : colors.backgroundLightGrey};
    border-radius: ${({isBottom})=> isBottom ? '0px 0px 8px 8px' : '8px 8px 0px 0px'};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
        margin: 0;
        font-size: 1rem;
        text-align: center;
        user-select: none;
    }
`;

const TargetContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const MenuContainer = styled.div`
  border-radius: 4px;
  position: absolute;
  z-index: 3;
  width: 100%;
`;
const Blanket = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 2;
`;

export const Dropdown = ({ className, target, children, isOpen, toggle }) => (
  <div className={className} style={{ position: 'relative', height: '100%' }}>
    <TargetContainer className="dropdown-target-container" onClick={toggle}>
      {target}
    </TargetContainer>
    {isOpen ? <MenuContainer className="dropdown-menu-container">{children}</MenuContainer> : null}
    {isOpen ? <Blanket className="dropdown-menu-blanket" onClick={toggle} /> : null}
  </div>
);