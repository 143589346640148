import styled from "styled-components";

export const FlexContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 3.8fr 1fr;
  grid-gap: 32px;
  align-items: center;
  a {
    text-decoration: none;
  }
`;

export const Learn = styled.div`
  display: flex;
  color: #388cff;
  .join {
    pointer-events: auto;
    cursor: pointer;
    display: flex;
    padding-bottom: 15px;
  }
  img {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
`;

export const Contact = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  h4 {
    pointer-events: auto;
    cursor: pointer;
    color: #388cff;
    margin-left: 24px;
  }
`;

export const CarouselContainer = styled.div`
  overflow-x: auto;
  padding: 20px 0;
`;

export const CarouselApp = styled.div`
  display: flex;
`;

export const Main = styled.div`
  width: 100%;
`;
