import React from 'react';
import styled from 'styled-components';
import colors from 'resources/constants/colors';
import { Spinner } from '@etiquette-ui/misc';

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
`;

const IconButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  ${({disabled})=>disabled ? 'cursor: default; pointer-events: none; opacity: 0.2;' : ''}
  img {
    height: 20px;
  }
`;

const SpinnerContainer = styled.div`
  width: 25px;
`;

const Actions = ({ loading, actions, style, spinnerBackground }) => {
  return (
    <ActionsContainer style={style}>
      <>
        <SpinnerContainer>
          {!!loading && 
            <Spinner 
              style={{
                '--width': '35px',
                '--margin': '0 5px',
                '--bg': `${spinnerBackground ?? 'white'}`,
                '--color': `${colors.backgroundDarkBlue}`
              }}
            />
          }
        </SpinnerContainer>
        {actions.map(({ event, src, child, disabled }, i) => {
          return (
            <div key={i}>
              {!!src ? (
                <IconButton type="button" disabled={disabled} onClick={disabled ? ()=>{} : event}>
                  <img src={src} alt="" />
                </IconButton>
              ) : (
                child
              )}
            </div>
          );
        })}
      </>
    </ActionsContainer>
  );
};

export default Actions;
