const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.NAME:
    case ACTIONS.DESCRIPTION: {
      const data = { ...state };
      data[action.type] = action.payload;
      return { ...data };
    }
    case ACTIONS.HEADS:
    case ACTIONS.USERS: {
      const userMap = state[action.type];
      const {users, groupId} = action.payload;

      userMap.set(groupId, users);
      return { ...state, [action.type]: userMap };
    }
    case ACTIONS.UPDATE_GROUP: {
      const groups = [...state.groups];
      const heads = new Map(state.heads);
      const users = new Map(state.users);
      if (groups.map(({ value }) => value).includes(action.payload.value)) {
        const index = groups.map(({ value }) => value).indexOf(action.payload.value);
        groups.splice(index, 1);
        heads.delete(action.payload.value);
        users.delete(action.payload.value);
      } else {
        groups.push(action.payload);
        heads.set(action.payload.value, []);
        users.set(action.payload.value, []);
      }
      return { ...state, groups, heads, users };
    }
    case ACTIONS.REMOVE_GROUP: {
      const groups = [...state.groups];
      const heads = new Map(state.heads);
      const users = new Map(state.users);
      const index = groups.indexOf(action.payload);
      if (index !== null && index !== undefined) {
        groups.splice(index, 1);
        heads.delete(action.payload.value);
        users.delete(action.payload.value);
      }
      return { ...state, groups, heads, users };
    }
    case ACTIONS.INIT:
      return { ...action.payload };

    default:
      throw new Error(`Action type not valid ${action.type}`);
  }
};

export const INITIAL_STATE = {
  id: '-999',
  name: '',
  description: '',
  groups: [],
  heads: new Map(),
  users: new Map(),
};

export const ACTIONS = {
  INIT: 'init',
  NAME: 'name',
  DESCRIPTION: 'description',
  ADD_GROUP: 'add_group',
  UPDATE_GROUP: 'update_group',
  REMOVE_GROUP: 'remove_group',
  HEADS: 'heads',
  USERS: 'users',
};

export default reducer;
