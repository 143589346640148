export const assignmentsReducer = (assignments) => {
  const reducedAssignments = new Map();
  for (const asgnmt of assignments) {
    const key = !!asgnmt.division ? asgnmt.division.value : 'default';
    if (reducedAssignments.has(key)) {
      const roles = reducedAssignments.get(key);
      if (!roles.includes(asgnmt.role.value)) reducedAssignments.set(key, [...roles, asgnmt.role.value]);
    } else {
      reducedAssignments.set(key, [asgnmt.role.value]);
    }
  }

  const formattedAssignments = [];
  reducedAssignments.forEach((value, key) => {
    formattedAssignments.push({ roles: value, isHead: 0, division: key === 'default' ? null : key });
  });

  return formattedAssignments;
};

export function parseAssignments(assignments) {
  const tree = Object.create(null);

  assignments.forEach(assignment => {
    const { groupId, role, division } = assignment;
    if (!tree[groupId]) tree[groupId] = {};
    if (!tree[groupId][division || ""]) tree[groupId][division || ""] = [];

    !!role && tree[groupId][division || ""].push(role)
  })


  const result = [];

  for (const groupId in tree) {
    for (const division in tree[groupId]) {
      const roles = [...new Set(tree[groupId][division])];
      const element = { groupId: +groupId };
      if (!!division) element.division = +division;
      if (roles.length) element.roles = roles;
      result.push(element)
    }
  }

  return result;
}


export function unParseAssignments(assignments) {
  const result = {};

  for (const groupId in assignments) {
    assignments[groupId].forEach(({ division, roles }) => {
      if (!result[groupId]) result[groupId] = [];

      if (roles.length) {
        result[groupId].push(...roles.map(role => ({ groupId: +groupId, division, role })))
      } else {
        return result[groupId].push({ division, groupId: +groupId })
      }
    })
  }
  
  return result;
}

export function getConsolidateAssignments(assignments) {

  let globalAssignments = [];
  for(const groupId in assignments){
    for(const assignment of assignments[groupId]){
      let object = {groupId: +groupId};
      if(!!assignment.division) object.division = assignment.division;
      if(!!assignment.isHead && assignment.isHead > 0) object.isHead = assignment.isHead;
      if(assignment.roles && assignment.roles.length > 0) object.roles = assignment.roles;
      globalAssignments.push(object);
    }
  }

  return globalAssignments;
}

export function getGroupedAssignments(assignments) {
  let groupedAssignments = {};
  for(const assignment of assignments){
    const { groupId, roles, division, isHead } = assignment;
    if(groupedAssignments[groupId] === undefined) groupedAssignments[groupId] = [];

    let object = {};
    object.division = !!division ? division : null;
    if(!!isHead && isHead > 0) object.isHead = isHead;
    object.roles = roles && roles.length > 0 ? roles : null;

    if(Object.keys(object).length > 0 ) groupedAssignments[groupId].push(object);
  }

  return groupedAssignments;
}

export function getGroupDivisionsAssigned(assingments, groupId){
  return assingments[groupId].map((assingment) => assingment.division);
        
}

export function getGroupDivisionRolesAssigned(assingments, groupId){
  let divisions = {};
  for(const assingment of assingments[groupId]){
    divisions[assingment.division] = assingment.roles;
  }
  return divisions;
}

export function addRoleToDivision(employee, roleId, divisionId, groupId) {

  if(employee.assignments === null) {
    employee.assignments = {};
    employee.assignments[groupId] = [{
      isHead: 0,
      division: divisionId,
      roles: [roleId]
    }];
    return employee;
  }

  if(employee.assignments[groupId] === undefined) {   
    employee.assignments[groupId] = [{
      isHead: 0,
      division: divisionId,
      roles: [roleId]
    }];
    return employee;
  }

  let assignment = employee.assignments[groupId].find(asgmt => asgmt.division === divisionId);
  
  if(!!assignment){
    if(assignment.roles === null) assignment[0].roles = [];

    assignment.roles.push(roleId);

    employee.assignments[groupId] = employee.assignments[groupId].map(asgnmnt => {
      if(asgnmnt.division === divisionId){  
        return assignment;
      } 
      return asgnmnt;
    });

  } else {
    assignment = {
      isHead: 0,
      division: divisionId,
      roles: [roleId]
    };
    employee.assignments[groupId].push(assignment);
  }

  return employee;
}

export function removeRoleToDivision(employee, roleId, divisionId, groupId){
  const division = employee.assignments[groupId].filter(assignment => assignment.division === divisionId);
  const remainingRoles = division[0].roles.filter(role => role !== roleId);

  employee.assignments[groupId] = employee.assignments[groupId].map( assignment => {
    if( assignment.division === divisionId ){
      assignment.roles = remainingRoles;
    }
    return assignment
  });

  return employee;
}