import React from 'react';
import styled from 'styled-components';
import Card from 'components/Card';
import useI18n from 'i18n/useI18n';
import { PUBLIC_URL } from 'resources/constants/urls';

const RequestAppBox = ({ appListSelected = [] }) => {
  const { translate } = useI18n();
  return (
    <CustomCard>
      <Container>
        <div className="container__title">
          <p>{translate("Your Request")}</p>
        </div>
        {!!appListSelected &&
          appListSelected.map((item, idx) => (
            <AppName key={"appbox-"+idx}>
              <img src={`${PUBLIC_URL}/logos/${item.identifier}.svg`} alt="" />
              <p>{item.name}</p>
            </AppName>
          ))}
      </Container>
    </CustomCard>
  );
}


const CustomCard = styled(Card)`
  padding: 16px 32px;
  height: 100%;
  box-sizing: border-box;
`;

const Container = styled.div`
  .container__title {
    margin-bottom: 24px;
    p {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
    }
  }
`;

const AppName = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  img {
    width: 40px;
    height: 40px;
  }
  p {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    padding-left: 16px;
  }
`;
export default RequestAppBox;
