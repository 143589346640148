import PERSONAL_INFO_KEYS from "./keys";

const DEFAULT_PERSONAL_INFO = {
    [PERSONAL_INFO_KEYS.NAME]: '',
    [PERSONAL_INFO_KEYS.LAST_NAME]: '',
    [PERSONAL_INFO_KEYS.SECOND_NAME]: '',
    [PERSONAL_INFO_KEYS.USER_POS_ID]: '',
    [PERSONAL_INFO_KEYS.EMAIL]: '',
    [PERSONAL_INFO_KEYS.PASS]: '',
    [PERSONAL_INFO_KEYS.IMAGE]: '',
    [PERSONAL_INFO_KEYS.IS_ACTIVE]: 1,
    [PERSONAL_INFO_KEYS.SHORT_NAME]: '',
    [PERSONAL_INFO_KEYS.SHORT_PASSWORD]: '',
    [PERSONAL_INFO_KEYS.USER_TYPE]: 'regular',
};

export default DEFAULT_PERSONAL_INFO;