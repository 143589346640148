import React, { useEffect } from 'react';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import TabsComponent from 'components/Tabs';
import routes from 'pages/Consolidate/routes';
import IconLink from 'components/IconLink';
import { useSearch } from 'providers/search';
import GroupFilter from '../components/GroupFilter';

const ListContainer = styled.div`
  padding: 16px;
  height: 100%;
  display: grid;
  grid-template-rows: 36px 65px minmax(0, 1fr);
`;

const Content = styled.section`
  width: 90%;
  display: flex;
`;

const Tabs = styled(TabsComponent)`
  align-self: center;
  max-width: 90%;
`;

const LayoutTable = ({ location, children }) => {
  const { translate } = useI18n();
  const { setVisible } = useSearch();

  useEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, []);

  return (
    <ListContainer>
      <IconLink to="/">{translate('Home')}</IconLink>
      <Tabs routes={routes(location)} pathIndex={2} current={location.pathname} >
        <GroupFilter/>
      </Tabs>
      <Content>{children}</Content>
    </ListContainer>
  );
};

export default LayoutTable;
