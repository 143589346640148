import React from 'react';
import useI18n from 'i18n/useI18n.js';
import { MultiSelect } from '@etiquette-ui/inputs-dropdowns';

const formatOption = (selectedValues, CustomOption) => (data) => {
  const Component = CustomOption;
  return <Component selected={selectedValues.map(({ value }) => value).includes(data.value)} data={data} />;
};

const MultipleSelect = ({ options, onChange, title, error, selectedValues, CustomOption = undefined }) => {
  const { translate } = useI18n();

  return (    
    <MultiSelect
      labelProps={{ label: !!title && title !== ''  ? title : translate('Search') }}
      errorProps={{ error: error }}
      leadingIconProps={{ icon: 'search' }}
      selectProps={{ 
        onChange: onChange, 
        options: options,
        placeholder: '',
        label: selectedValues.length > 0 ? selectedValues.map(val=>val.label).join(',') : '',
        isSearchable: true,        
        backspaceRemovesValue: false,
        tabSelectsValue: false,
        closeMenuOnSelect: false,
        formatOptionLabel: !!CustomOption ? formatOption(selectedValues, CustomOption) : undefined
      }} 
      selectedValues={selectedValues}
    />
  );
};

export default MultipleSelect;
