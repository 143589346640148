import React, {useMemo} from 'react';
import UserItem from 'components/UserItem';
import useDivisions from 'state/divisions';
import CloseSVG from 'resources/img/close-icon-dark.svg';
import { getGroupDivisionRolesAssigned, removeRoleToDivision } from 'utils/employee';

import {
  Container,
  ProfileContainer,
  Title,
  RolesContainer,
  RoleItem,
  DeleteEmployee,
  DeleteEmployeeIcon,
  DeleteRoleItem,
  DeleteRoleIcon,} from '../styles';  

const getDivisionsList = (assignments, divisions, roleId) => {
  if(!!assignments && Object.keys(assignments).length <= 0 ) return [];     

  let names = [];
  for(const divisionId in assignments) {   
    if(!!+divisionId && !!assignments[divisionId] && assignments[divisionId].includes(roleId)){        
      const division  = divisions.find( division => division.id === +divisionId);
      if(!!division) names.push({name: division.name, id: division.id});
    }
  };
  return names;
}

const EditDivisions = ({employee, formData, handleFormChange, groupId}) => {
  
  const [divisions] = useDivisions();

  const assignments = useMemo(()=>{
    if(!employee || !groupId) return {}
    return getGroupDivisionRolesAssigned(employee.assignments, groupId);
  },[employee, formData.users.get(groupId)]);

  const divisionNamesList = useMemo(()=>{
    if(!assignments) return [];
    return getDivisionsList(assignments, divisions.list, formData.id);
  },[assignments, formData.users.get(groupId)]);

  const deleteUserFromDivision = () => {
    const remainingEmployees = formData.users.get(groupId).filter(user => user.value !== employee.value);
    handleFormChange(remainingEmployees);
  }

  const deleteRoleFromDivision = (id) => {
    const editedEmployee = removeRoleToDivision(employee, formData.id, id , groupId);
    
    let newEmployees = formData.users.get(groupId).map(employeeItem => {
      if( employeeItem.value === employee.id ){
        return ({
          value: editedEmployee.id,
          label: editedEmployee.name,
          ...editedEmployee
        });
      }
      return employeeItem;
    });
    handleFormChange(newEmployees);
  }
  

  return (
    <Container>
      <DeleteEmployee onClick={deleteUserFromDivision}>
        <DeleteEmployeeIcon src={CloseSVG} alt={'remove-employee'}/>
      </DeleteEmployee>
      <ProfileContainer>
        <UserItem name={`${employee.label} ${employee?.lastName ?? ''}`} image={employee?.image} />
      </ProfileContainer>
      {!!divisionNamesList && (<>
        <Title>Divisions</Title>
        <RolesContainer>
          {divisionNamesList.map( (division,i) => 
            (<RoleItem key={division.id}>
              {division.name} 
              <DeleteRoleItem onClick={()=>deleteRoleFromDivision(division.id)}>
                <DeleteRoleIcon src={CloseSVG} alt={'remove-Role'}/>
              </DeleteRoleItem>
            </RoleItem>)
          )}
        </RolesContainer>
      </>)}  
    </Container>
  )
}
export default EditDivisions;
