import React, {useState} from 'react';
import useI18n from 'i18n/useI18n';
import useGroups from 'state/groups';
import styled from 'styled-components';
import { useHistory } from "react-router";
import { RadioButton } from '@etiquette-ui/selectors';
import { BaseDialog } from '@etiquette-ui/alert-dialog';
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 24px/29px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
`;

const List = styled.ul`
  padding-left: 0px;
  margin: 15px auto 0px auto;
  li {
    list-style: none;
    margin-bottom: 15px;
  }
`;

const ButtonsContainer = styled.div`
  gap: 30px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: center;

  .ButtonCover {
    min-width: 150px;
    font-size: 14px;    

    b {
      font-weight: 600;
      padding: 5px 0px;
    }
  }
`;

const getGroupValue = (groupId, replaceStr) => {
  return ({
    groupId: +groupId, 
    targetUrl: replaceStr.replace(':groupId',`${+groupId}`)
  });
}

const Dialog = ({open, onClose, routeString, groupId}) => {

  const [groups] = useGroups();
  const history = useHistory();
  const {translate} = useI18n();
  const [selectedGroup, setSelectedGroup] = useState(getGroupValue(groupId, routeString));

  const onChange = (id) => {
    setSelectedGroup(getGroupValue(id, routeString));
  }

  const onClick = () => {
    if(selectedGroup.groupId === +groupId) {
      onClose()
      return;
    };
    history.push(selectedGroup.targetUrl);
    onClose();
  }

  return (
    <BaseDialog open={open} onClose={onClose} isResponsive={false}>
      <Title>{translate('Change Group')}</Title>
      <List>
        {!!groups && groups.list.map( ({id, name}) => (
          <li key={id}>
            <RadioButton checked={selectedGroup.groupId === +id} onClick={()=>onChange(id)}>{name}</RadioButton>
          </li>
        ))}
      </List>
        <ButtonsContainer>
          <div className='ButtonCover'>
            <SecondaryButton onClick={onClose}><b>{translate('Cancel')}</b></SecondaryButton>  
          </div>
          <div className='ButtonCover'>
            <PrimaryButton onClick={onClick}><b>{translate('Change')}</b></PrimaryButton>
          </div>         
      </ButtonsContainer>
    </BaseDialog>
  )
}
export default Dialog;
