import React from 'react';
import styled from 'styled-components';
import { TextSpan } from 'components/DynamicTable/DynamicTableComponents/styles';

const AttributesHeader = () =>
(<Container>
    <Header>Restaurant</Header>
    <Header>Division/Roles</Header>
    <Header>Permissions</Header>
</Container>)

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    justify-items: center;
`

const Header = styled(TextSpan)`
    font-weight: 700;
    font-size: 1rem;
    width: 100%;
    text-align : left;
    margin: 0;
    padding: 0;
`

export default AttributesHeader
