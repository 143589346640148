import React from 'react';
import { FormSpacer, CustomContainer } from 'containers/FormLayout';
import useI18n from 'i18n/useI18n';
import { useMemo } from 'react';
import styled from 'styled-components';
import { TEXT_PLACEHOLDER, WRONG_DARK  } from '@etiquette-ui/colors';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';

const SelectContainer = styled.div`
  pointer-events: ${ ({isActive}) => isActive ? 'all' : 'none'};
  opacity: ${ ({isActive}) => isActive ? '1' : '.7'};
  position: relative;
`;

const ValidDivisionContainer = styled.div`
  border: ${ ({isValid}) => isValid ? '0px' : `1px solid ${WRONG_DARK }`};
  border-radius: 10px;
`;

const Placeholder = styled.div`  
  padding: 2px 5px;
  font-size: 12px;
  color: ${TEXT_PLACEHOLDER};
  font-weight: 500;

`;

const Assignment = ({divisions, roles, formData, asgnmt, index, handleDivisionChange, handleRoleChange}) => {
  const { translate } = useI18n();

  const getFreeOptions = () => {
    if(!!asgnmt.division) {
      const divisionId = asgnmt.division.value;
      let rolesIds = formData.assignments.filter((assignment)=>{
        if(!!assignment.division && assignment.division.value === divisionId && !!assignment.role) return assignment;
      });
      rolesIds = rolesIds.map(item => item.role.value);
      const rolesList = roles.filter((role) => {
        if(!rolesIds.includes(role.value)) return role;
      });
      return rolesList;
    }

    return []; 
  }

  const isAValidDivision = () => {
    if(asgnmt.division === null && asgnmt.role !== null) return false;   
    return true;
  }

  const freeRoles = useMemo(getFreeOptions, [asgnmt.division]);
  
  return (
    <>
      <CustomContainer columns="2">
        <ValidDivisionContainer isValid={isAValidDivision()}>
          <Dropdown
            labelProps={{
              label: translate('Assign Division')
            }} 
            leadingIconProps={{
              icon: 'title'
            }} 
            selectProps={{
              options: divisions,
              value: asgnmt.division,
              onChange: (value) => handleDivisionChange(index, value),
              placeholder:  translate('Choose one')
            }}            
          />
        </ValidDivisionContainer>
      </CustomContainer>
      <FormSpacer />
      <CustomContainer columns="2">
        <SelectContainer isActive={freeRoles.length > 0 ? true : false}>
          <Dropdown
            labelProps={{
              label: translate('Assign Role')
            }} 
            leadingIconProps={{
              icon: 'role'
            }} 
            selectProps={{
              options: freeRoles,
              value: asgnmt.role,
              onChange: (value) => handleRoleChange(index, value),
              placeholder: translate('Choose one')
            }} 
          />
          {freeRoles.length === 0 && (
            <Placeholder>{translate('Choose a Division first')}</Placeholder>
          )}          
        </SelectContainer>
      </CustomContainer>
    </>
  )
}
export default Assignment;