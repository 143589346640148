import React from "react";
import styled from "styled-components";
import { IMAGES_URL } from 'resources/constants/urls'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const User = () => {

  const auth = JSON.parse(localStorage.getItem("auth"))
  return (
    <Container>
      <Img style={{ maxHeight: "3rem" }} src={ `${IMAGES_URL}${auth.image}` } alt={'user'} />
      <span style={{ margin: "10px" }}>{auth.name}</span>
    </Container>
  );
};

const Img = styled.img`
  max-height: 3rem;
  border-radius: 50%;
`

export default User;
