import React from 'react';
import Card from 'components/Card';
import { TitleH3 } from 'components/Texts';
import { FormSpacer, GridContainer } from 'containers/FormLayout';
import useI18n from 'i18n/useI18n';
import PERSONAL_INFO_KEYS from '../keys';
import { Input } from '@etiquette-ui/inputs-dropdowns';

const Id = ({ formData, onChangeForm }) => {
  const { translate } = useI18n();

  return (
    <Card>
      <TitleH3>{translate('ID Information')}</TitleH3>
      <FormSpacer />
      <GridContainer>
        <Input
          labelProps={{label: translate('POS ID')}}
          iconProps={{
            icon: 'pos-id'
          }}
          inputProps={{
            value: formData[PERSONAL_INFO_KEYS.USER_POS_ID],
            onChange: (e) => onChangeForm(PERSONAL_INFO_KEYS.USER_POS_ID, e.target.value),
            type: 'number',
          }}
        />
      </GridContainer>
    </Card>
  );
};

export default Id;
