import React from 'react';
import { Text } from '@etiquette-ui/dynamic-table-components';
import RowActions from './cells/Actions';
import AppList from 'components/Table/AppList';
import RowContainer from 'components/Table/RowContainer';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 3,
            header: ()=><></>,
            body: ({ item }) => <Text text={item.name} style={{ marginLeft: '16px' }} />,
        },
        {
            flex: 2,
            header: ()=><></>,
            body: ({ item }) => <Text text={item.employees} />,
        },
        {
            flex: 4,
            header: ()=><></>,
            body: ({ item }) => <AppList idList={item.apps ? item.apps.map(({id})=>id) : []}/>,
        },
        {
            flex: 2,
            header: ()=><></>,
            body: RowActions,
        },
    ],
};

export default structure;