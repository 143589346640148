import styled from "styled-components";
import { WRONG } from "@etiquette-ui/colors";


export const TitleH1 = styled.h1`
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
`;

export const TitleH2 = styled.h2`
    margin: 0;
    font-weight: 600;
    font-size: 1.5rem;
`;

export const TitleH3 = styled.h3`
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
`;

export const TitleH4 = styled.h4`
    margin: 0;
    font-weight: 600;
    font-size: 0.875rem;
`;

export const Body = styled.p`
    margin: 0;
    font-size: 0.875rem;
`;

export const InvalidText = styled.p`
  margin: 0;
  font-size: 0.75rem;
  color: ${WRONG};
`;