import React, { useReducer, useEffect } from 'react';
import useUsers from 'state/users';
import Form from '../components/Form';
import useI18n from 'i18n/useI18n';
import { useParams } from 'react-router-dom';
import Reducer, { ACTIONS, initialState } from '../Reducer';
import { useRouter } from 'providers/router';
import { parseAssignments } from 'utils/employee';
import FormHeader from 'pages/Group/components/FormHeader';
import FormScrollableContainer from '../../../../../containers/FormScrollableContainer';
import { FormSpacer } from 'containers/FormLayout';
import { formatObjectForSelect } from 'utils';
import UserItem from 'components/UserItem';
import useDivisions from 'state/divisions';
import useRoles from 'state/roles';
import usePermissions from 'state/permissions';
import toFormState from './utils/toFormState';
import styled from 'styled-components';

const OptionContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const formatOption = (data) => (
  <OptionContainer>
    <UserItem name={`${data?.label} ${data?.secondName || ''} ${data?.lastName || ''}`} image={data?.image} />
  </OptionContainer>
);

const Create = () => {
  const { translate } = useI18n();
  const [data, dispatcher] = useUsers();
  const [divisions, divisionsDispatcher, divisionUtils] = useDivisions();
  const [roles, rolesDispatcher, roleUtils] = useRoles();
  const [permissions, permissionsDispatcher, permissionUtils] = usePermissions();
  const { route, canGoBack } = useRouter();
  const { groupId } = useParams();
  const [formData, formDispatcher] = useReducer(Reducer, groupId, initialState);
  const dispatch = (type, payload) => formDispatcher({ type, payload });

  useEffect(() => {
    dispatcher.fetchStart();
    divisionsDispatcher.fetchStart(groupId);
    rolesDispatcher.fetchStart(groupId);
    permissionsDispatcher.fetchStart(groupId);
  }, []);

  const onSubmit = () => {
    const newData = { ...formData.personalInfo };

    newData.image = formData.personalInfo.image?.file ?? null;
    newData.permissions = formData.permission?.value ? [formData.permission.value] : [];

    newData.assignments = [
      ...parseAssignments(
        formData.assignments
          .filter(({ division, role }) => !!division || !!role)
          .map(({ groupId, division, role }) => ({ groupId, division: division?.value ?? null, role: role?.value ?? null }))
      ),
      { groupId: +groupId },
    ];

    dispatcher.createStart({ ...newData });
  };

  const importData = (user) => {
    if (divisions.fetching.state || roles.fetching.state || permissions.fetching.state) return;

    const employee = { ...user };
    delete employee.value;
    delete employee.label;
    delete employee.image;
    employee.name = user.label;
    
    dispatch(ACTIONS.INIT, toFormState(employee, groupId, permissionUtils, divisionUtils, roleUtils));
  }

  return (
    <FormScrollableContainer>
      <FormHeader
        route={canGoBack() ? route.from : `/${groupId}/management/employees`}
        routeText={translate('Back to Employees')}
        importOptions={data.list.map((role) => formatObjectForSelect(role))}
        importData={importData}
        importFormatOption={formatOption}
      />
      <Form
        onSubmit={onSubmit}
        formData={formData}
        formDispatcher={dispatch}
        title={translate('New Employee')}
        loading={data.fetching.state}
        buttonText={translate('Create Employee')}
      />
      <FormSpacer />
    </FormScrollableContainer>
  );
};

export default Create;
