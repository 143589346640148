import React from 'react';
import styled from 'styled-components';
import ok from 'resources/img/ok-icon.svg';
import RequestAppBox from './RequestAppBox';
import useI18n from 'i18n/useI18n';


const RequestApps = ({ appListSelected = [] }) => {
  const { translate } = useI18n();
  return (
    <Main>
      <section className='main__farewellSection'>
        <FarewellTitle><p>{translate("Thanks")}</p></FarewellTitle>
        <FarewellSubtitle><p>{translate("Request Received")}</p></FarewellSubtitle>
        <FarewellImg><img src={ok} alt="" /></FarewellImg>
        <FarewellFooter>
          <p>{translate("Our Customer")}</p>
        </FarewellFooter>
      </section>
      <section className='main__requestSection'>
        <RequestAppBox appListSelected={appListSelected} />
      </section>
    </Main>
  )
}


const Main = styled.div`
  display: flex;
  margin-bottom: 40px;
  .main__farewellSection {
    width: 50%;
    padding: 0 8%;
    box-sizing: border-box;
  }
  .main__requestSection {
    width: 50%;
    padding-right: 5%;
    padding-left: 1.4%;
  }
`;

const Farewell = styled.div`
  margin-bottom: 32px;
  p {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
`;

const FarewellTitle = styled(Farewell)`
  margin-bottom: 24px;
  p {
    font-size: 1.5rem;
  }
`;

const FarewellSubtitle = styled(Farewell)`
  p {
    font-weight: 600;
  }
`;

const FarewellImg = styled(Farewell)`
  display: flex;
  justify-content: center;
  img {
    width: 90px;
    height: 90px;
  }
`;

const FarewellFooter = styled(Farewell)`
  margin-bottom: 0;
  p {
    font-size: 0.875rem;
  }
`;

export default RequestApps;
