import React, { useEffect } from "react";
import Card from "components/Card";
import AddPayment from "./AddPayment";
import { FormSpacer } from "containers/FormLayout";
import Movement from "./Movement";
import useNomenclators from "state/nomenclators";
import { TextArea } from "@etiquette-ui/inputs-dropdowns";

import { P } from "@etiquette-ui/typography";

import styled from "styled-components";
import useI18n from "i18n/useI18n";
import { TitleH3 } from "components/Texts";

const Payments = ({ comment, movements, dispatcher }) => {
  const { translate } = useI18n();
  const [{ paymentMethods }, nomenclatorDispatcher] = useNomenclators();

  useEffect(() => {
    nomenclatorDispatcher.fetchPaymentMethods();
  }, []);

  const addMovement = () => {
    const movementBase = {
      paymentMethod: null,
      paymentMethodName: null,
      food: null,
      drink: null,
      iva: null,
    };

    dispatcher({ type: 'addMovement', payload: movementBase })
  };

  const update = (idx) => (movement) => dispatcher({ type: 'updateMovement', payload: { idx, movement } })

  return (
    <Card>
      <TitleH3>{translate("Payments")}</TitleH3>
      <FormSpacer />
      {movements.map((movement, idx) => (
        <Movement
          key={"movement-" + idx}
          data={movement}
          update={update(idx)}
          paymentMethods={paymentMethods}
        />
      ))}
      <Text>{translate("Press button")}</Text>
      <FormSpacer />
      <AddPayment onClick={addMovement} />
      <FormSpacer />
      <TextArea        
        inputProps={{
          value: comment, 
          onChange: (e) => dispatcher({ type: "comment", payload: e.target.value }), 
          required: true, type: "text-area",
          rows: 3
        }}
        iconProps={{
          icon: 'align-left'
        }}
        labelProps={{
          label: translate("Add comments")
        }}
      />
    </Card>
  );
};

const Text = styled(P)`
  text-align: center;
`;

export default Payments;
