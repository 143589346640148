import React, {useMemo} from 'react';
import UserItem from 'components/UserItem';
import useRoles from 'state/roles';
import CloseSVG from 'resources/img/close-icon-dark.svg';
import { getGroupDivisionRolesAssigned, removeRoleToDivision } from 'utils/employee';

import {
  Container,
  ProfileContainer,
  Title,
  RolesContainer,
  RoleItem,
  DeleteEmployee,
  DeleteEmployeeIcon,
  DeleteRoleItem,
  DeleteRoleIcon
} from '../styles';


const getRoleNames = (list, roles) => {
  if(!list) return []; 
  const names = list.map(item => {
    const role  = roles.find( listItem => listItem.id === item);
    return role;
  });
  return names;
}

const EditRoles = ({employee, formData, handleFormChange}) => {
  const [roles] = useRoles();

  const roleNamesList = useMemo(()=>{
    if(!employee || !formData.groupId) return [];

    const divisions = getGroupDivisionRolesAssigned(employee.assignments, formData.groupId);    
    return getRoleNames(divisions[+formData.id], roles.list)
  },[employee, formData.users]);


  const deleteUserFromDivision = () => {
    const remainingEmployees = formData.users.filter(user => user.value !== employee.value);
    handleFormChange('users',remainingEmployees);
  }

  const deleteRoleFromDivision = (id) => {
    const editedEmployee = removeRoleToDivision(employee, id , +formData.id, formData.groupId);
    
    let newEmployees = formData.users.map(employeeItem => {
      if( employeeItem.value === employee.id ){
        return ({
          value: editedEmployee.id,
          label: editedEmployee.name,
          ...editedEmployee
        });
      }
      return employeeItem;
    });
    handleFormChange('users',newEmployees);
  }

  return (
    <Container>
      <DeleteEmployee onClick={deleteUserFromDivision}>
        <DeleteEmployeeIcon src={CloseSVG} alt={'remove-employee'}/>
      </DeleteEmployee>
      <ProfileContainer>
        <UserItem name={`${employee.label} ${employee?.lastName ?? ''}`} image={employee?.image} />
      </ProfileContainer>
      {!!roleNamesList && (<>
        <Title>Roles</Title>
        <RolesContainer>
          {roleNamesList.map( role => 
            !!role && (<RoleItem key={role.id}>
              {role.name} 
              <DeleteRoleItem onClick={()=>deleteRoleFromDivision(role.id)}>
                <DeleteRoleIcon src={CloseSVG} alt={'remove-Role'}/>
              </DeleteRoleItem>
            </RoleItem>)
          )}
        </RolesContainer>
      </>)}  
    </Container>
  )
}
export default EditRoles;
