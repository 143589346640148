import { all, call } from 'redux-saga/effects';
import reservations from './groups/sagas';
import places from './places/sagas';
import apps from './apps/sagas';
import permissions from './permissions/sagas';
import divisions from './divisions/sagas';
import users from './users/sagas';
import emailTemplates from './emailTemplates/sagas';
import salesAdjustment from './salesAdjustment/sagas';
import roles from './roles/sagas';
import nomenclators from './nomenclators/sagas';

export default function* rootSaga() {
  yield all([
    call(reservations),
    call(places),
    call(apps),
    call(permissions),
    call(divisions),
    call(users),
    call(emailTemplates),
    call(salesAdjustment),
    call(roles),
    call(nomenclators)
  ]);
}
