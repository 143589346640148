import React from 'react';
import colors from 'resources/constants/colors';
import styled from 'styled-components';

const SwitchContainer = styled.div`
    width: 35px;
	height: 20px;
    position: relative;
    border-radius: 35px;
    transition: background-color 100ms ease-out;
    background-color: ${({active})=>active ? colors.active : colors.inactive};
    ${({disabled})=> disabled ? 'opacity: 0.2; pointer-events: none;' : ''}
    cursor: ${({disabled})=> disabled ? 'default' : 'pointer'};
    margin-left: 12px;
`;

const ToggleButton = styled.div`
    width: 16px;
    height: 16px;
    background-color: white;
    border: solid black 1px;
    border-radius:16px;
    transition: transform 100ms ease-in-out;
    transform: ${({active})=>active ? 'translate(17px,2px)' : 'translate(2px,2px)'};
`;

const Switch = ({onClick, active, disabled=false}) => {
  return <SwitchContainer onClick={disabled ? ()=>{} : onClick} active={active} disabled={disabled}><ToggleButton active={active}/></SwitchContainer>;
};

export default Switch;
