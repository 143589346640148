import DEFAULT_PERSONAL_INFO from 'components/EmployeeForm/DefaultPersonalInfo';

export const DEFAULT_ASSIGNMENT = (groupId) => ({ groupId, division: null, role: null });

export const initialState = (groupId) => ({
  personalInfo: {
    ...DEFAULT_PERSONAL_INFO,
  },
  permission: null,
  assignments: [{ ...DEFAULT_ASSIGNMENT(groupId) }],
});

export const ACTIONS = {
  INIT: 'init',
  PERSONAL_INFO: 'personalInfo',
  PERMISSION: 'permission',
  ADD_ASSIGNMENT: 'addAssignment',
  REMOVE_ASSIGNMENT: 'removeAssignment',
  ADD_DIVISION: 'division',
  ADD_ROLE: 'role',
};

const Reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.PERSONAL_INFO: {
      const newState = { ...state };
      newState.personalInfo = { ...state.personalInfo, ...action.payload };

      return newState;
    }
    case ACTIONS.PERMISSION: {
      const newState = { ...state };
      newState[action.type] = action.payload;

      return newState;
    }
    case ACTIONS.ADD_ASSIGNMENT: {
      const assignments = [...state.assignments];
      assignments.push({ ...DEFAULT_ASSIGNMENT(action.payload) });

      return { ...state, assignments };
    }
    case ACTIONS.REMOVE_ASSIGNMENT: {
      const assignments = [...state.assignments];
      assignments.splice(action.payload, 1);

      return { ...state, assignments };
    }
    case ACTIONS.ADD_DIVISION:
    case ACTIONS.ADD_ROLE: {
      const assignments = [...state.assignments];
      const newAssignment = { ...assignments[action.payload.index] };
      newAssignment[action.type] = action.payload.value;
      assignments[action.payload.index] = newAssignment;
      return { ...state, assignments };
    }
    case ACTIONS.INIT:
      return { ...action.payload };

    default:
      throw new Error(`Action type not valid ${action.type}`);
  }
};

export default Reducer;
