const PERSONAL_INFO_KEYS = {
    NAME: 'name',
    LAST_NAME: 'lastName',
    SECOND_NAME: 'secondName',
    USER_POS_ID: 'userPosid',
    EMAIL: 'email',
    PASS: 'pass',
    IMAGE: 'image',
    IS_ACTIVE: 'isActive',
    SHORT_NAME: 'shortName',
    SHORT_PASSWORD: 'shortPassword',
    USER_TYPE: 'ofType',
};

export default PERSONAL_INFO_KEYS;