import { useContext } from 'react'
import { DISPLAY_DATE_FORMAT, getDisplayDate } from 'utils/date';
import { I18nContext } from './provider';

const useI18n = () => {
    const { translate, selectedLang, setSelectedLang } = useContext(I18nContext);

    const formatDate = (date, format = DISPLAY_DATE_FORMAT) => getDisplayDate(date, format, selectedLang);

    return { translate, selectedLang, setSelectedLang, formatDate };
}


export default useI18n;