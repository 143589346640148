import React from 'react';
import { Card } from '@etiquette-ui/misc';
import { FormContainer, FormSpacer, GridContainer } from 'containers/FormLayout';
import { TitleH2 } from 'components/Texts';
import useI18n from 'i18n/useI18n';

import { PrimaryButton } from '@etiquette-ui/buttons';
import { Spinner } from '@etiquette-ui/misc';
import styled from 'styled-components';
import { Input } from '@etiquette-ui/inputs-dropdowns';

import AddToDivision from 'pages/Group/pages/Management/components/AddEmployee/AddToDivision/index';
import HeadersSelect from 'pages/Group/pages/Management/components/AddEmployee/AddToDivision/HeadersSelect';

const UpdateButton = styled(PrimaryButton)`
  max-width: 300px;
  margin: auto;
  margin-top: 20px;
  pointer-events: all;
`;

const DivisionForm = ({ formData, setFormData, onSubmit, title, buttonText, loading = false}) => {

  const { translate } = useI18n();

  const handleFormChange = (key, value) => {
    const data = { ...formData };
    data[key] = value;
    setFormData(data);
  };

  return (
    <Card>
      <FormContainer>
        <form onSubmit={onSubmit} style={{ width: '90%' }}>
          <TitleH2 style={{ marginBottom: '20px' }}>{title}</TitleH2>
          <Card>            
              <h3>{translate('Details')}</h3>
              <GridContainer>
                <Input
                  labelProps={{label: translate('Name')}}
                  iconProps={{
                    icon: 'user'
                  }}
                  inputProps={{
                    value: formData?.name,
                    onChange: (e) => handleFormChange('name', e.target.value),
                    required: true,
                  }}
                />
                <Input
                  labelProps={{label: translate('Description of Division')}}
                  iconProps={{
                    icon: 'export',
                    iconStyles: {
                      line: {
                        fill: 'none',
                        strokeLinecap:'round',
                        strokeLinejoin:'round',
                        strokeWidth:'1.25px',
                        stroke: '#000'
                      }
                    }
                  }}
                  inputProps={{
                    value: formData?.description,
                    onChange: (e) => handleFormChange('description', e.target.value),
                    required: true
                  }}
                />
              </GridContainer>  
          </Card>
          <FormSpacer />
          
          <Card>    
            <h2>Assignments</h2>
            
            <h3>{translate('Head(s) of the Division')}</h3>
            <HeadersSelect
              title={translate('Head(s) of the Division (max 3)')}
              formData={formData}
              handleFormChange={handleFormChange}
            />

            <h3>{translate('Employees')}</h3>
            <AddToDivision 
              title={translate('Add Employees')}
              formData={formData}
              handleFormChange={handleFormChange}
            />  
          </Card>         

          <FormSpacer />
          <UpdateButton type={'submit'} disabled={loading}>
            {loading 
              ? <Spinner 
                  style={{
                    '--width': '15px',
                    '--margin': '0 5px',
                    '--bg': 'white',
                    '--color': 'white'
                  }}
                /> 
              : buttonText
            }
          </UpdateButton>
        </form>
      </FormContainer>
    </Card>
  );
};

export default DivisionForm;
