import Axios from 'axios';
import Authorization from './auth';
import crudder from './crudder';
import { executeCall } from './utils';

const userCrudder = (domain, resource) => {
  const defaultCrudder = crudder(domain)(resource);

  const url = `${domain}/${resource}`;

  const headers = Authorization;

  return {
    ...defaultCrudder,
    unregister: (data) => executeCall(() => executeCall(() => Axios.post(url + '/unregister', data, { headers }))),
  };
};

export default userCrudder;
