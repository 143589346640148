import React from 'react';
import { FormSpacer } from 'containers/FormLayout';
import { GridContainer, CustomContainer } from 'containers/FormLayout';
import useI18n from 'i18n/useI18n';
import Card from 'components/Card';
import { TitleH3 } from 'components/Texts';
import styled from 'styled-components';
import colors from 'resources/constants/colors';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import { RadioButton } from '@etiquette-ui/selectors';

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TagSample = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 8px;
  background-color: ${({ tagColor }) => tagColor};
  margin-left: 5px;
`;

const CustomRadio = styled(RadioButton)`
  font-size: 14px;
`;

const Details = ({ formData, handleFormChange }) => {
  const { translate } = useI18n();

  return (
    <Card>
      <TitleH3 style={{marginBottom: '18px'}}>{translate('Details')}</TitleH3>
      <GridContainer>
        <Input
          labelProps={{label: translate('Template Name')}}
          iconProps={{
            icon: 'title'
          }}
          inputProps={{ 
            value: formData?.name, 
            onChange: (e) => handleFormChange('name', e.target.value), 
            required: true
          }}
        />
        <Input
          labelProps={{label: translate('Description')}}
          iconProps={{
            icon: 'export',
            iconStyles: {
              line: {
                fill: 'none',
                strokeLinecap:'round',
                strokeLinejoin:'round',
                strokeWidth:'1.25px',
                stroke: '#000'
              }
            }
          }}
          inputProps={{
            value: formData?.description,
            onChange: (e) => handleFormChange('description', e.target.value),
            required: true,
          }}
        />
      </GridContainer>

      <CustomContainer columns="3" width="80%" style={{marginTop: '15px'}}>
        <RadioContainer>
          <CustomRadio
            checked={formData?.tag === 'positive'}
            onClick={() => handleFormChange('tag', 'positive')}
          >{translate('Positive Tag')}</CustomRadio>
          <TagSample tagColor={colors.green} />
        </RadioContainer>
        <RadioContainer>
          <CustomRadio
            checked={formData?.tag === 'neutral'}
            onClick={() => handleFormChange('tag', 'neutral')}
          >{translate('Neutral Tag')}</CustomRadio>
          <TagSample tagColor={colors.neutral} />
        </RadioContainer>
        <RadioContainer>
          <CustomRadio
            checked={formData?.tag === 'negative'}
            onClick={() => handleFormChange('tag', 'negative')}
          >{translate('Negative Tag')}</CustomRadio>
          <TagSample tagColor={colors.cancel} />
        </RadioContainer>
      </CustomContainer>
      <FormSpacer />
    </Card>
  );
};

export default Details;
