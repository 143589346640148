import Axios from 'axios';
import Authorization from './auth';
import { executeCall } from './utils';
import { API } from '../constants/urls';

const salesCrudder = (AppSinTiCrudder) => {

    const url = `${API}/sale/bulk`;

    AppSinTiCrudder.createBulk = (data) => executeCall(() => Axios.post(url, data, { headers: Authorization }));
    AppSinTiCrudder.updateBulk = (data) => executeCall(() => Axios.patch(url, data, { headers: Authorization }));

    return AppSinTiCrudder
}




export default salesCrudder;