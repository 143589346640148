import React from 'react';
import Actions from 'components/DynamicTable/DynamicTableComponents/Editable/Actions';
import ActionEditIcon from 'resources/img/action-edit-icon.svg';
import Switch from 'components/Switch';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import Active from '../alert/Active';
import Inactive from '../alert/Inactive';
import { useAccess } from 'providers/access';
import { ACCESS_LIST } from 'resources/constants/config';

const RowActions = ({ item, globalState }) => {
  const alert = useAlert();
  const { hasAccessInGroup } = useAccess();
  
  const handleActive = (obj) => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: Active,
      },
    })
      .then(() => {
        globalState.config.dispatcher.toggleActive(obj)
      })
  };
  const handleInactive = (obj) => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: Inactive,
      },
    })
      .then(() => {
        globalState.config.dispatcher.toggleActive(obj)
      })
  };

  const onActiveChange = async () => {
    const obj = {};
    obj.id = item.id;
    obj.isActive = !!item.isActive ? 0 : 1;

    if(obj.isActive === 0){
      handleInactive(obj)
    }else if(obj.isActive === 1){
      handleActive(obj)
    }
  };

  const actions = [
    {
      event: () => globalState.config.history.push(`/${item.id}`),
      src: ActionEditIcon,
    },
    {
      child: <Switch active={item.isActive} disabled={!hasAccessInGroup(item.id, ACCESS_LIST.GROUP)} onClick={onActiveChange} />,
    },
  ];

  const loading = globalState.config.data.fetching?.config?.id===item.id;

  return <Actions loading={loading} actions={actions} />;
};

export default RowActions;
