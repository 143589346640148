import { API } from '../constants/urls';
import authCrudder from './auth-crud';
import crudder from './crudder';
import nomenclatorConsumer from './nomenclator';
import salesCrudder from './sale';
import surveyCrudder from './survey';
import userCrudder from './user';
export const getJson = (url) => fetch(url).then(r => r.json());

const AppSinTiCrudder = crudder(API);
export const groupsAPI = AppSinTiCrudder("group");
export const availableAppsAPI = AppSinTiCrudder("security/app/available");
export const appsAPI = AppSinTiCrudder("security/app");
export const permissionsAPI = AppSinTiCrudder("security/permission");
export const divisionsAPI = AppSinTiCrudder("security/divisions");
export const usersAPI = userCrudder(API, "security/user");
export const emailTemplatesAPI = AppSinTiCrudder("emailtemplates");
export const salesAdjustmentAPI = salesCrudder(AppSinTiCrudder("sale"));
export const rolesAPI = AppSinTiCrudder("security/role");
export const nomenclatorAPI = nomenclatorConsumer;
export const authAPI = authCrudder(API, "auth/access-list");
export const surveyAPI = surveyCrudder(API, "survey/survey");
