const routes = () => {
  const url = '/consolidate';
  return [
    { path: `${url}/groups`, title: "Groups" },
    { path: `${url}/permissions`, title: "Permissions" },
    { path: `${url}/divisions`, title: "Divisions" },
    { path: `${url}/roles`, title: "Roles" },
    { path: `${url}/employees`, title: "Employees" },
  ];
};

export default routes;
