import React from 'react';
import styled from 'styled-components';
import colors from 'resources/constants/colors';
import useI18n from 'i18n/useI18n';

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bg }) => bg};
  border-radius: 8px;
  width: 130px;
  height: 48px;
  color: ${({ font }) => font};
`;

const Tags = ({ tag }) => {
  const { translate } = useI18n();

  let bg = colors.darkGray;
  let font = colors.disabled;

  switch (tag) {
    case 'positive':
      bg = colors.active;
      font = colors.textMain;
      break;
    case 'negative':
      bg = colors.inactive;
      font = colors.white;
      break;
    default:
      bg = colors.darkGray;
      font = colors.disabled;
  }

  return (
    <Tag bg={bg} font={font}>
      {translate(tag)}
    </Tag>
  );
};

export default Tags;
