import React, { useState } from 'react';
import styled from 'styled-components';
import { Card } from '@etiquette-ui/misc';
import Personal from 'components/EmployeeForm/sections/Personal';
import AccountAccess from 'components/EmployeeForm/sections/AccountAccess';
import ShortAccess from 'components/EmployeeForm/sections/ShortAccess';
import Id from 'components/EmployeeForm/sections/Id';
import Permission from '../../../../../../../components/EmployeeForm/sections/Permission';
import { useParams } from 'react-router-dom';
import { FormSpacer } from 'containers/FormLayout';
import { ACTIONS } from '../Reducer';
import Assignments from '../../../../../../../components/EmployeeForm/sections/Assignments';
import colors from 'resources/constants/colors';
import useI18n from 'i18n/useI18n';
import PERSONAL_INFO_KEYS from 'components/EmployeeForm/keys';

import { PrimaryButton } from '@etiquette-ui/buttons';
import { Spinner } from '@etiquette-ui/misc';

const Title = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 32px;
`;

const Form = ({ title, formData, formDispatcher, onSubmit, buttonText, loading = false, isEdit = false }) => {
  const { groupId } = useParams();

  const [errors, setErrors] = useState({});
  const { translate } = useI18n();

  const handlePersonalChange = (field, value) => {
    const newField = { [field]: value };
    formDispatcher(ACTIONS.PERSONAL_INFO, newField);
  };

  const validateAssingments = (assignments) => {
    const validations = assignments.map((assignment)=>{
      if(!!assignment.division && !!assignment.role)
        return true;

      return false;
    });
    return validations.every(item => item === true);
  }

  const hasValidationError = () => {
    if(!formData) return true;

    let newErrors = {...errors};
    let hasAnError = false;

    if(formData.personalInfo[PERSONAL_INFO_KEYS.NAME] === ''){
      newErrors[PERSONAL_INFO_KEYS.NAME] = translate('Name is required');
      hasAnError = true;
    } else newErrors[PERSONAL_INFO_KEYS.NAME] = '';

    if(formData.personalInfo[PERSONAL_INFO_KEYS.LAST_NAME] === ''){
      newErrors[PERSONAL_INFO_KEYS.LAST_NAME] = translate('Last Name is required');
      hasAnError = true;
    } else newErrors[PERSONAL_INFO_KEYS.LAST_NAME] = '';

    if(formData.personalInfo[PERSONAL_INFO_KEYS.EMAIL] === ''){
      newErrors[PERSONAL_INFO_KEYS.EMAIL] = translate('Invalid Email');
      hasAnError = true;
    } else newErrors[PERSONAL_INFO_KEYS.EMAIL] = '';

    if(formData.personalInfo[PERSONAL_INFO_KEYS.PASS] === '' && !isEdit){
      newErrors[PERSONAL_INFO_KEYS.PASS] = translate('Password is required');
      hasAnError = true;
    } else newErrors[PERSONAL_INFO_KEYS.PASS] = '';

    if(formData.permission === null){
      newErrors['permission'] = translate('A permission type is required.');
      hasAnError = true;
    } else newErrors['permission'] = '';

    if(!formData.assignments || formData.assignments[0].division === null || formData.assignments[0].role === null || !validateAssingments(formData.assignments)){
      newErrors['assignments'] = translate('One Division and its Role are required at least. Verify each Division has a Role assigned.');
      hasAnError = true;
    } else newErrors['assignments'] = '';

    setErrors(newErrors);
    return hasAnError;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(hasValidationError()){
      return;
    }
    
    onSubmit();
  }

  return (
    <>
      <Card>
        <Title>{title}</Title>
        <form onSubmit={handleSubmit}>
          <Personal formData={formData.personalInfo} onChangeForm={handlePersonalChange} errors={errors} />
          <FormSpacer />
          <AccountAccess formData={formData.personalInfo} onChangeForm={handlePersonalChange} errors={errors} isEdit={isEdit} />
          <FormSpacer />
          <ShortAccess formData={formData.personalInfo} onChangeForm={handlePersonalChange} errors={errors} isEdit={isEdit} />
          <FormSpacer />
          <Id formData={formData.personalInfo} onChangeForm={handlePersonalChange} errors={errors} />
          <FormSpacer />
          <Permission
            formData={formData}
            handleChange={(value) => {
              formDispatcher(ACTIONS.PERMISSION, value)
            }}
            errors={errors?.permission}
            groupId={groupId}
          />
          <FormSpacer />
          <Assignments
            formData={formData}
            handleDivisionChange={(index, value) => formDispatcher(ACTIONS.ADD_DIVISION, { index, value })}
            handleRoleChange={(index, value) => formDispatcher(ACTIONS.ADD_ROLE, { index, value })}
            addAssignment={() => formDispatcher(ACTIONS.ADD_ASSIGNMENT, groupId )}
            removeAssignment={(id) => formDispatcher(ACTIONS.REMOVE_ASSIGNMENT, id)}
            errors={errors?.assignments}
            groupId={groupId}
          />
          <FormSpacer />
          <PrimaryButton type={'submit'} disabled={loading} >
            {loading 
              ? <Spinner 
                  style={{
                    '--width': '15px',
                    '--margin': '0 5px',
                    '--bg': 'white',
                    '--color': `${colors.accept}`
                  }}
                /> 
              : buttonText
            }
          </PrimaryButton>
        </form>
      </Card>
    </>
  );
};

export default Form;
