import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Body } from 'components/Texts';
import LockSVG from 'resources/img/lock-icon.svg';
import useGroups from 'state/groups';
import { PUBLIC_URL } from 'resources/constants/urls';
import { useParams } from 'react-router';

const Container = styled.div`
  min-width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  p {
    width: 100%;
    text-align: center;
  }
  .opacity {
    opacity: 0.4;
  }
`;

const LockContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const Lock = styled.img`
  width: 28px;
  height: 28px;
`;

const AppIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
  border-radius: 8px;
  .opacity {
    opacity: 0.4;
  }
`;

const CarouselItem = ({ data }) => {
  const { groupId } = useParams();
  const [groups] = useGroups();
  const [lock, setLock] = useState(false);

  useEffect(()=>{
    if(groups.list.length===0) return;
    const group = groups.list.find(({id})=>id===+groupId);
    if(!group) return;

    setLock(!group.apps.some((element) => element.identifier === data.identifier));
  },[groups.list]);

  return (
    <Container>
      <AppIcon src={`${PUBLIC_URL}/logos/${data.identifier}.png`} alt="" className={lock && 'opacity'} />
      <LockContainer>{lock && <Lock src={LockSVG} alt={data.name} />}</LockContainer>
      <Body className={lock && 'opacity'}>{data.name}</Body>
    </Container>
  );
};

export default CarouselItem;
