import React, { useEffect, useState } from 'react';
import useDivisions from 'state/divisions';
import Form from '../components/Form';
import useI18n from 'i18n/useI18n';
import { useParams } from 'react-router-dom';
import { useRouter } from 'providers/router';
import useUsers from 'state/users';
import { formatUsersForSelect, mapIdsToObjects, getUsersRoleByDivision } from 'utils';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import FormHeader from 'pages/Group/components/FormHeader';
import { getConsolidateAssignments } from 'utils/employee';


const matchAssignments = (employees, headerIds, groupId, divisionId) => {
  if( headerIds.length <= 0 ) return employees;

  const editedEmployees = employees.map( employee => {
    if(headerIds.includes(employee.value)){
      employee.assignments[groupId] = employee.assignments[groupId].map( assignment => {
        if( assignment.division === divisionId ){
          assignment.isHead = 1;
        } else assignment.isHead = 0;
        
        return assignment;
      });
    }
    employee.assignments = getConsolidateAssignments(employee.assignments);
    return employee;
  });
  return editedEmployees;
}

const Edit = ({ location }) => {
  const { translate } = useI18n();
  const [data, dispatcher, utils] = useDivisions();
  const [users, usersDispatcher] = useUsers();
  const { route, canGoBack } = useRouter();
  const { groupId } = useParams();
  const [formData, setFormData] = useState({ heads: [], users: [] });

  useEffect(() => {
    dispatcher.fetchStart(groupId);
    usersDispatcher.fetchStart();
  }, []);

  const getDivision = () => {
    const url = location.pathname.split('/');
    const id = url.pop();
    const division = utils.findOne(id);
    return division;
  }

  useEffect(() => {
    if (users.length === 0 || data.list.length === 0) return;

    const division = getDivision();

    const formData = { 
      id: division.id,
      name: division.name,
      description: division.config?.description ?? '',
      groupId: division.groupId,
      heads: formatUsersForSelect(mapIdsToObjects(division.heads, users.list)),
      users: formatUsersForSelect(mapIdsToObjects(division.users, users.list)),
    };
    setFormData(formData);
  }, [users.list, data.list]);

  const onSubmit = (e) => {
    e.preventDefault();
    const division = {
      id: formData.id,
      name: formData.name,
      groupId,
      users: getUsersRoleByDivision(formData.users, groupId, formData.id),
      heads: formData.heads.map(({ value }) => value ),
      config: { description: formData.description },
    };
    console.log('division',division);
    dispatcher.updateStart(division);
  };

  return (
    <FormScrollableContainer>
      <FormHeader route={canGoBack() ? route.from : `/${groupId}/management/divisions`} routeText={translate('Back to Divisions')} />
      {!data.isFetching && (
        <Form
          onSubmit={onSubmit}
          updateAssignments={onSubmit}
          formData={formData}
          setFormData={setFormData}
          title={translate('Edit Division')}
          loading={data.fetching.state}
          buttonText={translate('Update Division')}
          groupId={groupId}
        />
      )}
    </FormScrollableContainer>
  );
};

export default Edit;
