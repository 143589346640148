import React from 'react';
import styled from 'styled-components';
import colors from 'resources/constants/colors';
import color from 'resources/constants/colors';
import { Card } from '@etiquette-ui/misc';
import { PrimaryButton } from '@etiquette-ui/buttons';

export const BaseButton = styled.button`
  width: 100%;
  padding: 15px 0px;
  border: 1px solid ${({ color }) => (color ? color : colors.accept)};
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:focus {
    outline: 1px solid ${({ color }) => (color ? color : colors.accept)};
  }
`;

export const PassiveButton = styled(BaseButton)`
  outline: none;
  border: none;
  background-color: ${colors.lightBlueButton};
  color: ${colors.accept};
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
  &:hover {
    box-shadow: 0px 4px 12px ${colors.shadow};
  }
`;

export const ColorButton = styled(BaseButton)`
  background-color: ${({ color }) => (color ? color : colors.accept)};
  color: white;
  font: normal normal normal 14px/18px Montserrat;
  &:hover {
    background-color: white;
    color: ${({ color }) => (color ? color : colors.accept)};
  }
`;

export const TransparentButton = styled(BaseButton)`
  background-color: transparent;
  color: ${({ color, disabled }) => (disabled ? colors.disabled : color ? color : colors.accept)};
  &:hover {
    background-color: ${({ color }) => (color ? color : colors.accept)};
    color: white;
  }
`;

export const TransparentDarkButton = styled(TransparentButton)`
  color: white;
`;

export const AltTransparentButton = styled(TransparentButton)`
  border: none;
  &:hover {
    background-color: ${({ backgroundColor, disabled }) =>
      disabled ? 'inherit' : backgroundColor ? backgroundColor : colors.lightBlue};
    color: ${({ color, disabled }) => (disabled ? colors.disabled : color ? color : colors.accept)};
  }
  &:focus {
    outline: none;
  }
`;

export const AcceptButton = ({ text, onClick, disabled, type = 'button' }) => (
  <Card>
    <ColorButton
      type={type}
      color={disabled ? colors.disabled : colors.accept}
      onClick={disabled ? () => {} : onClick}
      style={{ disabled: disabled }}
    >
      {!!text ? text : 'Accept'}
    </ColorButton>
  </Card>
);

export const CancelButton = ({ text, dark = false, onClick, type = 'button' }) => {
  const Button = dark ? TransparentDarkButton : TransparentButton;

  return (
    <Card>
      <PrimaryButton type={type} color={colors.cancel} onClick={onClick}>
        {!!text ? text : 'Cancel'}
      </PrimaryButton>
    </Card>
  );
};

export const Link = styled.a`
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
`;

export const AcceptLink = styled(Link)`
  color: ${color.accept};
`;

export const CancelLink = styled(Link)`
  color: ${color.cancel};
`;

const PassiveButtonStructure = styled(PassiveButton)`
  width: 195px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  .button__icon {
    width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 17px;
      height: 17px;
    }
  }
  p {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

export const PassiveButtonCustom = ({ onClick, text, src }) => {
  return (
    <PassiveButtonStructure type="button" onClick={onClick}>
      {src && (
        <div className="button__icon">
          <img src={src} alt="" />
        </div>
      )}
      <p>{text}</p>
    </PassiveButtonStructure>
  );
};

export const OnSubmitButton = styled(PrimaryButton)`
  font-weight: 600;
  &:hover {
    color: ${colors.accept};
    background-color: ${colors.lightBlueButton};
    border: ${colors.lightBlueButton};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.accept};
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 20px;
  margin-right: 10px;
`;

export const IconButton = ({ children, className, icon, onClick }) => {
  return (
    <Container className={className} onClick={onClick}>
      <Icon src={icon} />
      {children}
    </Container>
  );
};

export const CardActionButton = styled(IconButton)`
  position: absolute;
  right: 40px;
  bottom: 20px;
`;