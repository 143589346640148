import React from 'react';
import styled from 'styled-components';
import Card from 'components/Card';
import { TitleH1 } from 'components/Texts';
import Space from 'components/Space';
import IconLink from 'components/IconLink';
import ImportData from 'components/ImportData';

const Container = styled.div`
  width: 100%;
  padding: 10px 242px;
  height: fit-content;
  margin-top: ${({ marginTop }) => (marginTop || '0')}
`;

const Header = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Layout = ({ pathBack, pathBackText, marginTop, onClickImportData, title, children }) => {

  return (
    <Container marginTop={marginTop}>
      <Header>
        <IconLink to={pathBack}>{pathBackText}</IconLink>
        {onClickImportData && <ImportData onClick={onClickImportData} />}
      </Header>
      <Card>
        <TitleH1>{title}</TitleH1>
        <Space />
        {children}
      </Card>
    </Container>
  );
}

export default Layout;
