export const initialState = {
    id:'-999',
    data: {
        name: "",
        description: ""
    },
    users: new Map(),
    groups: [],
}


const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "DATA": return { ...state, data: { ...state.data, ...action.payload } };

        case "ADD_GROUP": {
            const newState = { ...state };
            const groupId = action.payload;
            
            if (!newState.groups.includes(groupId)) {
                newState.groups.push(groupId);
                newState.users.set(groupId, []);
            }
            
            console.log('add newState, groupId: ', groupId);
            console.log(newState);

            return newState;
        }

        case "REMOVE_GROUP": {
            const newState = { ...state };
            const groupId = action.payload;

            if (newState.groups.includes(groupId)) {
                const index = newState.groups.map(({ value }) => value).indexOf(groupId);
                newState.groups.splice(index, 1);
                newState.users.delete(groupId);
            }

            return newState;
        }

        case "USERS":{
            const userMap = state["users"];
            const {users, groupId} = action.payload;

            userMap.set(groupId, users);
            return { ...state, ["users"]: userMap };
        }

        default: throw new Error(`Invalid action ${action.type}`)

    }
}



export default Reducer;