import React, { useEffect, useMemo } from "react";
import { useRouteMatch } from 'react-router';
import TableCardContainer from "pages/Consolidate/components/TableCardContainer";
import FloatingActionButton from "components/FloatingActionButton";
import useI18n from "i18n/useI18n";
import LayoutTable from "pages/Consolidate/containers/LayoutTable";
import Table from 'components/Table';
import Structure from './structure';
import { useHistory } from 'react-router-dom';
import useRoles from "state/roles";
import { useSearch } from "providers/search";
import { compareStrings } from "utils";
import { useAlert } from '@etiquette-ui/alert-dialog';
import useUsers from "state/users";
import useDivisions from "state/divisions";

const List = ({ location }) => {
  const [data, dispatcher] = useRoles();
  const history = useHistory();
  const { translate } = useI18n();
  const { path } = useRouteMatch();
  const { search, groupsList } = useSearch();
  const alert = useAlert();
  const [users, usersDispatcher] = useUsers();
  const [, divisionsDispatcher] = useDivisions();

  useEffect(() => {
    dispatcher.fetchStart();
    usersDispatcher.fetchStart();
    divisionsDispatcher.fetchStart();
  }, [users.list]);

  const items = useMemo(() => {
    let list = [...data.list];
    if (search.length !== 0) list = list.filter(({ name }) => compareStrings(name, search));
    if (groupsList.length > 0) list = list.filter(({groupId}) => groupsList.includes(groupId));
    return list;
  }, [data.list, search, groupsList]);

  return (
    <LayoutTable location={location}>
      <TableCardContainer>
        <Table 
          structure={Structure} 
          config={{history, dispatcher, data, translate, alert}} 
          items={items} 
        />
        <FloatingActionButton to={`${path}/create`}>
          {translate("Add Role")}
        </FloatingActionButton>
      </TableCardContainer>
    </LayoutTable>
  );
};

export default List;
