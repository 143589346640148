import Types from './types';
import { DEFAULT_NO_ERROR } from 'state/constants';

const DEFAULT_FETCHING_STATE = { state: false, config: {} };

const INITIAL_STATE = {
    list: [],
    fetching: { state: false, config: {} },
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } }
        case Types.FETCH_SUCCESS: return { ...state, list: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } }
        case Types.FETCH_ERROR: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } }

        case Types.UPDATE_START: return { ...state, fetching: { state: true, config: { id: action.payload.id } } }
        case Types.UPDATE_SUCCESS: {
            const list = [...state.list];
            const item = list.find(({ id }) => id === action.payload.sale.id);
            const index = list.indexOf(item);
            if (index > -1) {
                Object.keys(action.payload).forEach((key) => {
                    item[key] = action.payload[key];
                });
                list[index] = item;
            }
            return { ...state, list, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } }
        }
        case Types.UPDATE_ERROR: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } }

        case Types.CREATE_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } }
        case Types.CREATE_SUCCESS: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } }
        case Types.CREATE_ERROR: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } }

        case Types.DELETE_SUCCESS: {
            const list = [...state.list];
            const itemIndex = list.findIndex(item => +item.id === +action.payload.id)
            list.splice(itemIndex, 1);

            return { ...state, list }
        }

        case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

        default: return state;
    }
};

export default reducer;