import styled from "styled-components";
import colors from "resources/constants/colors";

export const SearchContainer = styled.div`
  height: 40px;
  border-radius: 4px;
  background-color: white;
  margin: 0px 15px 10px 15px;
  border: solid 1px ${colors.backgroundLightGrey};
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
  padding-left: 18px;
  color: #0f191b;
  background-color: transparent;
  border: none;
  outline: none;
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
  }
`;