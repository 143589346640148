import { isValidEmail } from 'utils/validators';
import { findCountryPhoneCode } from 'utils/country';
import { isValidNumber } from 'utils/phone';
import GROUP_INFO_KEYS from './keys';

const validate = (formData) => {
    const errors = {};
    let count = 0;
    formData.contacts.forEach((ctx)=>{
      if(!isValidNumber(findCountryPhoneCode(ctx.contactCountryCode), ctx.contactPhone)){
        errors[`contactPhone_${count}`] = true;
      }
      if(!isValidEmail(ctx.contactEmail)){
        errors[`contactEmail_${count}`] = true;
      }
      count++;
    });
    if(!formData[GROUP_INFO_KEYS.IMAGE]){
      errors[GROUP_INFO_KEYS.IMAGE] = true;
    }
    if(!formData[GROUP_INFO_KEYS.STATE_ADDRESS]){
      errors[GROUP_INFO_KEYS.STATE_ADDRESS] = true;
    }
    if(!formData[GROUP_INFO_KEYS.CITY_ADDRESS]){
      errors[GROUP_INFO_KEYS.CITY_ADDRESS] = true;
    }
    return errors;
}

export default validate;