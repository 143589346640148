import React, { useEffect, useState } from 'react';
import useUsers from 'state/users';
import styled from 'styled-components';
import UserItem from 'components/UserItem';
import useI18n from 'i18n/useI18n';
import useRoles from 'state/roles';
import { getFullName } from 'utils/text';

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const User = styled(UserItem)`
  width: 260px;
  margin: 10px 0;
`;

const DEFAULT_HEADS = [];
const DEFAULT_USERS = [];

const UserGrid = ({ heads = DEFAULT_HEADS, users = DEFAULT_USERS, divisionId, groupId }) => {
  const [list, setList] = useState([]);
  const { translate } = useI18n();
  const [usersState,, userUtils] = useUsers();
  const [roles] = useRoles();

  const processUser = (user) => {
    const division = user.assignments[groupId].find(({division})=>division===divisionId);
    const { name, lastName, image } = user;
    return { user: { name, lastName, image }, isHead: !!division?.isHead, roles: [...(division ? division.roles : [])] }
  }

  useEffect(() => {
    if (usersState.list.length === 0 || roles.list.length===0) return;
    const reorder = [
      ...heads.map((id) => {
        const user = userUtils.findOne(id);
        return !!user && !!user.assignments && !!user.assignments[groupId] ? processUser(user) : {};
      }),
      ...users.map((id) => {
        const user = userUtils.findOne(id);
        return !!user && !!user.assignments && !!user.assignments[groupId] ? processUser(user) : {};
      }),
    ];
    setList(reorder);
  }, [heads, users, usersState.list, roles.list]);

  const getPositionText = (isHead, rolesIds) => {
    return `${isHead ? `${translate('Head')}` : ''}${!!rolesIds && rolesIds.length > 0 ? `${isHead ? ' | ' : ''}${rolesIds.map((roleId)=>roles.list.find(({id})=>id===roleId)?.name ?? '').join(', ')}` : ''}`;
  }

  return (
    <Grid>
      {list.map(({ user, isHead, roles }, i) => (
        <User
          key={i}
          name={user && getFullName(user)}
          image={user?.image}
          position={!!isHead || !!roles ? getPositionText(isHead, roles) : ''}
          highlight={isHead}
        />
      ))}
    </Grid>
  );
};

export default UserGrid;
