import React, { useState, useMemo } from 'react';
import useUsers from 'state/users';

import EditRoles from './EditRoles';
import EmployeeSelect from 'components/Assignments/EmployeeSelect';
import RoleSelect from 'components/Assignments/RoleSelect';
import { addRoleToDivision } from 'utils/employee';


import { Row, SelectContainer} from '../styles';


const AddToDivision = ({ title, formData, handleFormChange, groupId}) => {

  const [users] = useUsers();
  const [employee, setEmployee] = useState(undefined);

  const onChangeRole = (role) =>{
    if( !role) return; 
    
    let employees;    
    const repeatedEmployee = formData.users.get(groupId).find(item => item.value === employee.id);

    if(!!repeatedEmployee) {

      const filteredEmployees = formData.users.get(groupId).filter(item => item.value !== employee.id);
      const editedEmployee = addRoleToDivision(repeatedEmployee,role.value, +formData.id, groupId);
      employees = [editedEmployee,...filteredEmployees];

    } else {
      const editedEmployee = addRoleToDivision(employee,role.value, +formData.id, groupId);
      employees = [{
        value: editedEmployee.id,
        label: editedEmployee.name,
        ...editedEmployee
        },
        ...formData.users.get(groupId)
      ];
    }

    handleFormChange(employees);
    setEmployee(undefined);
  }

  const options = useMemo(()=>{
    const list = users.list
      .filter(({ assignments }) => {
        if (!assignments || !assignments[groupId]) return false;
        return true;
      })
      .filter(({ id }) => {
        return !formData.heads.get(groupId).some(({ value }) => value === id);
      });
    return list;
  },[formData.heads.get(groupId)]);

  return (
    <div>
      <Row>
        <SelectContainer>
          <EmployeeSelect
            employee={employee}
            setEmployee={setEmployee}
            employees={options.filter(({assignments}) => !!assignments && Object.keys(assignments).includes(groupId.toString()))}
          />
        </SelectContainer>
        <SelectContainer>
          <RoleSelect
            employee={employee}
            onChangeRole={onChangeRole}
            divisionId={+formData.id}
            groupId={groupId}
          />
        </SelectContainer>
      </Row>
      {!!formData.users.get(groupId) && formData.users.get(groupId).length > 0 && (
        <Row>
          {formData.users.get(groupId).map((employee, index) =>(
            <EditRoles 
              key={`${employee.value}-${index}`}
              employee={employee} 
              formData={formData}
              handleFormChange={handleFormChange}
              groupId={groupId}
            />
          ))}
        </Row>
      )}      
    </div>
  );
};

export default AddToDivision;