import React, { useState, useReducer } from 'react'

import useUsers from 'state/users';
import Reducer, { initialState } from './Reducer';
import Layout from "./Form";
import useRoles from 'state/roles';
import { getUserDivisionsByRole } from 'utils';


const Form = () => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [, , usersUtils] = useUsers();
    const [state, dispatch] = useReducer(Reducer, initialState);
    const [, rolesDispatcher] = useRoles();

    const addGroup = (option) => {
        if (selectedValues.find(selected => selected.value == option.value)) return;

        setSelectedValues([...selectedValues, option])
        dispatch({
            type: "ADD_GROUP",
            payload: +option.value
        })
    }

    const removeGroup = (option) => {
        setSelectedValues(selectedValues.filter(val => val.value != option.value))
        dispatch({
            type: "REMOVE_GROUP",
            payload: +option.value
        })
    }

    const onChangeForm = (field, value) => {
        dispatch({
            type: "DATA",
            payload: { [field]: value }
        })
    }

    const onChangeUsers = (groupId, users) => {
        dispatch({
            type: "USERS",
            payload: {
                groupId,
                users: users
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const roles = [];
        for (const groupId of state.groups) {
            roles.push({
                name: state.data.name,
                config: {
                    description: state.data.description
                },
                groupId: +groupId,
                users: getUserDivisionsByRole(state.users.get(groupId), groupId, state.id)
            })
        }
        roles.forEach(rol => rolesDispatcher.createStart(rol))
    }

    function getUserOfGroup(groupId) {
        const listOfIds = state.groups[groupId] || [];

        return listOfIds.map(id => {
            const user = usersUtils.findOne(id);
            return { value: user.id, label: user.name, ...user }
        })
    }

    return (<Layout
        onSubmit={onSubmit}
        addGroup={addGroup}
        state={state}
        removeGroup={removeGroup}
        selectedValues={selectedValues}
        onChangeForm={onChangeForm}
        getUserOfGroup={getUserOfGroup}
        onChangeUsers={onChangeUsers}
    />)
}

export default Form
