import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { isCurrent } from 'utils/routes';
import { NavItem } from '@etiquette-ui/selectors';

const Nav = styled.nav`
  display: flex;
`;

const CustomLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  &.current {
    font-weight: 600;
  }
`;

const Tabs = ({ routes = [], pathIndex, current, className, children }) => {
  const { translate } = useI18n();
  return (
    <Nav className={className}>
      {routes.map(({ path, title }, i) => (
        <NavItem key={i} isActive={isCurrent(path, current, pathIndex)}>
          <CustomLink to={path} className={isCurrent(path, current, pathIndex) ? 'current' : ''}>
            {translate(title)}
          </CustomLink>
        </NavItem>
      ))}
      {children}
    </Nav>
  );
};

export default Tabs;
