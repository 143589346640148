import Card from 'components/Card';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GroupInfo from './components/GroupInfo';
import GroupProfile from './components/GroupProfile';
import AppList from './components/AppList';
import TabLayoutContainer from 'pages/Group/pages/Management/containers/TabLayoutContainer';
import TopNavBar from 'pages/Group/pages/Management/components/TopNavBar';
import IconLink from 'components/IconLink';
import useI18n from 'i18n/useI18n';
import EditIcon from 'resources/img/edit-icon.svg';
import useGroups from 'state/groups';
import { useParams } from 'react-router';
import { HIDDEN_SCROLLBARS } from 'resources/constants/styles';
import Schedule from './components/Schedule';
import { useAccess } from 'providers/access';
import { ACCESS_LIST } from 'resources/constants/config';

const Scrollable = styled.div`
  height: 100%;
  width: 90%;
  overflow: scroll;
  ${HIDDEN_SCROLLBARS}
`;

const Rows = styled.div`
  display: grid;
  grid-template-rows: 36px auto auto;
`;

const Top = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
`;

const GroupInfoContainer = styled.div`
  display: grid;
  grid-template-rows: 2fr 1fr;
  grid-gap: 30px;
`;

const AppContainer = styled.div`
  margin-top: 32px;
`;

const List = ({ location }) => {
  const { translate } = useI18n();
  const [, , utils] = useGroups();
  const { groupId } = useParams();
  const [group, setGroup] = useState({});
  const { hasAccessInGroup } = useAccess();

  useEffect(() => {
    if (!groupId) return;
    const group = utils.findOne(+groupId);
    if (!!group) setGroup(group);
  }, [utils]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TabLayoutContainer>
      <TopNavBar location={location} />
      <Scrollable>
        <Rows>
          <Top>
            <IconLink to={`${location.pathname}/edit`} icon={EditIcon} disabled={!hasAccessInGroup(groupId, ACCESS_LIST.GROUP)}>
              {translate('Edit Profile')}
            </IconLink>
          </Top>
          <InfoContainer>
            <GroupInfoContainer>
            <Card>
              <GroupProfile group={group} />
            </Card>
            <Card>
              <Schedule group={group} />
            </Card>
            </GroupInfoContainer>
            <Card>
              <GroupInfo group={group} />
            </Card>
          </InfoContainer>
          <AppContainer>
            <Card>
              <AppList />
            </Card>
          </AppContainer>
        </Rows>
      </Scrollable>
    </TabLayoutContainer>
  );
};

export default List;
