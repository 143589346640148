import Axios from 'axios';
import Authorization from './auth';
import { API } from '../constants/urls';
import { executeCall } from './utils';

const nomenclator = (type) => {
    const url = `${API}/nomenclator/${type}`;

    return executeCall(() => Axios.get(url, { headers: Authorization }));
}

export default nomenclator;