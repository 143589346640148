import { HIDDEN_SCROLLBARS } from 'resources/constants/styles';
import styled from 'styled-components';

const FormScrollableContainer = styled.div`
  height: 100%;
  width: 90%;
  overflow: scroll;
  ${HIDDEN_SCROLLBARS}
`;

export default FormScrollableContainer;
