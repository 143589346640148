import React, { useEffect, useState } from 'react';
import useDivisions from 'state/divisions';
import Form from '../components/Form';
import useI18n from 'i18n/useI18n';
import { useParams } from 'react-router-dom';
import { useRouter } from 'providers/router';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import FormHeader from 'pages/Group/components/FormHeader';
import { formatObjectForSelect, formatUsersForSelect, mapIdsToObjects, getUsersRoleByDivision } from 'utils';
import useUsers from 'state/users';
const Create = () => {
  const { translate } = useI18n();
  const [data, dispatcher] = useDivisions();
  const [users, usersDispatcher] = useUsers();
  const { groupId } = useParams();
  const { route, canGoBack } = useRouter();
  const [formData, setFormData] = useState({ id: '-999', groupId, heads: [], users: [] });

  useEffect(() => {
    dispatcher.fetchStart(groupId);
    usersDispatcher.fetchStart();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const division = {
      name: formData.name,
      groupId,
      users: getUsersRoleByDivision(formData.users, groupId, formData.id),
      heads: formData.heads.map(({ value }) => value),
      config: { description: formData.description },
    };
    dispatcher.createStart(division);
  };  

  const importData = (division) => {
    const heads = formatUsersForSelect(mapIdsToObjects(division.heads, users.list).filter(({ assignments }) => assignments && Object.keys(assignments).some((id) => +id === +groupId)));
    const divisionUsers = formatUsersForSelect(mapIdsToObjects(division.users, users.list).filter(({ assignments }) => assignments && Object.keys(assignments).some((id) => +id === +groupId)));
    const formData = { 
      id: `${division.value}`,
      name: division.label,
      groupId: +groupId,
      description: division.config?.description ?? '',
      heads: replaceId(heads, +groupId, division.value, `${division.value}`),
      users: replaceId(divisionUsers, +groupId, division.value, `${division.value}`)
    };
    setFormData(formData);
  }

  const replaceId = (users, groupId, divisionId, newDivisionId) => {
    return users.map( user => {
      user.assignments[groupId] = user.assignments[groupId].map( assignment => {
        if( assignment.division === divisionId ){
          assignment.division = newDivisionId;
        }
        return assignment;
      });
      return user;
    });
  }

  return (
    <FormScrollableContainer>
      <FormHeader
        route={canGoBack() ? route.from : `/${groupId}/management/divisions`}
        routeText={translate('Back to Divisions')}
        importOptions={data.list.map((div)=>formatObjectForSelect(div))}
        importData={importData}
      />
      <Form
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        title={translate('New Division')}
        loading={data.fetching.state}
        buttonText={translate('Create Division')}
        groupId={groupId}
      />
    </FormScrollableContainer>
  );
};

export default Create;
