import React, { useEffect, useState } from 'react';
import { FormSpacer } from 'containers/FormLayout';
import useI18n from 'i18n/useI18n';
import Card from 'components/Card';
import useNomenclators from 'state/nomenclators';
import { TitleH3 } from 'components/Texts';
import FormSearchableSelect from 'components/FormSearchableSelect';
import { InvalidText } from 'components/Texts';

const DEFAULT_LANG = (lang) => ({
  lang,
  title: '',
  message: '',
});

const Languages = ({ formData, handleFormChange, errors }) => {
  const { translate } = useI18n();
  const [data, dispatcher] = useNomenclators();
  const [options, setOptions] = useState([]);

  const handleLanguageSelection = (lang) => {
    const list = [...formData.translations];
    const values = list.map(({ lang }) => lang.value);

    if (values.includes(lang.value)) {
      const index = values.indexOf(lang.value);
      list.splice(index, 1);
    } else {
      list.push({ ...DEFAULT_LANG(lang) });
    }

    handleFormChange('translations', list);
  };

  useEffect(() => {
    dispatcher.fetchLangs();
  }, []);

  useEffect(() => {
    if (!data.langs) return;
    setOptions(data.langs.map(({ id, name }) => ({ label: name, value: id })));
  }, [data.langs]);

  return (
    <>
      <Card>
        <TitleH3 style={{marginBottom: '18px'}}>{translate('Languages')}</TitleH3>
        <FormSearchableSelect
          title={translate('Which languages ​​will be available')}
          onChange={handleLanguageSelection}
          onRemove={handleLanguageSelection}
          selectedValues={formData.translations.map(({ lang }) => lang)}
          options={options}
          error={null}
        />
        {errors?.translations && (
          <InvalidText style={{ textAlign: 'center' }}>{translate('At least one translation required')}</InvalidText>
        )}
      </Card>
      <FormSpacer />
    </>
  );
};

export default Languages;
