import { formatObjectForSelect, mapIdsToObjects } from 'utils';
import { unParseAssignments } from 'utils/employee';
import { DEFAULT_ASSIGNMENT } from '../../Reducer';

const findPermission = (permissionsIds, groupId, permissions) => {
  const data = mapIdsToObjects(permissionsIds, permissions);
  const permission = data.find((group) => +group.groupId === +groupId);
  return !!permission ? formatObjectForSelect(permission) : null;
};

const getAssignments = (unparse, gid, divisionUtils, roleUtils) => {
  return unparse[gid]
    .filter(({ division, role }) => !!division || !!role)
    .map(({ groupId, division, role }) => ({
      groupId,
      division: formatObjectForSelect(divisionUtils.findOne(division)),
      role: formatObjectForSelect(roleUtils.findOne(role)),
    }));
};

const toFormState = (employee, groupsUtils, permissionUtils, divisionUtils, roleUtils) => {
  const newData = {
    personalInfo: {
      id: employee.id,
      name: employee.name,
      lastName: employee.lastName ?? '',
      secondName: employee.secondName ?? '',
      userPosId: employee.userPosId ?? '',
      email: employee.email ?? '',
      image: employee.image ?? '',
      isActive: employee.isActive ?? 1,
      shortName: employee.shortName ?? '',
      pass: '',
      shortPassword: '',
      ofType: employee.ofType ?? 'regular',
    },
  };

  const unparse = unParseAssignments(employee.assignments);
  const groups = Object.keys(unparse);

  newData.groups = groups.map((gid) => {
    const assgnmts = getAssignments(unparse, gid, divisionUtils, roleUtils);
    return {
      element: formatObjectForSelect(groupsUtils.findOne(gid)),
      permission: findPermission(employee.permissions ?? [], gid, permissionUtils.getAll()),
      assignments: assgnmts.length === 0 ? [{ ...DEFAULT_ASSIGNMENT(gid) }] : assgnmts,
    };
  });

  return newData;
};

export default toFormState;
