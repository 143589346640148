import React from 'react';
import { Card } from '@etiquette-ui/misc';
import useI18n from 'i18n/useI18n';
import { TitleH3 } from 'components/Texts';
import { CustomContainer } from 'containers/FormLayout';
import PERSONAL_INFO_KEYS from 'components/EmployeeForm/keys';
import { Input } from '@etiquette-ui/inputs-dropdowns';

const ShortAccess = ({ formData, onChangeForm, isEdit = false }) => {
  const { translate } = useI18n();
  return (
    <Card>
      <TitleH3 style={{marginBottom: '18px'}}>{translate('Short Access')}</TitleH3>
      <CustomContainer columns="2">
        <Input
          labelProps={{label: translate('Nick Name')}}
          iconProps={{
            icon: 'user-name'
          }}
          inputProps={{
            value: formData[PERSONAL_INFO_KEYS.SHORT_NAME],
            onChange: (e) => onChangeForm(PERSONAL_INFO_KEYS.SHORT_NAME, e.target.value),
          }}
        />
        <Input
          labelProps={{label: translate('Short Password')}}
          inputProps={{
            type: 'password',
            value: formData[PERSONAL_INFO_KEYS.SHORT_PASSWORD],
            onChange: (e) => onChangeForm(PERSONAL_INFO_KEYS.SHORT_PASSWORD, e.target.value),
            autoComplete: "new-password"
          }}          
        />
      </CustomContainer>
    </Card>
  );
};

export default ShortAccess;
