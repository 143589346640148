import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import useDivisions from 'state/divisions';
import Create from './pages/Create';
import List from './pages/List';

const Divisions = () => {
  const { path } = useRouteMatch();
  const [, dispatcher] = useDivisions();

  useEffect(()=>{
    dispatcher.fetchStart();
  },[])

  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route exact path={`${path}/create`} component={Create} />
    </Switch>
  );
};

export default Divisions;
