import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import useI18n from 'i18n/useI18n';
import { useHistory, useParams } from 'react-router-dom';
import useEmailTemplates from 'state/emailTemplates';
import Table from 'components/Table';
import structure from './structure';
import FloatingActionButton from 'components/FloatingActionButton';
import styled from 'styled-components';
import { useAlert } from '@etiquette-ui/alert-dialog';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';

const Content = styled(Card)`
  width: 90%;
  margin-bottom: 40px;
`;

const List = ({ location }) => {
  const [data, dispatcher] = useEmailTemplates();
  const history = useHistory();
  const { translate } = useI18n();
  const { groupId } = useParams();
  const { search, setVisible } = useSearch();
  const [items, setItems] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    dispatcher.fetchStart(groupId);
    setVisible(true);
    return () => setVisible(false);
  }, []);

  useEffect(() => {
    let list = [...data.list];
    if (search.length !== 0)
      list = list.filter(
        ({ name, tag, description, translations }) =>
          compareStrings(name ?? '', search) ||
          compareStrings(tag ?? '', search) ||
          compareStrings(description ?? '', search) ||
          translations.some(({ title, message }) => compareStrings(title ?? '', search) || compareStrings(message ?? '', search))
      );
    setItems(list);
  }, [data.list, search]);

  return (
    <>
      <Content>
        <Table
          structure={structure}
          config={{ history, dispatcher, data, translate, alert, groupId }}
          items={items.filter((app) => +groupId === app.groupId)}
        />
      </Content>
      <FloatingActionButton to={`${location.pathname}/create`}>{translate('Add Template')}</FloatingActionButton>
    </>
  );
};

export default List;
