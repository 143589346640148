import React, {useMemo} from 'react';
import Dialog from './Dialog';
import useI18n from "i18n/useI18n";
import useGroups from "state/groups";
import styled from 'styled-components';
import { useRouter } from 'providers/router';
import { Spinner } from '@etiquette-ui/misc';
import { BG, PRIMARY } from '@etiquette-ui/colors';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';

const GroupLabel = styled.div`
  width: 100%;
  padding: 15px;
  font-size: 14px/18px;
  font-weight: 300;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;

  position: relative;

  .name {
    text-decoration: underline;
    font-size: 16px/19px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;

    position: absolute;
    left: 15px;
    right: 15px;
    top: 70%;

    &:hover {
      cursor: pointer;
    }
  }
`;

const GroupSwitch = ({groupId}) => {  

  const alert = useAlert();
  const [groups] = useGroups();
  const { route } = useRouter();
  const { translate } = useI18n();
  const group = groups.list.find(({id}) => id === +groupId);

  const routeString = useMemo(()=>{
    if(!route) return '';

    let crumbs = route.to.split('/'); 
    crumbs[1] = ':groupId';
    return crumbs.join('/');
  },[route]);

  const onClick = () => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: Dialog,
        props: {
          routeString,
          groupId
        }          
      },
    })
    .then(() => {})
    .catch(() => {});
  }

  return (
    
      <GroupLabel onClick={onClick}>
          {translate("You are in")}:
        {
          !!group ? group.name && (<div className="name">{group.name}</div> ) : (
            <Spinner 
            style={{
              '--width': '40px',
              '--margin': '0 5px',
              '--bg': BG,
              '--color': PRIMARY
            }}
          />
          )
        }        
      </GroupLabel>
    
  )
}
export default GroupSwitch;
