import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BackIcon from "resources/img/back.svg";

const Container = styled.div`
  & > a {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.875rem;
    ${({disabled}) => disabled ? 'cursor: default; opacity: 0.2; pointer-events: none;' : ''}
    user-select: none;
  }
`;

const Icon = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const IconLink = ({ to, children, className, icon, disabled }) => {
  return (
    <Container className={className} disabled={disabled}>
      <Link to={disabled ? '#' : to}>
        <Icon src={icon ? icon : BackIcon}/>
        {children}
      </Link>
    </Container>
  );
};

export default IconLink;
