import React from 'react';
import Actions from 'components/DynamicTable/DynamicTableComponents/Editable/Actions';
import AddUserIcon from 'resources/img/adduser-icon.svg';
import ActionEditIcon from 'resources/img/action-edit-icon.svg';
import ActionDeleteIcon from 'resources/img/action-delete-icon.svg';
import { ALERT_TYPES } from '@etiquette-ui/alert-dialog';
import colors from 'resources/constants/colors';
import { ACCESS_LIST } from 'resources/constants/config';
import { useAccess } from 'providers/access';
import AddEmployee from 'components/alert/AddEmployee/index';

const RowActions = ({ item, globalState, count }) => {
  const { hasAccessInGroup } = useAccess();

  const actions = [
    {
      event: () => {
        globalState.config
          .alert({
            type: ALERT_TYPES.CONFIRM,
            config: {
              title: globalState.config.translate('Delete Division'),
              body: globalState.config.translate('Are you sure you want to delete this division?'),
              closeText: globalState.config.translate('Go Back'),
              submitText: globalState.config.translate('Delete'),
            },
          })
          .then(() => {
            globalState.config.dispatcher.deleteStart({ id: +item.id, groupId: item.groupId });
          })
          .catch(() => {});
      },
      src: ActionDeleteIcon,
      disabled: !hasAccessInGroup(+item.groupId, ACCESS_LIST.DIVISION),
    },
    {
      event: () => globalState.config.history.push(`/${item.groupId}/management/divisions/edit/${item.id}`),
      src: ActionEditIcon,
      disabled: !hasAccessInGroup(+item.groupId, ACCESS_LIST.DIVISION),
    },
    {
      event: () => {
        globalState.config
          .alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
              body: AddEmployee,
              props: {
                groupId: item.groupId,
                divisionId: item.id,
              }
            },
          })
      },
      disabled: !hasAccessInGroup(item.groupId, ACCESS_LIST.DIVISION),
      src: AddUserIcon,
    },
  ];

  return (
    <Actions
      loading={globalState.config.data.fetching?.config?.id === item.id}
      actions={actions}
      style={{ alignItems: 'start', marginTop: '10px' }}
      spinnerBackground={count % 2 === 0 ? colors.backgroundLightGrey : 'white'}
    />
  );
};

export default RowActions;
