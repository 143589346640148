import styled from 'styled-components';
import starIcon from 'resources/img/feature-icon.svg';


const AppFeatures = ({ data }) => {
  return (
    <Container>
      <section className='container__starSection '>
        <img src={starIcon} alt="" />
      </section>
      <section className='container__featureSection'>
        <div className='container__featureSection--title'>
          <p>{data.title}</p>
        </div>
        <div className='container__featureSection--description'>
          <p>{data.description}</p>
        </div>
      </section>
    </Container>
  )
}

const Container = styled.div`
  width: 50%;
  height: 79px;
  display: flex;
  padding: 0 5%;
  margin-bottom: 10px;
  box-sizing: border-box;
  .container__starSection {
    width: 44px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    img {
      width: 28px;
      height: 28px;
    }
  }
  .container__featureSection {
    width: calc(100% - 44px);
    p {
      margin: 0;
      font-size: 0.875rem;
    }
  }
  .container__featureSection--title {
    p {
      font-weight: 600;
    }
  }
  .container__featureSection--description {
    padding-top: 3px;
    box-sizing: border-box;
    p {
      height: 51px;
      font-weight: 400;
      overflow-y: auto;
    }
  }
`;

export default AppFeatures;
