const TYPE = `[SALES_ADJUSTMENT]`;

const TYPES = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    UPDATE_START: `${TYPE} UPDATE START`,
    UPDATE_SUCCESS: `${TYPE} UPDATE SUCCESS`,
    UPDATE_ERROR: `${TYPE} UPDATE ERROR`,

    CREATE_START: `${TYPE} CREATE START`,
    CREATE_SUCCESS: `${TYPE} CREATE SUCCESS`,
    CREATE_ERROR: `${TYPE} CREATE ERROR`,

    DELETE_START: `${TYPE} DELETE START`,
    DELETE_SUCCESS: `${TYPE} DELETE SUCCESS`,
    DELETE_ERROR: `${TYPE} DELETE ERROR`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default TYPES;