import InfoElement from 'components/InfoElement';
import { TitleH3 } from 'components/Texts';
import React, { useEffect, useState } from 'react';
import ContactIcon from 'resources/img/perfil/user.svg';
import MailIcon from 'resources/img/perfil/mail.svg';
import JobIcon from 'resources/img/perfil/position.svg';
import PhoneIcon from 'resources/img/perfil/phone.svg';

import styled from 'styled-components';
import useI18n from 'i18n/useI18n';

const Spacer = styled.div`
  height: 5px;
`;

const UserInfo = ({ contact, title = 'Main Contact' }) => {
  const { translate } = useI18n();
  return (
    <>
      <TitleH3 style={{ marginBottom: '0.5rem' }}>{translate(title)}</TitleH3>
      <InfoElement icon={ContactIcon} title={translate('Name')} description={`${contact.name} ${contact.lastName}`} />
      <Spacer />
      <InfoElement icon={MailIcon} title={translate('Email Address')} description={contact.email ?? ''} />
      <Spacer />
      <InfoElement icon={PhoneIcon} title={translate('Mobile Phone')} description={contact.phone ?? ''} />
      <Spacer />
      <InfoElement icon={JobIcon} title={translate('Job Position')} description={contact.position ?? ''} />
    </>
  );
};

const GroupInfo = ({ group }) => {
  const [contact, setContact] = useState({});
  const [secondary, setSecondary] = useState(null);

  useEffect(() => {
    setContact(group?.contacts?.length > 0 ? group.contacts[0] : {});
    setSecondary(group?.contacts?.length > 1 ? group.contacts[1] : null);
  }, [group]);

  return (
    <div>
      <UserInfo contact={contact} />
      <div style={{height: '20px'}}></div>
      {secondary && <UserInfo contact={secondary} title={'Secondary Contact'} />}
    </div>
  );
};

export default GroupInfo;
