import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import List from './pages/List';

const Groups = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={List} />
    </Switch>
  );
};

export default Groups;
