import PERSONAL_INFO_KEYS from 'components/EmployeeForm/keys';
import { isValidEmail } from 'utils/validators';

const validate = (formData) => {
    const errors = {};
    if(!isValidEmail(formData.personalInfo[PERSONAL_INFO_KEYS.EMAIL])){
      errors[PERSONAL_INFO_KEYS.EMAIL] = true;
    }
    if(formData.groups.length === 0){
      errors.groups = true;
    }
    return errors;
}

export default validate;