import React from 'react';
import styled from 'styled-components';
import SortText from 'components/Table/headers/SortText';
import SortNumber from 'components/Table/headers/SortNumber';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const SortHeader =
  (filterKey, title, isNumber=false) =>
  ({ globalState, ...rest }) => {
    return (
      <Container onClick={()=>globalState.config.handleFilter(filterKey)}>
        {isNumber ? (
          <SortNumber property={filterKey} text={title} {...rest}/>
          ):(
          <SortText property={filterKey} text={title} {...rest}/>
        )}
      </Container>
    );
  };

export default SortHeader;
