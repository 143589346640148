import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  margin: 1rem 0;
  width: 100%;
  border-radius: 5px;
  min-height: 3rem;
  font-weight: bold;
  position: relative;
  padding: 0.5rem 1rem;
  ${({disabled}) => disabled ? 'cursor: default; opacity: 0.2; pointer-events: none;' : ''}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1rem 3rem auto;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ActiveIndicatorBackground = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #388CFF;
  opacity: 0.2;
  border-radius: 8px;
  z-index: -1;
`;

const ActiveIndicator = styled.div`
  width: 3px;
  min-height: 24px;
  margin-left: 4px;
  border-radius: 24px;
  background-color: #388cff;
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
`;

const NavOption = ({ icon, title, isActive = false, onClick, disabled }) => {
  return (
    <Container onClick={disabled ? ()=>{} : onClick} disabled={disabled}>
      {isActive && <ActiveIndicatorBackground />}
      <Grid>
        <div>{isActive && <ActiveIndicator />}</div>
        <div>
          <Icon src={icon}/>
        </div>
        <div>{title}</div>
      </Grid>
    </Container>
  );
};

export default NavOption;
