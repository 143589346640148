import { countries } from 'countries-list';

const formattedCountries = Object.keys(countries).map((code) => ({
    label: countries[code].name,
    value: code,
    ...countries[code],
}));

export const findCountryPhoneCode = (phoneCode) => {
    return findCountry(phoneCode)?.phone;
}


export const findCountry = (countryValue) => {
    return formattedCountries.find((country)=>countryValue===country.value);
}

