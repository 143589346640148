import React, { useEffect, useState } from 'react';
import useI18n from 'i18n/useI18n';
import { CustomImageInput } from 'components/ImageUpload';
import Card from 'components/Card';
import { GridContainer, FormSpacer } from 'containers/FormLayout';
import usePlaces from 'state/places';
import { TitleH3 } from 'components/Texts';
import GROUP_INFO_KEYS from '../keys';

import { Input } from '@etiquette-ui/inputs-dropdowns';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';

const GroupInformation = ({ formData, handleGroupInfo, errors }) => {
  const { translate } = useI18n();
  const [places, dispatcher] = usePlaces();
  const [citiesOptions, setCitiesOptions] = useState([]);

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  useEffect(() => {
    if (!formData[GROUP_INFO_KEYS.STATE_ADDRESS]) return;
    const citiesFromState = places.cities.filter(({ state_id }) => state_id == formData.stateAddress.value);
    setCitiesOptions(citiesFromState);
  }, [places.cities, formData[GROUP_INFO_KEYS.STATE_ADDRESS]]);

  return (
    <Card>
      <TitleH3>{translate('Group Information')}</TitleH3>
      <FormSpacer />
      <GridContainer>
        <CustomImageInput
          text={translate('Add Picture')}
          altText={translate('Edit Picture')}
          src={formData[GROUP_INFO_KEYS.IMAGE]}
          onChange={(value) => handleGroupInfo(GROUP_INFO_KEYS.IMAGE, value)}
          error={errors[GROUP_INFO_KEYS.IMAGE] && !formData[GROUP_INFO_KEYS.IMAGE] ? translate('File Missing') : undefined}
        />
        <Input
          labelProps={{label: translate('Group Name')}}
          iconProps={{
            icon: 'restaurant'
          }}
          inputProps={{
            value: formData[GROUP_INFO_KEYS.NAME],
            onChange: (e) => handleGroupInfo([GROUP_INFO_KEYS.NAME], e.target.value),
            required: true,
          }}
        />
        <Input
          labelProps={{label: translate('Street Address')}}
          iconProps={{
            icon: 'location'
          }}
          inputProps={{
            value: formData[GROUP_INFO_KEYS.STREET_ADDRESS],
            onChange: (e) => handleGroupInfo(GROUP_INFO_KEYS.STREET_ADDRESS, e.target.value),
            required: true,
          }}
        />
        <GridContainer>
          <Input
            labelProps={{label: translate('Number')}}
            iconProps={{
              icon: 'number'
            }}
            inputProps={{
              value: formData[GROUP_INFO_KEYS.STREET_NUMBER],
              onChange: (e) => handleGroupInfo(GROUP_INFO_KEYS.STREET_NUMBER, e.target.value),
              required: true,
            }}
          />
          <Input
            labelProps={{label: translate('ZIP Code')}}
            iconProps={{
              icon: 'zip-code'
            }}
            inputProps={{
              value: formData[GROUP_INFO_KEYS.ZIP_CODE],
              onChange: (e) => handleGroupInfo(GROUP_INFO_KEYS.ZIP_CODE, e.target.value),
              required: true,
              name: 'zipCode',
              type: 'number',
            }}
          />
        </GridContainer>
        <Dropdown
          labelProps={{
            label: translate('State')
          }} 
          leadingIconProps={{
            icon: 'estate'
          }} 
          selectProps={{
            options: places.states.map(({ id, name }) => ({ value: id, label: name })),
            value: formData[GROUP_INFO_KEYS.STATE_ADDRESS],
            onChange: (value) => handleGroupInfo(GROUP_INFO_KEYS.STATE_ADDRESS, value),
            required: true,
            placeholder: translate('Choose one')
          }}
          errorProps={{error: errors[GROUP_INFO_KEYS.STATE_ADDRESS] ? translate('Required Field') : ''}}
        />
        <Dropdown
          labelProps={{
            label: translate('City')
          }} 
          leadingIconProps={{
            icon: 'estate'
          }} 
          selectProps={{
            options: citiesOptions.map(({ id, name }) => ({ value: id, label: name })),
            value: formData[GROUP_INFO_KEYS.CITY_ADDRESS],
            onChange: (value) => handleGroupInfo(GROUP_INFO_KEYS.CITY_ADDRESS, value),
            required: true,
            placeholder: translate('Choose one')
          }}
          errorProps={{error: errors[GROUP_INFO_KEYS.CITY_ADDRESS] ? translate('Required Field') : ''}}
        />
      </GridContainer>
    </Card>
  );
};
export default GroupInformation;