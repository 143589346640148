export const createPermissionObject = (formData, groupId, apps) => {
  const permission = {};

  permission.permission = {};
  permission.permission.name = formData.name;
  permission.permission.description = formData.description;
  permission.permission.groupId = groupId;

  permission.apps = [];
  Object.keys(formData.selectedPermissions).forEach((key) => {
    const id = apps
      .filter((app) => +app.groupId === +groupId || app.groupId === null)
      .find(({ identifier }) => identifier === key)?.id;
    permission.apps.push({ id, access: formData.selectedPermissions[key] });
  });

  return permission;
};
