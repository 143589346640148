import IconLink from 'components/IconLink';
import useI18n from 'i18n/useI18n';
import React, { useEffect, useState } from 'react';
import useEmailTemplate from 'state/emailTemplates';
import Form from '../components/Form';
import styled from 'styled-components';
import Space from 'components/Space';
import { useParams } from 'react-router-dom';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import { useRouter } from 'providers/router';
import useNomenclators from 'state/nomenclators';
import { formatObjectForSelect } from 'utils';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Edit = () => {
  const { translate } = useI18n();
  const [formData, setFormData] = useState(null);
  const [data, dispatcher, utils] = useEmailTemplate();
  const [nomenclators, nomenclatorsDispatcher] = useNomenclators();
  const { groupId, id } = useParams();
  const { canGoBack, route } = useRouter();

  useEffect(() => {
    dispatcher.fetchStart();
    nomenclatorsDispatcher.fetchLangs();
  }, []);

  const getTranslations = (template, langs) => {
    if (!template) return [];
    const list = template.translations.filter(({lang, title, message})=> !!lang && !!title && !!message).map(({ lang, ...rest }) => ({
      lang: formatObjectForSelect(langs.find(({ id }) => +id === +lang)),
      ...rest,
    }));
    return list;
  };

  useEffect(() => {
    if (data.fetching.state || nomenclators.langs?.length === 0) return;
    const template = utils.findOne(id);
    setFormData({
      name: template?.name ?? '',
      description: template?.description ?? '',
      tag: template?.tag ?? '',
      isActive: template?.isActive ?? 1,
      groupId: template?.groupId ?? groupId,
      translations: getTranslations(template, nomenclators.langs),
    });
  }, [data.fetching, nomenclators.langs]);

  const onSubmit = () => {
    const newData = {
      ...formData,
      id: id,
      translations: formData.translations.map(({ lang, ...rest }) => ({ lang: lang.value, ...rest })),
    };

    dispatcher.updateStart({ ...newData, groupId });
  };

  return (
    <FormScrollableContainer>
      <HeaderContainer>
        <IconLink to={canGoBack() ? route.from : `/${groupId}/templates`}>{translate('Back to Email Template')}</IconLink>
      </HeaderContainer>
      <Space />
      {formData && (
        <Form
          onSubmit={onSubmit}
          formData={formData}
          setFormData={setFormData}
          title={translate('Edit Email Template')}
          loading={data.fetching.state}
          buttonText={translate('Edit Email Template')}
        />
      )}
    </FormScrollableContainer>
  );
};

export default Edit;
