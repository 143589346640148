import React, {useMemo} from 'react'; 
import useI18n from 'i18n/useI18n';
import useGroups from 'state/groups';
import styled from 'styled-components';
import { useSearch } from 'providers/search';
import { MultiSelect } from '@etiquette-ui/inputs-dropdowns';

const Container = styled.div`
  max-width: 300px;
`;

const Selector = styled(MultiSelect)`
  min-width: 300px;
`;

const getPlaceholder = ( options, translate, listOptions ) => {
  if(options.length === 0) return translate('Groups');
  if(options.length === listOptions.length ) return translate('All Groups');
  return options.map((option) => option.label).join(' | ');
}

const GroupFilter = () => {

  const {translate} = useI18n();
  const [groups] = useGroups();
  const { groupsList, setGroupsList } = useSearch();

  const initOption = {
    value: '-1', 
    label: translate('All Groups')
  }

  const groupsListOptions = useMemo(() => {
    if(!groups) return [];

    return ([initOption, ...groups.list.map( group => ({
        value: group.id,
        label: group.name
      }))
    ]);
  },[groups]);

  const selectedOptions = useMemo(()=>{
    if(!groupsList || groupsList.length === 0 || groupsList.length === groupsListOptions.length-1) 
      return [initOption];

    return groupsListOptions.filter( ({value}) => groupsList.includes(value) );
  },[groupsList]);

  const onSelectOption = (selected) => {
    if( selected.value === '-1' || selectedOptions.length === 0 ){
      setGroupsList([]);
      return;
    }

    let newOptions = [...selectedOptions.filter(({value}) => value !== '-1' )];
    const option = newOptions.find(({ value }) => value === selected.value);
    const index = option ? newOptions.indexOf(option) : -1;

    if (index === -1)  newOptions.push(selected);
    else newOptions.splice(index, 1);

    setGroupsList(newOptions.map(({value}) => value));
  }; 

  return (
    <Container>
      <Selector
        labelProps={{          
          label: translate('Groups')
        }}
        selectProps={{
          onChange: onSelectOption,
          options: groupsListOptions,
          placeholder: getPlaceholder(selectedOptions, translate, groupsListOptions)
        }}
        selectedValues={selectedOptions}
      />
    </Container>
  )
}
export default GroupFilter;