import React from 'react';
import TabLayoutContainer from 'pages/Group/pages/Management/containers/TabLayoutContainer';
import TopNavBar from 'pages/Group/pages/Management/components/TopNavBar';
import Card from 'components/Card';
import FloatingActionButton from 'components/FloatingActionButton';
import styled from 'styled-components';

const Content = styled(Card)`
    margin-top: 36px;
    width: 90%;
    margin-bottom: 40px;
`;

const ManagementTabsContainer = ({ location, fabLink, fabText, fabDisabled, children }) => {
  return (
    <TabLayoutContainer>
      <TopNavBar location={location} />
      <Content>{children}</Content>
      <FloatingActionButton to={fabLink} disabled={fabDisabled}>{fabText}</FloatingActionButton>
    </TabLayoutContainer>
  );
};

export default ManagementTabsContainer;
