import React, { useEffect } from 'react';
import useI18n from 'i18n/useI18n';
import FormSearchableSelect from 'components/FormSearchableSelect';
import useGroups from 'state/groups';
import { useAccess } from 'providers/access';

const GroupSelect = ({ onChange, onRemove, selectedValues, error, access=null }) => {
  const { translate } = useI18n();
  const [groups, dispatcher] = useGroups();
  const { hasAccessInGroup } = useAccess();

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  return (
    <FormSearchableSelect
      title={translate('Add Groups')}
      onChange={onChange}
      onRemove={onRemove}
      selectedValues={selectedValues}
      options={groups.list.filter(({id})=>access ? hasAccessInGroup(id, access) : true).map(({ id, name, ...rest }) => ({ value: id, label: name, ...rest }))}
      error={error}
    />
  );
};

export default GroupSelect;
